import { CustomSize, SkillSize } from 'lib/api/skills/skills'
import { useEffect, useRef, useState } from 'react'
import CustomSizes from './custom-sizes'
import SelectableSkillSize from './selectable-skill-size'
import IconButton from 'lib/components/buttons/icon-button'

interface SkillSizesProps {
  skillSizes: SkillSize[]
  onSelect: (sizes: { skillSize?: SkillSize[]; customSizes?: CustomSize[] }) => void
  ticketSkillSizes: SkillSize[]
  ticketCustomSizes: CustomSize[]
}

const blankCustomSize: CustomSize = { width: '', height: '', units: 'px' }

const classNames = {
  customSizesContainer: `
    tw-border-neutral-200
    tw-border-2
    tw-border-solid
    tw-rounded-md
    tw-p-4
    tw-mt-6
    tw-mr-5`,
}

export default function SkillSizes({
  skillSizes,
  onSelect,
  ticketSkillSizes,
  ticketCustomSizes,
}: SkillSizesProps): JSX.Element {
  const skillSizesRef = useRef([])
  const customSizesRef = useRef([])
  const [selectedSkillSizes, setSelectedSkillSizes] = useState<SkillSize[]>([])
  const [selectedCustomSizes, setSelectedCustomSizes] = useState<CustomSize[]>([])
  const [showCustomSizes, setShowCustomSizes] = useState(ticketCustomSizes.length > 0)

  function setSelectedSkillSizesAndRef(sizes: SkillSize[]) {
    skillSizesRef.current = sizes
    setSelectedSkillSizes(sizes)
  }

  function setSelectedCustomSizesAndRef(sizes: CustomSize[]) {
    customSizesRef.current = sizes
    setSelectedCustomSizes(sizes)
  }

  function onSkillSelect(skillSize: SkillSize) {
    if (selectedSkillSizes.find((s) => s.id === skillSize.id)) {
      const updatedValues = selectedSkillSizes.filter((s) => s.id !== skillSize.id)

      setSelectedSkillSizesAndRef(updatedValues)
    } else {
      const updatedValues = [...selectedSkillSizes, skillSize]

      setSelectedSkillSizesAndRef(updatedValues)
    }
  }

  function isSelected(skillSize: SkillSize) {
    return selectedSkillSizes.some((s) => s.id === skillSize.id)
  }

  function isCardsSquare() {
    return !skillSizes.some((skillSize) => (skillSize.name + skillSize.details).length > 80)
  }

  useEffect(() => {
    if (ticketSkillSizes) {
      setSelectedSkillSizesAndRef(ticketSkillSizes)
    }
  }, [ticketSkillSizes])

  useEffect(() => {
    if (ticketCustomSizes?.length > 0) {
      setSelectedCustomSizesAndRef(ticketCustomSizes)
    } else {
      setSelectedCustomSizesAndRef([blankCustomSize])
    }
  }, [ticketCustomSizes])

  useEffect(() => {
    return function () {
      const onSelectPayload = {
        skillSizes: undefined,
        customSizes: undefined,
      }

      if (skillSizesRef.current !== ticketSkillSizes) {
        onSelectPayload.skillSizes = skillSizesRef.current
      }

      if (customSizesRef.current !== ticketCustomSizes) {
        onSelectPayload.customSizes = customSizesRef.current
      }

      if (onSelectPayload.skillSizes || onSelectPayload.customSizes) {
        onSelect(onSelectPayload)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleDeleteCustomSizes() {
    setSelectedCustomSizesAndRef([blankCustomSize])
    setShowCustomSizes(false)
  }

  function handleAddCustomSizes() {
    setShowCustomSizes(true)
  }

  return (
    <>
      <h3>Size(s)*</h3>
      {skillSizes?.length > 0 ? (
        <>
          <p>Select all that apply</p>
          <div className="tw-flex tw-gap-3 tw-flex-wrap">
            {skillSizes.map((skillSize) => (
              <SelectableSkillSize
                skillSize={skillSize}
                onClick={onSkillSelect}
                isSelected={isSelected(skillSize)}
                key={skillSize.id}
                isCardsSquare={isCardsSquare()}
              />
            ))}
          </div>
          {!showCustomSizes ? (
            <div className={`${classNames.customSizesContainer} tw-flex tw-justify-center tw-items-center tw-flex-col`}>
              <h5>Need to add a custom size?</h5>
              <IconButton color="secondary" icon={['far', 'plus']} onClick={handleAddCustomSizes} />
            </div>
          ) : (
            <div className={classNames.customSizesContainer}>
              <div className="tw-flex tw-justify-between">
                <h5>Custom Size(s)</h5>
                <IconButton color="danger" icon={['far', 'trash']} onClick={handleDeleteCustomSizes} invert />
              </div>
              <CustomSizes
                selectedCustomSizes={selectedCustomSizes}
                setSelectedCustomSizes={setSelectedCustomSizesAndRef}
              />
            </div>
          )}
        </>
      ) : (
        <div>
          <p>Enter custom size(s)</p>
          <CustomSizes
            selectedCustomSizes={selectedCustomSizes}
            setSelectedCustomSizes={setSelectedCustomSizesAndRef}
          />
        </div>
      )}
    </>
  )
}
