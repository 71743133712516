import { ReactElement, useMemo } from 'react'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'
import { generateRangeTo } from 'lib/util/range'
import { useRequestContext } from '../providers/request-provider'
import { useTimelineListContext } from '../providers/timeline-list-provider'

export default function TimelineFilters(): ReactElement {
  const { ticket } = useRequestContext()
  const { selectedVersion, setSelectedVersion } = useTimelineListContext()

  const versions = useMemo(() => generateRangeTo(ticket.lastDeliveredVersion), [ticket.lastDeliveredVersion])

  function handleSelectedVersionChange(version: number) {
    setSelectedVersion(version)
  }

  if (ticket.lastDeliveredVersion === null) {
    return null
  }

  return (
    <div className="tw-sticky tw-top-0 tw-py-5 tw-bg-white tw-z-10">
      <TicketVersionSelectBox
        selectedVersion={selectedVersion}
        setSelectedVersion={handleSelectedVersionChange}
        versions={versions}
      />
    </div>
  )
}
