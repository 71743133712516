import { useCallback, useEffect, useState } from 'react'
import { useAdminTicketContext } from '../../providers/admin-ticket-provider'
import { AdminLogFilter, AdminTicketLog, getLogs } from 'lib/api/admin/logs/admin-logs'
import { LoaderCircle } from 'lucide-react'
import AdminLogEntry from './admin-log-entry'
import { toast } from 'lib/components/toast/toast'
import SelectBox from 'lib/components/dropdown/select-box'
import { useInView } from 'react-intersection-observer'

const logFilterOptions = [
  {
    value: AdminLogFilter.AutoAssigner,
    displayElement: <>Auto Assigner</>,
  },
  {
    value: AdminLogFilter.QAReview,
    displayElement: <>QA Reviews</>,
  },
  {
    value: AdminLogFilter.TicketChange,
    displayElement: <>Ticket Changes</>,
  },
  {
    value: AdminLogFilter.TicketTimeTracking,
    displayElement: <>Time Tracking</>,
  },
  {
    value: AdminLogFilter.all,
    displayElement: <>All logs</>,
  },
]

export default function AdminLogs() {
  const { ticket } = useAdminTicketContext()
  const [logs, setLogs] = useState<AdminTicketLog[]>([])
  const [loading, setLoading] = useState(false)
  const [logFilter, setLogFilter] = useState<AdminLogFilter>(AdminLogFilter.all)
  const [hasNextPage, setHasNextPage] = useState(true)
  const { ref, inView } = useInView()

  const handleLogFilterChange = (selectedFilter: AdminLogFilter) => {
    setHasNextPage(true)
    setLogs([])
    setLogFilter(selectedFilter)
  }

  const fetchLogs = useCallback(async () => {
    if (!hasNextPage || loading) return
    setLoading(true)
    try {
      const lastLog = logs.length > 0 ? logs[logs.length - 1] : {}
      const filters = {
        logType: logFilter,
        lastCreatedAt: 'endTime' in lastLog ? lastLog['endTime'] : lastLog['createdAt'],
      }
      const newLogs = await getLogs(ticket.id, filters)
      if (newLogs.length === 0) {
        setHasNextPage(false)
      } else {
        setLogs((prev) => [...prev, ...newLogs])
      }
    } catch (error) {
      console.error('Error fetching logs', error)
      toast.error('Error fetching logs')
    }
    setLoading(false)
  }, [hasNextPage, loading, logFilter, logs, ticket.id])

  useEffect(() => {
    if (inView) {
      fetchLogs()
    }
  }, [fetchLogs, inView])

  return (
    <div>
      <header className="tw-border-solid tw-border-0 tw-border-b tw-border-peppercorn-200 tw-p-4">
        <h4 className="tw-m-0">Logs</h4>
      </header>
      <div className="tw-w-64 tw-p-4">
        <SelectBox options={logFilterOptions} handleChange={handleLogFilterChange} selectedValue={logFilter} />
      </div>
      <ul className="tw-p-4">
        {logs.map((log, idx) => (
          <AdminLogEntry key={idx} log={log} />
        ))}
      </ul>
      {loading && (
        <div className="tw-max-w-sm tw-w-full tw-text-center">
          <LoaderCircle className="tw-animate-spin" data-testid="loader-circle" />
        </div>
      )}
      <div className="tw-h-1 tw-w-1" ref={ref} />
    </div>
  )
}
