import React, { forwardRef, InputHTMLAttributes } from 'react'
import { inputClassNames } from '../textarea/textarea'

interface TextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string
  error?: boolean | string
  type?: 'text' | 'password' | 'number'
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ id, label, error = false, type = 'text', className = '', ...otherProps }, ref) => {
    return (
      <>
        {label && (
          <label htmlFor={id} className={inputClassNames.label}>
            {label}
          </label>
        )}
        <input
          id={id}
          ref={ref}
          type={type}
          className={`${inputClassNames.common} ${
            error ? inputClassNames.error : inputClassNames.noError
          } ${className}`}
          {...otherProps}
        />
      </>
    )
  }
)

TextInput.displayName = 'TextInput'

export default TextInput
