import { MouseEvent } from 'react'
import { Pencil } from 'lucide-react'

import IconButton from 'lib/components/buttons/icon-button'
import { CompanyTag } from 'lib/api/company-tags/company-tags'
import Checkbox from 'lib/components/checkbox/checkbox'

import { useCompanyTaggingContext, ViewStates } from 'providers/company-tagging-provider'

const classNames = {
  tagContainer: {
    base: `tw-flex
           tw-items-center
           tw-justify-between
           tw-max-w-full`,
  },
  newTagContainer:
    'tw-text-black tw-p-2 hover:tw-bg-cornflower-50 tw-rounded-sm tw-transition-colors tw-cursor-pointer tw-break-all',
}

export default function CompanyTaggingSearchResults() {
  const { companyTags, searchTerm } = useCompanyTaggingContext()

  return <>{companyTags.length > 0 || searchTerm ? <TagsSearchResults /> : <NoTags />}</>
}

function TagsSearchResults() {
  const {
    searchResults,
    searchTerm,
    selectCompanyTag,
    selectedTags,
    unselectCompanyTag,
    setCurrentView,
    partiallySelectedTags = [],
  } = useCompanyTaggingContext()

  async function handleTagSelectBox(tag: CompanyTag) {
    if (isTagSelected(tag) || isTagIndeterminate(tag)) {
      await unselectCompanyTag(tag)
    } else {
      await selectCompanyTag(tag)
    }
  }

  function isTagSelected(tag: CompanyTag) {
    return selectedTags.some((selectedTag) => selectedTag.id === tag.id) && !isTagIndeterminate(tag)
  }

  function isTagIndeterminate(tag: CompanyTag) {
    return partiallySelectedTags.some((selectedTag) => selectedTag.id === tag.id)
  }

  function handleEditTagClick(e: MouseEvent<HTMLButtonElement>, tag: CompanyTag) {
    e.stopPropagation()
    setCurrentView({ viewState: ViewStates.Edit, tag })
  }

  function showCreateTagButton() {
    return (
      searchTerm &&
      searchTerm !== '' &&
      !searchResults.some((tag) => tag.name.toLowerCase() === searchTerm.toLowerCase())
    )
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-pt-2 tw-max-h-56 tw-w-full tw-overflow-x-hidden tw-overflow-y-auto tw-break-all">
      {showCreateTagButton() && <CreateTagButton />}
      {searchResults.map((tag) => (
        <div key={tag.id} className={classNames.tagContainer.base} style={{ minHeight: '40px' }}>
          <Checkbox
            className="tw-mr-2"
            onClick={() => handleTagSelectBox(tag)}
            isChecked={isTagSelected(tag)}
            label={tag.name}
            id={`tag-checkbox-${tag.id}`}
            indeterminate={isTagIndeterminate(tag)}
          />
          {tag.meta.permissions.update && (
            <IconButton
              color="transparent"
              onClick={(e: MouseEvent<HTMLButtonElement>) => handleEditTagClick(e, tag)}
              size="xs"
              data-testid={`tag-edit-button-${tag.id}`}
              className="tw-mr-2"
            >
              <Pencil className="tw-text-cornflower-500 lu-md lu-light" />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  )
}

function NoTags() {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-flex-col">
      <img src="/images/states/not-found.svg" alt="No Tags Found" />
      <h5>Oops, no tags were found!</h5>
      <p>Create a new tag by typing in the box above</p>
    </div>
  )
}

function CreateTagButton() {
  const { searchTerm, createAndSelectCompanyTag } = useCompanyTaggingContext()

  async function handleClick() {
    if (!searchTerm || searchTerm.includes(' ')) return
    await createAndSelectCompanyTag(searchTerm)
  }

  return (
    <div
      className={classNames.newTagContainer}
      onClick={handleClick}
      style={{ minHeight: '40px' }}
      data-testid="create-tag"
    >
      {searchTerm} (Create new tag)
    </div>
  )
}
