import { ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/core/button'
import { GenAiRequest, GenAiRequestStatus } from 'lib/api/gen-ai/gen-ai-requests'
import { BetaBadge } from 'lib/components/badge/badge'
import ExternalLink from 'lib/components/links/external-link'
import Modal from 'lib/components/modals/modal'
import { useDirectionsContext } from '../../providers/timeline-directions-provider'
import { useGenAiContext } from '../../providers/gen-ai-provider'
import Checkbox from 'components/core/checkbox'
import ConfirmationModal from 'components/elements/confirmation-modal'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'
import { AnimatedLoadingScreen } from '../../../requests/empty-screens'

interface ConfirmationMessage {
  confirmAction: () => void | Promise<void>
  message: string
  title: string
  visible: boolean
}

interface ModalProps {
  open: boolean
  request: GenAiRequest
  setOpen: Dispatch<SetStateAction<boolean>>
}

enum ModalState {
  Progress = 'progress',
  Results = 'results',
  Error = 'error',
}

const classNames = {
  buttons: {
    secondary:
      'tw-bg-peppercorn-50 hover:tw-bg-peppercorn-100 tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-px-4 tw-py-2 tw-border-0 sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm',
    wrapper: 'tw-flex tw-justify-end tw-gap-2 tw-mt-4',
  },
  progress: {
    container: 'tw-text-center',
    img: 'tw-mx-auto',
    text: 'tw-mx-auto',
  },
  title: 'tw-text-lg tw-font-bold tw-flex tw-items-center tw-gap-1',
}

const emptyConfirmationMessage: ConfirmationMessage = Object.freeze({
  confirmAction: () => null,
  message: '',
  title: '',
  visible: false,
})

const PROMPT_PLACEHOLDER = 'Begin your prompt here about what you want to create'

function Subheader() {
  return (
    <p>
      Use our AI text generator to kickstart your creative request!{' '}
      <ExternalLink href="https://designpickle.com/kb/request-assist">Learn More</ExternalLink>
    </p>
  )
}

function firstFive(directions: string[]): string[] {
  return directions ? directions.slice(0, 5) : []
}

function directionID(direction: string): string {
  return direction.replaceAll(' ', '-') + '-checkbox'
}

export default function RequestAssistResultsModal({ open, request, setOpen }: ModalProps): ReactElement {
  const [confirmationMessage, setConfirmationMessage] = useState<ConfirmationMessage>(emptyConfirmationMessage)
  const [modalState, setModalState] = useState<ModalState>(ModalState.Progress)
  const [newPrompt, setNewPrompt] = useState<string>('')
  const [selection, setSelection] = useState<string[]>([])

  const { addAiTasks } = useDirectionsContext()
  const { createRequestAssist, removeAndAccept, removeAndCancel, removeAndDismiss, removeAndRetry } = useGenAiContext()

  const genAiDirections = useMemo(() => {
    return firstFive(request?.response?.ds?.results as string[])
  }, [request?.response?.ds?.results])

  function handleAddToRequest() {
    addAiTasks(selection, request.id)
    removeAndAccept(request.id)
    setOpen(false)
    reset()
  }

  function handleAfterConfirmationClose() {
    setConfirmationMessage(emptyConfirmationMessage)
  }

  function handleCancel() {
    removeAndCancel(request.id)
    setOpen(false)
    reset()
  }

  function handleCancelConfirmation() {
    setConfirmationMessage((previous) => ({ ...previous, visible: false }))
  }

  function handleConfirmDelete() {
    removeAndDismiss(request.id)
    setOpen(false)
    reset()
  }

  async function handleConfirmRegenerate(): Promise<void> {
    try {
      await createRequestAssist(newPrompt)
      removeAndDismiss(request.id)
    } catch (error) {
      console.error('There was an error when attempting to regenerate this prompt.', error)
    }
  }

  function handleDelete() {
    setConfirmationMessage({
      confirmAction: handleConfirmDelete,
      message: 'Results will be removed, items not added to the request will be lost.',
      title: 'Delete Results',
      visible: true,
    })
  }

  function handlePromptChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setNewPrompt(event.target.value)
  }

  function handleRegenerate() {
    setConfirmationMessage({
      confirmAction: handleConfirmRegenerate,
      message: 'Regenerate will refresh results, items not added to the request will be lost.',
      title: 'Regenerate Results',
      visible: true,
    })
  }

  function handleRetry() {
    removeAndRetry(request)
    setOpen(false)
    reset()
  }

  function handleSelectAllClick() {
    if (selection.length !== genAiDirections.length) {
      setSelection(genAiDirections)
    } else {
      setSelection([])
    }
  }

  function handleSelectClick(direction: string) {
    if (selection.includes(direction)) {
      setSelection(selection.filter((d) => d !== direction))
    } else {
      setSelection([...selection, direction])
    }
  }

  function reset() {
    setSelection([])
  }

  useEffect(() => {
    if (request) {
      setNewPrompt(request.message)
      if (request.status === GenAiRequestStatus.Completed) {
        setSelection(firstFive(request?.response?.ds?.results as string[]))
        setModalState(ModalState.Results)
      } else if (request.status === GenAiRequestStatus.Error) {
        setModalState(ModalState.Error)
      }
    }
  }, [request])

  return (
    <Modal open={open} setOpen={setOpen} size="lg">
      <Modal.Header>
        <span className={classNames.title}>
          <FontAwesomeIcon icon={['far', 'sparkles']} />
          <span>
            Request Assist <BetaBadge />
          </span>
        </span>
      </Modal.Header>

      <Modal.Body setOpen={setOpen} closeButton>
        {modalState === ModalState.Progress && (
          <div className={classNames.progress.container}>
            <AnimatedLoadingScreen />
            <div style={{ width: '440px' }} className={classNames.progress.text}>
              <p>
                <strong>Your generative results are brining...</strong>
              </p>
              <p>
                You can click the “X” button on the top right to minimize this window. Your Request Assist prompt will
                run in the background while you continue with your request.
              </p>
            </div>
          </div>
        )}

        {modalState === ModalState.Error && (
          <>
            <Subheader />
            <div className="tw-text-center tw-my-20">
              <img
                alt="Crying Pickle Error"
                className="tw-mx-auto tw-mb-6"
                height="333"
                src="/images/states/error-crying-pickle.svg"
              />
              <h2 className="tw-text-neutral-500 tw-text-lg">Oh no!</h2>
              <p className="tw-mx-auto tw-max-w-md">
                Looks like something failed, click &quot;Retry&quot; to load again!
              </p>
            </div>
          </>
        )}

        {modalState === ModalState.Results && (
          <>
            <ConfirmationModal
              afterLeave={handleAfterConfirmationClose}
              title={confirmationMessage.title}
              message={confirmationMessage.message}
              confirmBtnText="Confirm"
              confirmAction={confirmationMessage.confirmAction}
              cancelBtnText="Cancel"
              cancelAction={handleCancelConfirmation}
              visible={confirmationMessage.visible}
            />
            <Subheader />
            <h5>Your prompt</h5>
            <div className="tw-flex tw-flex-row tw-mt-1.5 tw-mb-6">
              <Textarea onChange={handlePromptChange} placeholder={PROMPT_PLACEHOLDER} value={newPrompt} />
              <div className="tw-ml-2 tw-flex tw-flex-col tw-justify-between tw-my-2.5">
                <div className="tw-flex-grow tw-text-right">
                  <Button color="neutralGray" onClick={handleRegenerate}>
                    Regenerate
                  </Button>
                </div>
                <div className="tw-flex-none">
                  <TextCounter className="tw-text-left" value={newPrompt} />
                </div>
              </div>
            </div>
            <h5>Here are your results</h5>
            <p className="tw-mb-1">
              You can add any or all directions to your request. You&apos;ll still have an opportunity to edit them
              before submitting your request.
            </p>
            <div className="tw-px-2 tw-py-4 tw-bg-neutral-50 tw-mb-6">
              <Checkbox
                onChange={handleSelectAllClick}
                checked={selection.length === genAiDirections.length}
                label="Select All"
                id="select-all-directions"
              />
              <hr />
              {genAiDirections.map((direction) => (
                <Checkbox
                  key={directionID(direction)}
                  id={directionID(direction)}
                  onChange={() => handleSelectClick(direction)}
                  checked={selection.includes(direction)}
                  label={direction}
                  className="tw-mt-4"
                />
              ))}
            </div>

            <p className="tw-text-xs tw-mt-4">
              This content was created with the help of advanced AI technology, which is constantly improving. Please
              check it for accuracy before submitting your request.
            </p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {modalState === ModalState.Results && (
          <div className="tw-flex tw-justify-between tw-w-full tw-items-center">
            <span className="tw-font-bold">
              {selection.length} of {genAiDirections.length} items selected
            </span>
            <div className={classNames.buttons.wrapper}>
              <Button color="lightGray" type="button" onClick={handleDelete}>
                Delete results
              </Button>
              <Button color="purple" type="button" onClick={handleAddToRequest} disabled={!selection.length}>
                Add to request
              </Button>
            </div>
          </div>
        )}
        {modalState === ModalState.Progress && (
          <div className={classNames.buttons.wrapper}>
            <Button color="lightGray" type="button" onClick={handleCancel}>
              Cancel generation
            </Button>
          </div>
        )}

        {modalState === ModalState.Error && (
          <div className={classNames.buttons.wrapper}>
            <Button color="purple" type="button" onClick={handleRetry}>
              Retry
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}
