import { useMemo } from 'react'
import { SupportingDocumentation } from 'interfaces/supporting-documentation'

interface SupportingDocumentationTextProps {
  supportingDocumentation: SupportingDocumentation
}

export default function SupportingDocumentationText({
  supportingDocumentation,
}: SupportingDocumentationTextProps): JSX.Element {
  const { body } = supportingDocumentation

  const htmlBody = useMemo(() => {
    const regex = /(https?:\/\/[^\s$.?#].\S*)/g // checks if it is a URL
    const matches = Array.from(body?.matchAll(regex))
    const brTag = '<br data-testid="new-line">'

    if (matches?.length === 0) return { __html: body.replaceAll('\n', brTag) }

    // This adds the body to the front of the array,
    // converts the regex matches to string, and replaces the match with html
    const results = [body]
      .concat(...matches.map((match) => match[0]))
      .reduce((accumulator, url) =>
        accumulator.replace(url, `<a data-testid="note-link" href="${url}" target="_blank">${url}</a>`)
      )

    return { __html: results.replaceAll('\n', brTag) }
  }, [body])

  return <div className="tw-leading-6 tw-break-words tw-break-all tw-max-w-xs" dangerouslySetInnerHTML={htmlBody}></div>
}
