import { Switch } from '@headlessui/react'
import Tooltip from 'lib/components/tooltip/tooltip'
import { Info } from 'lucide-react'

export default function ShareToggle({ isShared, onToggle }: { isShared: boolean; onToggle: () => void }) {
  return (
    <Switch.Group>
      <div className="tw-flex tw-items-center tw-gap-2">
        <Switch
          checked={isShared}
          onChange={onToggle}
          className={`${
            isShared ? 'tw-bg-gherkin-500' : 'tw-bg-neutral-200'
          } tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-border-none tw-flex-shrink-0 tw-transition-colors tw-duration-200 tw-ease-in-out`}
          style={{ width: '38px', height: '18px', padding: '2px' }}
        >
          <span className="sr-only">Enable link</span>
          <span
            aria-hidden="true"
            className={`${
              isShared ? 'tw-translate-x-4' : 'tw--translate-x-1'
            } tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out`}
            style={{ width: '14px', height: '14px', transform: `translateX(${isShared ? '20px' : '0px'})` }}
          />
        </Switch>
        <Switch.Label className="tw-mb-0 tw-text-black tw-flex tw-items-center tw-gap-2 tw-flex-row">
          Enable link{' '}
          <Tooltip content={'For more information on what collaborators can see, refer to the knowledge base'}>
            <Info className="tw-text-peppercorn-400 tw-w-4 tw--mt-1" />
          </Tooltip>
        </Switch.Label>
      </div>
    </Switch.Group>
  )
}
