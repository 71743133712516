import { ReactElement, useRef, useState } from 'react'
import { Pencil, Trash } from 'lucide-react'
import Avatar from 'components/core/avatar'
import { ConversationUser } from 'lib/api/timeline/timeline'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { displayDate } from 'lib/util/date'
import Badge from 'lib/components/badge/badge'
import EditCommentForm from './edit-comment-form'

import './comment.scss'

interface CommentProps {
  body: string
  createdAt: string
  editable?: boolean
  onDelete?: () => void
  onSubmit?: (body: string) => Promise<void>
  user: ConversationUser
}

const classNames = {
  article: 'tw-mb-4 tw-bg-neutral-50 tw-text-neutral-800 tw-rounded-lg tw-p-4',
  body: 'tw-py-4 tw-whitespace-pre-wrap',
  header: {
    avatar: 'tw-shrink',
    byline: 'tw-flex-grow tw-text-left tw-flex',
    container: 'tw-flex tw-direction-row tw-justify-between tw-items-center tw-p-1 tw-gap-2',
  },
}

export default function Comment({
  body,
  createdAt,
  editable = false,
  onDelete,
  onSubmit,
  user,
}: CommentProps): ReactElement {
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const bodyRef = useRef<HTMLDivElement>()

  function handleDeleteClick() {
    onDelete()
  }

  function handleEditClick() {
    setIsEditMode(true)
  }

  function handleOnCancel() {
    setIsEditMode(false)
  }

  async function handleOnSubmit(body: string) {
    if (onSubmit) {
      await onSubmit(body)
    }
    setIsEditMode(false)
  }

  return (
    <article className={classNames.article}>
      <header className={classNames.header.container}>
        <div className={classNames.header.avatar}>
          <Avatar src={user.avatar} name={user.fullName} />
        </div>
        <div className={classNames.header.byline}>
          <div>{user.fullName}</div>
          <Badge style="pill" color="light">
            {displayDate(createdAt)}
          </Badge>
        </div>
        {editable && (
          <IconFlyoutMenu adjustedLeft color="transparent" icon={['far', 'ellipsis-v']} viewportBottomThreshold={230}>
            <IconFlyoutMenu.Button onClick={handleEditClick}>
              <div className="tw-flex tw-flex-row tw-items-center">
                <Pencil className="lu-sm tw-mr-1 tw-font-semibold" />
                Edit
              </div>
            </IconFlyoutMenu.Button>
            <IconFlyoutMenu.Button onClick={handleDeleteClick}>
              <div className="tw-flex tw-flex-row tw-items-center">
                <Trash className="lu-sm tw-mr-1 tw-text-red-700 tw-font-semibold" />
                <span className="tw-text-red-700">Delete</span>
              </div>
            </IconFlyoutMenu.Button>
          </IconFlyoutMenu>
        )}
      </header>
      <div ref={bodyRef} className={classNames.body}>
        {editable && isEditMode && <EditCommentForm onCancel={handleOnCancel} onSubmit={handleOnSubmit} body={body} />}
        {(!editable || !isEditMode) && <div className="comment" dangerouslySetInnerHTML={{ __html: body }}></div>}
      </div>
    </article>
  )
}
