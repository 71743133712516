import { Dispatch, SetStateAction } from 'react'

export enum TicketFeedbackValues {
  Unrated = 'unrated',
  ThumbsUp = 'thumbs_up',
  ThumbsDown = 'thumbs_down',
}

export enum RatingsSteps {
  Rate,
  Feedback,
  ThankYou,
}

export type SetStepType = Dispatch<SetStateAction<RatingsSteps>>
