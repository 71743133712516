import TextInput from 'lib/components/text-input/text-input'
import { Filters } from './admin-formats-page'
import Button from 'components/core/button'

const classNames = {
  button: 'tw-my-2',
  filtersWrapper: 'tw-mb-4 tw-relative tw-flex tw-justify-between',
  separator: 'tw-px-2 tw-pt-2.5 tw-text-xl tw-text-peppercorn-100',
}

export interface AdminSkillsPageFiltersProps {
  filters: Filters
  handleClear: (e) => void
  onSubmit: (e) => void
  setFilters: (filters: Filters) => void
}

export default function AdminSkillsPageFilters({
  filters,
  handleClear,
  onSubmit,
  setFilters,
}: AdminSkillsPageFiltersProps) {
  return (
    <form data-testid={'admin-formats-filters-form'} onSubmit={onSubmit}>
      <div className={classNames.filtersWrapper}>
        <div className="tw-flex tw-gap-4 tw-w-80">
          <TextInput
            data-testid="nameEq"
            placeholder="Search by format name"
            onChange={(e) => setFilters({ ...filters, nameCont: e.target.value })}
            value={filters.nameCont}
          />
        </div>
        <div className="tw-flex tw-gap-4">
          <Button className={classNames.button} type="submit">
            Search
          </Button>
          <Button className={classNames.button} color="neutralGray" onClick={handleClear}>
            Clear
          </Button>
        </div>
      </div>
    </form>
  )
}
