import { createContext, useContext, useEffect, useState } from 'react'
import {
  updateDesignerAllocation,
  createDesignerAllocation,
  deleteDesignerAllocation,
  getDesigners,
  CompanyDesignerAllocation,
} from 'lib/api/company-designer-allocations/company-designer-allocations'
import { Designer } from 'interfaces/designer'
import { snakeCaseKeys } from 'lib/object/utils'
import { toast } from 'lib/components/toast/toast'

interface ContextProps {
  children: JSX.Element
  companyDesignerAllocation: CompanyDesignerAllocation
}

interface DesignerAllocationsContextValue {
  companyAllocation: CompanyDesignerAllocation
  removeDesignerAllocation: (params) => void
  designerOptions: Designer[]
  upsertDesignerAllocation: (params) => void
  isLoading: boolean
}

const DesignerAllocationsContext = createContext({})

export function useDesignerAllocationsContext(): DesignerAllocationsContextValue {
  return useContext(DesignerAllocationsContext) as DesignerAllocationsContextValue
}

export default function DesignerAllocationsProvider({
  children,
  companyDesignerAllocation,
}: ContextProps): JSX.Element {
  const [companyAllocation, setCompanyAllocation] = useState<CompanyDesignerAllocation>(companyDesignerAllocation)
  const { company } = companyAllocation || companyDesignerAllocation
  const [designers, setDesigners] = useState<Designer[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const designersFromCompanyApm: Designer[] =
    company.apmIds.length > 0 ? designers.filter((designer) => company.apmIds.includes(designer.apmId)) : designers
  const designerOptions = designersFromCompanyApm.filter((designer) => !company.designerIds.includes(designer.id))

  useEffect(() => {
    let isAbandoned = false

    getDesigners()
      .then((response) => {
        if (!isAbandoned) {
          setDesigners(response.designers)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error('Designers could not be fetched.')
        console.error('Designers could not be fetched.', error)
      })

    return () => {
      isAbandoned = true
    }
  }, [])

  async function upsertDesignerAllocation(params) {
    setIsLoading(true)
    const upsertDesignerAllocation = params.id ? updateDesignerAllocation : createDesignerAllocation
    try {
      const result = await upsertDesignerAllocation(params)
      setCompanyAllocation(result)
      toast.success(`Designer allocation ${params.id ? 'updated' : 'created'} successfully`)
    } catch (error) {
      toast.error(`Designer allocation could not be ${params.id ? 'updated' : 'created'}.`)
      console.error('Designer allocation could not be updated.', error)
    } finally {
      setIsLoading(false)
    }
  }

  async function removeDesignerAllocation(params) {
    setIsLoading(true)
    try {
      const result = await deleteDesignerAllocation(snakeCaseKeys(params))
      setCompanyAllocation(result)
      toast.success('Designer allocation deleted successfully')
    } catch (error) {
      toast.error('Designer allocation could not be deleted.')
      console.error('Designer allocation could not be deleted.', error)
    } finally {
      setIsLoading(false)
    }
  }

  const context: DesignerAllocationsContextValue = {
    companyAllocation,
    removeDesignerAllocation,
    designerOptions,
    upsertDesignerAllocation,
    isLoading,
  }

  return <DesignerAllocationsContext.Provider value={context}>{children}</DesignerAllocationsContext.Provider>
}
