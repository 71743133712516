import { ChangeEvent, FormEvent, ReactElement, useState } from 'react'
import Button from 'components/core/button'
import TextInput from 'lib/components/text-input/text-input'
import { AuthRoute, useUserContext } from 'providers/user-provider'
import AuthBranding from './auth-branding'

export default function Login(): ReactElement {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [hasErrors, setHasErrors] = useState(false)

  const { auth, settings } = useUserContext()

  async function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const result = await auth.authenticate(email, password)
    if (result === false) {
      setHasErrors(true)
    }
  }

  return (
    <AuthBranding>
      <form onSubmit={submit}>
        <h1 className="tw-text-center tw-text-base tw-m-0">Log in to your collaborator account</h1>
        <TextInput
          name="user[email]"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
          placeholder="Email"
          autoComplete="email"
          error={hasErrors}
        />
        <TextInput
          name="user[password]"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
          placeholder="Password"
          type="password"
          autoComplete="current-password"
          error={hasErrors}
        />

        {settings.customBranding ? (
          <a
            href=""
            onClick={(e) => {
              e.preventDefault()
              auth.setAuthRoute(AuthRoute.FORGOTPASSWORD)
            }}
            className="tw-block tw-mb-6"
          >
            Forgot your password?
          </a>
        ) : (
          <a href="/users/password/new" className="tw-block tw-mb-6">
            Forgot your password?
          </a>
        )}
        <div className="tw-text-center">
          <Button color="purple" type="submit">
            Log in
          </Button>
          <p>
            New to collaboration?{' '}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault()
                auth.setAuthRoute(AuthRoute.SIGNUP)
              }}
            >
              Sign up here
            </a>
          </p>
        </div>
      </form>
    </AuthBranding>
  )
}
