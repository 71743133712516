import { useEffect } from 'react'
import SingleSelect, { OptionType } from 'components/core/single-select'

interface EditableTransferReasonProps {
  reason: string
  editMode: boolean
  newReason: OptionType
  setNewReason: (value: OptionType) => void
}

export const statusSelectOptions = [
  { value: '0', label: 'Creative Quality' },
  { value: '1', label: 'Turnaround Time' },
  { value: '2', label: 'Customer Handling' },
  { value: '3', label: 'Other' },
]

export function getReasonFromValue(value: string): string {
  const foundOption = statusSelectOptions.find((option) => option.value === value?.toString())
  return foundOption?.label || 'N/A'
}

export default function EditableTransferReason({
  reason,
  editMode,
  newReason,
  setNewReason,
}: EditableTransferReasonProps): JSX.Element {
  useEffect(() => {
    if (!newReason) {
      setNewReason({
        value: reason || 'not_applicable',
        label: getReasonFromValue(reason) || 'N/A',
      })
    }
  }, [newReason, reason, setNewReason])

  if (editMode) {
    return (
      <td>
        <SingleSelect
          name="transfer-reason"
          inputId="transfer-reason"
          placeholder="Select Transfer Reason"
          className="tw-w-52 tw-mr-2"
          options={statusSelectOptions}
          value={newReason}
          onChange={(value: OptionType) => setNewReason(value)}
        />
      </td>
    )
  }

  return <td>{getReasonFromValue(newReason?.value?.toString())}</td>
}
