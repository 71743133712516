import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cn } from 'lib/util/cn'
import { HTMLAttributes } from 'react'

const classNames = {
  container: `
    tw-flex tw-justify-center tw-relative
  `,
  input: `
    tw-block
    tw-appearance-none
    tw-rounded-md
    tw-border
    tw-border-solid
    tw-p-2
    tw-pl-10
    tw-shadow-none
    tw-outline-none
    tw-text-lg
    placeholder:tw-text-neutral-500
    focus:tw-ring-2
    focus:tw-ring-cornflower-500
  `,
  icon: `
    tw-text-neutral-500
    tw-absolute
    tw-top-1/2
    tw-transform
    tw--translate-y-1/2
    tw-left-10
    tw-w-4
    tw-h-4
    tw-pointer-events-none
    tw-p-4
  `,
}

interface SearchProps {
  value: string
  onChange: (string: string) => void
  placeholder: string
  name?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
}

export default function Search({ value, onChange, placeholder = 'Search', name = 'search', className }: SearchProps) {
  return (
    <div className={cn(classNames.container, className)}>
      <FontAwesomeIcon icon={['far', 'search']} className={classNames.icon} />
      <input
        name={name}
        placeholder={placeholder}
        className={classNames.input}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}
