import { baseRequest, BaseResponse, getApi } from '../api'

export interface Announcement {
  id: number
  heading: string
  body: string
}

export interface AnnouncementsResponse extends BaseResponse {
  announcements: Announcement[]
}

export async function getAnnouncements(): Promise<AnnouncementsResponse> {
  const announcementsUrl = '/api/internal/announcements'
  const response = await baseRequest(announcementsUrl, getApi)

  return {
    error: response.error,
    status: response.status,
    announcements: response.data as unknown as Announcement[],
  }
}
