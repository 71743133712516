import { Dispatch } from 'react'
import Checkbox from 'components/core/checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { humanize } from 'lib/string/utils'
import SingleSelect from 'components/core/single-select'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import styles from './filters.module.scss'

interface CheckboxFilterProps {
  filters: Record<string, unknown>
  filterKey: string
  setFilters: Dispatch<Record<string, unknown>>
  setLabel: string
}

export function CheckboxFilter({ filters, filterKey, setFilters, setLabel }: CheckboxFilterProps): JSX.Element {
  return (
    <Checkbox
      label={`${setLabel}`}
      className={styles.checkboxFilter}
      checked={filters[filterKey] as boolean}
      onChange={(e) =>
        setFilters({
          ...filters,
          [filterKey]: e.target.checked,
        })
      }
    />
  )
}

interface CancellationDefaultFilterProps {
  filters: Record<string, unknown>
  filterKey: string
  setFilters: Dispatch<Record<string, unknown>>
}

interface CancellationReasonsFilterProps extends CancellationDefaultFilterProps {
  cancellationReasonsOptions: string[]
}

export function CancellationReasonsFilter({
  cancellationReasonsOptions,
  filters,
  setFilters,
  filterKey,
}: CancellationReasonsFilterProps): JSX.Element {
  let option

  if (filters[filterKey]) {
    option = {
      label: humanize(filters[filterKey]),
      value: filters[filterKey],
    }
  }

  return (
    <>
      <label className={styles.filterTitle} htmlFor="cancellation-reasons">
        Cancellation Reasons
      </label>
      {!cancellationReasonsOptions ? (
        <FontAwesomeIcon icon="spinner-third" spin />
      ) : (
        <SingleSelect
          name="cancellation-reasons"
          inputId="cancellation-reasons"
          className={styles.input}
          value={option}
          placeholder="Select Cancellation Reason"
          options={cancellationReasonsOptions.map((option) => ({
            value: option,
            label: humanize(option),
          }))}
          onChange={(e) => {
            const newFilter = { ...filters }
            newFilter[filterKey] = String(e.value)
            setFilters({
              ...newFilter,
            })
          }}
        />
      )}
    </>
  )
}

interface RefundStatusFilterProps extends CancellationDefaultFilterProps {
  refundStatusOptions: string[]
}

export function RefundStatusFilter({
  refundStatusOptions,
  filters,
  setFilters,
  filterKey,
}: RefundStatusFilterProps): JSX.Element {
  let option

  if (filters[filterKey]) {
    option = {
      label: humanize(filters[filterKey]),
      value: filters[filterKey],
    }
  }

  return (
    <>
      <label className={styles.filterTitle} htmlFor="refund-status">
        Refund Status
      </label>
      {!refundStatusOptions ? (
        <FontAwesomeIcon icon="spinner-third" spin />
      ) : (
        <SingleSelect
          name="refund-status"
          inputId="refund-status"
          className={styles.input}
          value={option}
          placeholder="Select Status"
          options={refundStatusOptions.map((option) => ({
            value: option,
            label: humanize(option),
          }))}
          onChange={(e) => {
            const newFilter = { ...filters }
            newFilter[filterKey] = String(e.value)
            setFilters({
              ...newFilter,
            })
          }}
        />
      )}
    </>
  )
}

interface PredictedLabelsFilterProps extends CancellationDefaultFilterProps {
  predictedLabelsOptions: Record<string, number>
}

export function PredictedLabelsFilter({
  predictedLabelsOptions,
  filters,
  setFilters,
  filterKey,
}: PredictedLabelsFilterProps): JSX.Element {
  const FilterWrapper = ({ children }: { children: JSX.Element }): JSX.Element => (
    <>
      <label className={styles.filterTitle} htmlFor="predicted-label">
        Status
      </label>
      {children}
    </>
  )

  if (!predictedLabelsOptions)
    return (
      <FilterWrapper>
        <FontAwesomeIcon icon="spinner-third" spin />
      </FilterWrapper>
    )

  const filterLabel = Object.entries(predictedLabelsOptions).find(([, id]) => `${id}` === filters[filterKey])

  let option

  if (filters[filterKey] && filterLabel) {
    option = {
      label: humanize(filterLabel[0]),
      value: filters[filterKey],
    }
  }

  return (
    <FilterWrapper>
      <SingleSelect
        name="predicted-label"
        inputId="predicted-label"
        className={styles.input}
        value={option}
        placeholder="Select Status"
        options={Object.entries(predictedLabelsOptions).map(([label, id]) => ({
          value: id,
          label: humanize(label),
        }))}
        onChange={(e) => {
          setFilters({
            ...filters,
            [filterKey]: String(e.value),
          })
        }}
      />
    </FilterWrapper>
  )
}

interface DateFilterProps {
  selected: Date
  onChange: (date: Date) => void
  placeholder: string
}

export const DateFilter = ({ selected, onChange, placeholder }: DateFilterProps): JSX.Element => (
  <div className={styles.dateFilter}>
    <FontAwesomeIcon icon="calendar" size="1x" className={styles.calendarIcon} />
    <DatePicker
      className={`${styles.sharedTextInputs}`}
      selected={selected}
      onChange={onChange}
      selectsStart
      placeholderText={placeholder}
      popperClassName={styles.popperClassName}
    />
  </div>
)
