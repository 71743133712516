import useSidebarState from 'lib/hooks/useSidebarState'
import { cn } from 'lib/util/cn'
import { ArrowLeftToLine, ArrowRightToLine, ChevronLeft, ChevronRight, ExternalLink, LucideIcon } from 'lucide-react'
import IconButton from '../buttons/icon-button'
import { useState, HTMLAttributes } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '../popover/popover'
import { MenuItem, adminItems, mainItems } from './sidebar-menus'
import Button from 'components/core/button'
import { cva } from 'class-variance-authority'

interface SubMenuProps {
  item: MenuItem
  toggleFlyout: () => void
}

const SubMenu = ({ item, toggleFlyout }: SubMenuProps) => {
  return (
    <>
      {item.Icon && (
        <div
          className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-text-base tw-font-semibold
      tw-text-neutral-800 tw-mb-1"
        >
          <IconButton size="xs" color="secondary" onClick={toggleFlyout}>
            <ChevronLeft />
          </IconButton>
          {item.Icon && <item.Icon className="tw-min-w-6 tw-min-h-6" />}
          {item.label}
        </div>
      )}
      {item.submenu?.map((subItem) => (
        <SidebarItem key={subItem.label} isOpen={true} item={subItem} popoverClassName="tw-ml-2" />
      ))}
    </>
  )
}

interface SidebarItemProps {
  isOpen: boolean
  item: MenuItem
  selected?: boolean
  popoverClassName?: HTMLAttributes<HTMLDivElement>['className']
  RightIcon?: LucideIcon
}

const sidebarItemVariants = cva(
  'tw-flex tw-items-center tw-py-2 tw-px-2 tw-text-neutral-800 tw-rounded tw-cursor-pointer tw-transition-colors tw-duration-300 tw-overflow-hidden',
  {
    variants: {
      isOpen: {
        true: 'tw-gap-2 tw-text-base tw-w-full',
        false: 'tw-flex-col tw-rounded-lg tw-py-3 tw-gap-0 tw-text-xs tw-justify-center tw-w-20',
      },
    },
  }
)

const SidebarItem = ({ isOpen, item, selected, popoverClassName, RightIcon }: SidebarItemProps) => {
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const toggleFlyout = () => {
    setFlyoutOpen(!flyoutOpen)
  }

  return (
    <Popover open={flyoutOpen} onOpenChange={setFlyoutOpen}>
      <PopoverTrigger asChild>
        <div
          className={cn(
            sidebarItemVariants({ isOpen }),
            selected &&
              'tw-bg-cornflower-100 tw-border tw-border-white tw-border-solid tw-font-semibold tw-ring-2 tw-ring-offset-1 tw-ring-cornflower-100'
          )}
        >
          {item.Icon && <item.Icon className="tw-min-w-6 tw-min-h-6" />}
          {item.label}
          {item.submenu && isOpen && <ChevronRight className="tw-min-w-4 tw-min-h-4 tw-ml-auto" />}
          {RightIcon && <RightIcon className="tw-min-w-4 tw-min-h-4 tw-ml-auto" />}
        </div>
      </PopoverTrigger>
      {item.submenu && (
        <PopoverContent className={popoverClassName} intent="flyout" side="right" align="start">
          <SubMenu item={item} toggleFlyout={toggleFlyout} />
        </PopoverContent>
      )}
    </Popover>
  )
}

interface SidebarProps {
  version: string
}

const Sidebar = ({ version }: SidebarProps) => {
  const [isOpen, setIsOpen] = useSidebarState()

  return (
    <div className={cn('tw-transition-all tw-duration-300', isOpen ? 'tw-w-52' : 'tw-w-24')}>
      <div
        className={cn(
          'tw-h-full tw-bg-white tw-shadow-lg tw-transition-all tw-duration-300 tw-px-2 tw-py-4',
          'tw-fixed tw-flex-col tw-flex tw-h-full tw-bg-white tw-overflow-hidden',
          isOpen ? 'tw-w-52' : 'tw-w-24'
        )}
      >
        <div className="tw-absolute tw-w-52 tw-h-full tw-flex tw-flex-col tw-left-0 tw-top-0 tw-px-2  tw-gap-1">
          <div className="tw-mt-16 tw-pt-4 tw-pb-2">Admin</div>
          {adminItems.map((item) => (
            <SidebarItem key={item.label} isOpen={isOpen} item={item} />
          ))}
          <hr className="tw-my-2 tw-w-full tw-border-neutral-200" />
          <div className="tw-py-2">Main</div>
          {mainItems.map((item) => (
            <SidebarItem key={item.label} isOpen={isOpen} item={item} />
          ))}
          <hr className="tw-my-2 tw-w-full tw-border-neutral-200" />

          <SidebarItem
            item={{
              label: 'FreshStock',
              Icon: () => (
                <img width={24} height={24} src="/images/icons/fresh-stock-small-logo.svg" alt="FreshStock Logo" />
              ),
              RightIcon: ExternalLink,
            }}
            isOpen={isOpen}
          />
          <hr className="tw-my-2 tw-border-neutral-200 tw-w-full" />

          <div className={sidebarItemVariants({ isOpen, className: 'tw-mt-auto' })}>
            {isOpen ? (
              <>
                <Button className="tw-w-full" color="neutral" onClick={() => setIsOpen(!isOpen)}>
                  <ArrowLeftToLine className="tw-mr-2" />
                  Collapse
                </Button>
              </>
            ) : (
              <>
                <IconButton
                  color="secondary"
                  onClick={() => {
                    setIsOpen(!isOpen)
                  }}
                >
                  <ArrowRightToLine />
                </IconButton>
                Expand
              </>
            )}
          </div>
          <hr className="tw-my-2 tw-border-neutral-200 tw-w-full" />
          <div className={sidebarItemVariants({ isOpen })}>
            <div className="tw-text-xs tw-text-neutral-500 tw-text-center tw-w-full tw-py-2">
              {isOpen ? 'Version' : 'V.'} {version}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
