import { validateEmail } from 'lib/util/email'
import { X } from 'lucide-react'
import { useRef, useState } from 'react'
import { toast } from 'lib/components/toast/toast'

export default function InviteCollaboratorsInput({
  allCollaborators,
  collaborators,
  setCollaborators,
  className = '',
}: {
  allCollaborators: string[]
  collaborators: string[]
  setCollaborators: (value: string[]) => void
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}) {
  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef(null)
  const wrapperId = 'invite_collaborators_input'

  function removeCollaborator(collaboratorToRemove: string) {
    setCollaborators(collaborators.filter((collaborator) => collaborator !== collaboratorToRemove))
  }

  function handleSubmit(value: string) {
    const email = value.trim()

    if (!validateEmail(email)) {
      toast.error('Invalid Email')
      return
    }
    if (collaborators.includes(email)) {
      toast.error('Email is already added')
      return
    }
    if (allCollaborators.includes(email)) {
      toast.error('Email is already invited')
      return
    }

    setInputValue('')
    setCollaborators([...collaborators, email])
  }

  function handleDivClick(e) {
    if (e.target.id === wrapperId) {
      inputRef.current.focus()
    }
  }

  return (
    <div className={className}>
      <div
        onClick={handleDivClick}
        id={wrapperId}
        className={`tw-w-full tw-appearance-none tw-rounded-md tw-border tw-border-solid tw-px-2 tw-my-2.5 tw-shadow-none tw-outline-none tw-py-2 tw-flex tw-items-center tw-justify-start tw-gap-2 tw-flex-wrap tw-cursor-text ${
          isFocused ? 'tw-border-cornflower-500 tw-shadow' : ''
        }`}
      >
        {collaborators.map((collaborator) => (
          <div
            className="tw-bg-gray-100 tw-px-2 tw-text-peppercorn-900 tw-h-8 tw-rounded tw-flex tw-items-center tw-gap-1 tw-cursor-default"
            key={collaborator}
          >
            <span>{collaborator}</span>
            <button
              onClick={() => {
                removeCollaborator(collaborator)
              }}
              className="tw-border-none tw-bg-transparent tw-outline-none hover:tw-bg-gray-200 tw-rounded tw-flex tw-items-center tw-justify-center tw-p-0 tw-w-6 tw-h-6 tw--mr-1"
            >
              <X className="lu-sm" />
            </button>
          </div>
        ))}
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit(event.target[0].value)
          }}
          className="tw-flex-1"
          style={{ minWidth: '150px' }}
        >
          <input
            ref={inputRef}
            value={inputValue}
            onChange={(e) => {
              const newValue = e.target.value
              setInputValue(newValue)
              if (newValue.endsWith(' ')) {
                handleSubmit(newValue)
              }
            }}
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={() => {
              setIsFocused(false)
            }}
            className="tw-border-none tw-outline-none tw-h-8 tw-w-full"
            placeholder={!collaborators.length ? 'Invite Collaborators' : ''}
          />
        </form>
      </div>
      <div>Collaborators will be sent an invitation email when you click &apos;Send&apos;</div>
    </div>
  )
}
