import { ReactElement, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { hasOnlyFalsyValues, snakeCaseKeys } from 'lib/object/utils'
import ScaleSubscriptionsFilters, { emptyScaleFilters, ScaleFilters } from '../scale/scale-subscriptions-filters'
import SubscriptionsList from '../shared/subscriptions-list'
import ScaleSubscriptionsItem from './scale-subscriptions-item'
import { Dpu } from 'interfaces/dpu'
import Pagination from 'components/elements/pagination'
import { Team } from 'interfaces/team'
import { flatten } from 'lib/array/utils'
import { Apm } from 'interfaces/apm'
import { getInitialFilters } from 'components/elements/admin-filters'
import { Toaster, toast } from 'lib/components/toast/toast'

async function fetchSubscriptions(filters, params) {
  const response = await axios.get(
    window.Routes.apiInternalDpusUrl({
      scope: 'scale',
      q: snakeCaseKeys(filters),
      ...params,
    })
  )
  return response.data
}

function ScaleSubscriptionsPage(): ReactElement {
  const initialFilters = getInitialFilters(emptyScaleFilters) as ScaleFilters
  const [subscriptions, setSubscriptions] = useState([])
  const [apms, setApms] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [filters, setFilters] = useState<ScaleFilters>(initialFilters)
  const [companyStatusOptions, setCompanyStatusOptions] = useState([])
  const teams: Team[] = flatten(apms.map((apm: Apm) => apm.teams))

  const oops = useCallback(() => toast.error('Oops, something went wrong.'), [])

  const fetchSubscriptionsCallback = useCallback(
    async (params = {}) => {
      const { dpus, count, per_page } = await fetchSubscriptions(filters, params)

      setSubscriptions(dpus)
      setPageCount(Math.ceil(count / per_page))
    },
    [filters]
  )

  useEffect(() => {
    if (hasOnlyFalsyValues(filters)) {
      fetchSubscriptionsCallback().catch(oops)
    }
  }, [fetchSubscriptionsCallback, filters, oops])

  useEffect(() => {
    fetchSubscriptionsCallback().catch(oops)
    fetchApms().catch(oops)
    fetchCompanyStatusOptions().catch(oops)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchCompanyStatusOptions = async () => {
    const { data } = await axios.get(window.Routes.apiInternalCompanyStatusIndexUrl())

    setCompanyStatusOptions(data)
  }

  const fetchApms = async () => {
    const { data } = await axios.get(window.Routes.apiInternalApmsUrl())

    setApms(data)
  }

  const onTeamChange = (dpuId, teamId, clusterId) => {
    const subs = subscriptions.map((subscription) => {
      return subscription.id === dpuId ? { ...subscription, clusterId, teamId } : subscription
    })

    setSubscriptions(subs)
  }

  const onPageClick = (data) => {
    fetchSubscriptionsCallback({ page: data.selected + 1 }).catch(() => null)
    window.scrollTo(0, 0)
  }

  return (
    <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row">
      <ScaleSubscriptionsFilters
        companyStatusOptions={companyStatusOptions}
        fetchSubscriptions={fetchSubscriptionsCallback}
        setFilters={setFilters}
        filters={filters}
        apms={apms}
        teams={teams}
      />
      {subscriptions.length > 0 ? (
        <div className="tw-flex-grow tw-w-10/12">
          <SubscriptionsList headerOne="Team" headerTwo="Cluster">
            {subscriptions.map((dpu: Dpu) => (
              <ScaleSubscriptionsItem dpu={dpu} key={dpu.id} onTeamChange={onTeamChange} teams={teams} />
            ))}
          </SubscriptionsList>
          <Pagination pageCount={pageCount} onPageChange={onPageClick} />
        </div>
      ) : (
        <div className="tw-flex tw-w-full tw-justify-center tw-items-center tw-text-2xl tw-font-medium">
          No results were found.
        </div>
      )}
    </div>
  )
}

// eslint-disable-next-line react/display-name
export default function (): ReactElement {
  return (
    <>
      <Toaster />
      <ScaleSubscriptionsPage />
    </>
  )
}
