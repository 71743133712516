import { ReactElement } from 'react'
import AdminHeaderCreativeButtons from './admin-header-creative-buttons'
import AdminHeaderQRButtons from './admin-header-qr-buttons'
import AdminHeaderOverflowMenu from './admin-header-overflow-menu'
import { routes } from 'lib/api/admin/tickets/admin-tickets'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'

export default function AdminHeaderButtons(): ReactElement {
  const { ticket } = useAdminTicketContext()

  const inProgressTickets = ticket.inProgressTickets?.filter((id) => id !== ticket.id)
  return (
    <div>
      <div className="tw-flex tw-items-center tw-gap-2 tw-justify-end">
        <AdminHeaderCreativeButtons />
        <AdminHeaderQRButtons />
        <AdminHeaderOverflowMenu />
      </div>
      {inProgressTickets?.length > 0 && (
        <div className="tw-pt-2 tw-pr-2 tw-flex tw-flex-col tw-items-end tw-justify-end">
          {inProgressTickets.map((id) => (
            <div key={id}>
              <a href={`${routes.show}/${id}`} data-testid={`progress-link-${id}`}>
                #{id}
              </a>{' '}
              is already in progress
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
