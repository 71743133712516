/**
 * File generated by js-routes 2.2.3
 * Based on Rails 7.0.7.2 routes of DesignPickle::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /onboarding/powerplan/:hc(.:format)
 * @param {any} hc
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const acceptPowerPlanSignupInvitationUrl = __jsr.r({"hc":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"powerplan"],[2,[7,"/"],[2,[3,"hc"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /onboarding/powerplan/:hc(.:format)
 * @param {any} hc
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const acceptPowerPlanSignupInvitation = __jsr.r({"hc":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"powerplan"],[2,[7,"/"],[2,[3,"hc"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/activate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activateAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/activate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activateAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/add_coupon(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addCouponAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_coupon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/add_coupon(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addCouponAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_coupon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/add_files(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addFilesAdminFreshStockAssetUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/add_files(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addFilesAdminFreshStockAsset = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id/add_suggested_asset(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addSuggestedAssetFreshStockCollectionUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_suggested_asset"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id/add_suggested_asset(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addSuggestedAssetFreshStockCollection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_suggested_asset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/projects/:id/add_tickets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addTicketsApiInternalProjectUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/projects/:id/add_tickets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addTicketsApiInternalProject = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addressUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"address"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const address = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"address"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /settings/address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addressSettingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"address"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /settings/address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addressSettings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"address"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/admins/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAdminUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admins/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAdmin = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admins(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAdminsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admins"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admins(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAdmins = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admins"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/announcements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAnnouncementUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/announcements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAnnouncement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/announcements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAnnouncementsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/announcements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAnnouncements = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/apms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminApmUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"apms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/apms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminApm = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"apms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/apms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminApmsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"apms"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/apms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminApms = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"apms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_assigner_time_zones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAutoAssignerTimeZonesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/auto_assigner_time_zones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminAutoAssignerTimeZones = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/blocklists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminBlocklistUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blocklists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/blocklists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminBlocklist = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blocklists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blocklists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminBlocklistsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blocklists"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/blocklists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminBlocklists = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blocklists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/cancellations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCancellationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/cancellations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCancellation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/canned_responses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCannedResponseUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/canned_responses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCannedResponse = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/canned_response_folders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCannedResponseFolderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_response_folders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/canned_response_folders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCannedResponseFolder = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_response_folders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/canned_response_folders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCannedResponseFoldersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_response_folders"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/canned_response_folders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCannedResponseFolders = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_response_folders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/canned_responses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCannedResponsesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/canned_responses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCannedResponses = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompaniesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompanies = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/company_daily_available_times(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompanyDailyAvailableTimesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"company_daily_available_times"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/company_daily_available_times(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompanyDailyAvailableTimes = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"company_daily_available_times"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/company_designer_allocations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompanyDesignerAllocationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/company_designer_allocations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompanyDesignerAllocation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/company_designer_allocations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompanyDesignerAllocationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/company_designer_allocations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminCompanyDesignerAllocations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/conversations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminConversationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/conversations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminConversation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/conversations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminConversationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/conversations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminConversations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDashboardUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDashboard = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDesignerUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDesigner = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designer_trackings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDesignerTrackingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designer_trackings"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designer_trackings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDesignerTrackings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designer_trackings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDesignersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminDesigners = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/export(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminExportDashboardUrl = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"export"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/export(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminExportDashboard = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"export"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedback(/*any)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFeedbackUrl = __jsr.r({"any":{},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedback"],[2,[1,[2,[7,"/"],[5,[3,"any"]]]],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/feedback(/*any)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFeedback = __jsr.r({"any":{},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedback"],[2,[1,[2,[7,"/"],[5,[3,"any"]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /admin/formats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFormatsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/formats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFormats = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFreshStockAssetUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFreshStockAsset = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFreshStockAssetFileUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFreshStockAssetFile = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFreshStockAssetFilesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFreshStockAssetFiles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFreshStockAssetsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminFreshStockAssets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/global_variables/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminGlobalVariableUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"global_variables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/global_variables/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminGlobalVariable = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"global_variables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedules/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminLeaveDayUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedules/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminLeaveDay = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminLeaveDaysUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedules"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminLeaveDays = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_assets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMedicalAssetUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_assets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_assets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMedicalAsset = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_assets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMedicalReimbursementUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMedicalReimbursement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_reimbursements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMedicalReimbursementsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_reimbursements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminMedicalReimbursements = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminNotificationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminNotification = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminNotificationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminNotifications = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPendingLeaveDayUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPendingLeaveDay = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPendingLeaveDaysUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPendingLeaveDays = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_coupons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPickleCouponUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_coupons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPickleCoupon = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_coupons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPickleCouponsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_coupons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPickleCoupons = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPicklePlansUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_plans"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPicklePlans = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_subscriptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPickleSubscriptionUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_subscriptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminPickleSubscription = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pro_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminProSubscriptionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pro_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pro_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminProSubscriptions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pro_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaReviewsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_reviews"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaReviews = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_reviews"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_specialists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialistUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_specialists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialist = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_specialists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialistsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_specialists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialists = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_specialists/checked_in/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialistsCheckedInUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"checked_in"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_specialists/checked_in/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialistsCheckedIn = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"checked_in"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_specialists/checked_out/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialistsCheckedOutUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"checked_out"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_specialists/checked_out/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialistsCheckedOut = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"checked_out"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_specialists/on_break/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialistsOnBreakUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"on_break"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_specialists/on_break/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQaSpecialistsOnBreak = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"on_break"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQrTeamUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQrTeam = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/:qr_team_id/designer_clusters/:id(.:format)
 * @param {any} qrTeamId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQrTeamDesignerClusterUrl = __jsr.r({"qr_team_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"qr_team_id"],[2,[7,"/"],[2,[6,"designer_clusters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/:qr_team_id/designer_clusters/:id(.:format)
 * @param {any} qrTeamId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQrTeamDesignerCluster = __jsr.r({"qr_team_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"qr_team_id"],[2,[7,"/"],[2,[6,"designer_clusters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/:qr_team_id/designer_clusters(.:format)
 * @param {any} qrTeamId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQrTeamDesignerClustersUrl = __jsr.r({"qr_team_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"qr_team_id"],[2,[7,"/"],[2,[6,"designer_clusters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/:qr_team_id/designer_clusters(.:format)
 * @param {any} qrTeamId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQrTeamDesignerClusters = __jsr.r({"qr_team_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"qr_team_id"],[2,[7,"/"],[2,[6,"designer_clusters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQrTeamsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminQrTeams = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/advanced_analytics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsAdvancedAnalyticUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"advanced_analytics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/advanced_analytics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsAdvancedAnalytic = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"advanced_analytics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/advanced_analytics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsAdvancedAnalyticsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"advanced_analytics"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/advanced_analytics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsAdvancedAnalytics = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"advanced_analytics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/designer_sentiments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsDesignerSentimentsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_sentiments"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/designer_sentiments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsDesignerSentiments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_sentiments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/designer_transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsDesignerTransfersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/designer_transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsDesignerTransfers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/request_abandonments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsRequestAbandonmentsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"request_abandonments"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/request_abandonments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminReportsRequestAbandonments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"request_abandonments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminRequestsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminRequests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/sample_works/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminSampleWorkUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sample_works"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/sample_works/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminSampleWork = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sample_works"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/scale_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminScaleSubscriptionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"scale_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/scale_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminScaleSubscriptions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"scale_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/skill_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminSkillCategoriesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/skill_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminSkillCategories = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/skills/:skill_id/skill_sizes(.:format)
 * @param {any} skillId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminSkillSkillSizesUrl = __jsr.r({"skill_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"skill_id"],[2,[7,"/"],[2,[6,"skill_sizes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/skills/:skill_id/skill_sizes(.:format)
 * @param {any} skillId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminSkillSkillSizes = __jsr.r({"skill_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"skill_id"],[2,[7,"/"],[2,[6,"skill_sizes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/skills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminSkillsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/skills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminSkills = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/stimulus(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminStimulusUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"stimulus"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/stimulus(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminStimulus = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"stimulus"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTagUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTag = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tag_teams/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTagTeamUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tag_teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tag_teams/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTagTeam = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tag_teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tag_teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTagTeamsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tag_teams"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tag_teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTagTeams = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tag_teams"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTagsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTags = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_feedbacks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketFeedbacksUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_feedbacks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketFeedbacks = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketFileUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketFile = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_file_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketFileVideoUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_file_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_file_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketFileVideo = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_file_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketFilesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketFiles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminUser = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/watson_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminWatsonSearchesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"watson_searches"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/watson_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adminWatsonSearches = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"watson_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adobe_stock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adobeStockIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adobe_stock"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /adobe_stock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adobeStockIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adobe_stock"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/ajax_team(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajaxTeamAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"ajax_team"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/ajax_team(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajaxTeamAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"ajax_team"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /annotations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const annotationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"annotations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /annotations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const annotation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"annotations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /annotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const annotationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"annotations"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /annotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const annotations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"annotations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/extensibility/slack/command(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiExtensibilitySlackCommandUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"extensibility"],[2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"command"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/extensibility/slack/command(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiExtensibilitySlackCommand = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"extensibility"],[2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"command"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/extensibility/slack/interactive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiExtensibilitySlackInteractiveUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"extensibility"],[2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"interactive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/extensibility/slack/interactive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiExtensibilitySlackInteractive = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"extensibility"],[2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"interactive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/extensibility/slack/options_load(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiExtensibilitySlackOptionsLoadUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"extensibility"],[2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"options_load"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/extensibility/slack/options_load(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiExtensibilitySlackOptionsLoad = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"extensibility"],[2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"options_load"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/abilities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAbilitiesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"abilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/abilities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAbilities = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"abilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/abilities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAbilityUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"abilities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/abilities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAbility = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"abilities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/auto_assigner_time_zones/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminAutoAssignerTimeZoneUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/auto_assigner_time_zones/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminAutoAssignerTimeZone = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/auto_assigner_time_zones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminAutoAssignerTimeZonesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/auto_assigner_time_zones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminAutoAssignerTimeZones = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/canned_responses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminCannedResponseUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/canned_responses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminCannedResponse = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/canned_responses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminCannedResponsesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/canned_responses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminCannedResponses = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/companies/:company_id/users(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminCompanyUsersUrl = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/companies/:company_id/users(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminCompanyUsers = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/formats/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminFormatUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/formats/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminFormat = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/formats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminFormatsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/formats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminFormats = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/project_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminProjectTemplateUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"project_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/project_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminProjectTemplate = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"project_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/project_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminProjectTemplatesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"project_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/project_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminProjectTemplates = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"project_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/skills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/skills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkill = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/skill_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillCategoriesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/skill_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillCategories = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/skill_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillCategoryUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/skill_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillCategory = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/skill_sizes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillSizeUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_sizes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/skill_sizes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillSize = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_sizes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/skills/:skill_id/skill_sizes(.:format)
 * @param {any} skillId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillSkillSizesUrl = __jsr.r({"skill_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"skill_id"],[2,[7,"/"],[2,[6,"skill_sizes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/skills/:skill_id/skill_sizes(.:format)
 * @param {any} skillId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillSkillSizes = __jsr.r({"skill_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"skill_id"],[2,[7,"/"],[2,[6,"skill_sizes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/skills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkillsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/skills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminSkills = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/companies/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketCompanyUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/companies/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketCompany = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/conversations/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketConversationUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/conversations/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketConversation = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/conversations(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketConversationsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/conversations(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketConversations = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/detail_tasks/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketDetailTaskUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/detail_tasks/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketDetailTask = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/directions(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketDirectionsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"directions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/directions(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketDirections = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"directions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/logs(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketLogsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/logs(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketLogs = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/qa_reviews/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketQaReviewUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"qa_reviews"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/qa_reviews/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketQaReview = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"qa_reviews"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/ticket_files/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketTicketFileUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/ticket_files/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketTicketFile = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/ticket_files(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketTicketFilesUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/ticket_files(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketTicketFiles = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/annotations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAnnotationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"annotations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/annotations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAnnotation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"annotations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/annotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAnnotationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"annotations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/annotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAnnotations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"annotations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/announcements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAnnouncementsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"announcements"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/announcements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalAnnouncements = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"announcements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/apms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalApmsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"apms"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/apms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalApms = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"apms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/billing/downgrade_invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalBillingDowngradeInvoicesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"downgrade_invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/billing/downgrade_invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalBillingDowngradeInvoices = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"downgrade_invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/billing/upcoming_invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalBillingUpcomingInvoicesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"upcoming_invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/billing/upcoming_invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalBillingUpcomingInvoices = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"upcoming_invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/blocklists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalBlocklistUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"blocklists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/blocklists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalBlocklist = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"blocklists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/blocklists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalBlocklistsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"blocklists"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/blocklists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalBlocklists = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"blocklists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/collaborators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCollaboratorsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"collaborators"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/collaborators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCollaborators = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"collaborators"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/collaborators/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCollaboratorsLoginUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/collaborators/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCollaboratorsLogin = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/collaborators/reset_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCollaboratorsResetPasswordUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"reset_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/collaborators/reset_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCollaboratorsResetPassword = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"reset_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/collaborators/update_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCollaboratorsUpdatePasswordUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"update_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/collaborators/update_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCollaboratorsUpdatePassword = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"collaborators"],[2,[7,"/"],[2,[6,"update_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/companies/:company_id/brands(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyBrandsUrl = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"brands"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/companies/:company_id/brands(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyBrands = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"brands"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_daily_available_times/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDailyAvailableTimeUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_daily_available_times"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_daily_available_times/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDailyAvailableTime = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_daily_available_times"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_daily_available_times(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDailyAvailableTimesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_daily_available_times"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_daily_available_times(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDailyAvailableTimes = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_daily_available_times"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_designer_allocations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesignerAllocationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_designer_allocations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesignerAllocation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_designer_allocations/:company_designer_allocation_id/designer_allocations/:id(.:format)
 * @param {any} companyDesignerAllocationId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesignerAllocationDesignerAllocationUrl = __jsr.r({"company_designer_allocation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[2,[7,"/"],[2,[3,"company_designer_allocation_id"],[2,[7,"/"],[2,[6,"designer_allocations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_designer_allocations/:company_designer_allocation_id/designer_allocations/:id(.:format)
 * @param {any} companyDesignerAllocationId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesignerAllocationDesignerAllocation = __jsr.r({"company_designer_allocation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[2,[7,"/"],[2,[3,"company_designer_allocation_id"],[2,[7,"/"],[2,[6,"designer_allocations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_designer_allocations/:company_designer_allocation_id/designer_allocations(.:format)
 * @param {any} companyDesignerAllocationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesignerAllocationDesignerAllocationsUrl = __jsr.r({"company_designer_allocation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[2,[7,"/"],[2,[3,"company_designer_allocation_id"],[2,[7,"/"],[2,[6,"designer_allocations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_designer_allocations/:company_designer_allocation_id/designer_allocations(.:format)
 * @param {any} companyDesignerAllocationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesignerAllocationDesignerAllocations = __jsr.r({"company_designer_allocation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[2,[7,"/"],[2,[3,"company_designer_allocation_id"],[2,[7,"/"],[2,[6,"designer_allocations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_designer_allocations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesignerAllocationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_designer_allocations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesignerAllocations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designer_allocations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_designers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesignersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designers"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_designers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyDesigners = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_designers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyStatusIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyStatusIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyTagUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyTag = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_taggings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyTaggingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_taggings"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_taggings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyTaggings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_taggings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyTagsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalCompanyTags = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/video_annotations/:id/:comment_uuid(.:format)
 * @param {any} id
 * @param {any} commentUuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDeleteVideoAnnotationCommentUrl = __jsr.r({"id":{"r":true},"comment_uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"video_annotations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"comment_uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/video_annotations/:id/:comment_uuid(.:format)
 * @param {any} id
 * @param {any} commentUuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDeleteVideoAnnotationComment = __jsr.r({"id":{"r":true},"comment_uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"video_annotations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"comment_uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/designers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDesignersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"designers"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/designers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDesigners = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"designers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/detail_tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDetailTaskUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/detail_tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDetailTask = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/domains/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDomainUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/domains/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDomain = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/domains(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDomainsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/domains(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDomains = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/dpus/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDpuUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"dpus"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/dpus/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDpu = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"dpus"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/dpus(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDpusUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"dpus"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/dpus(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalDpus = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"dpus"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/features(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalFeaturesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"features"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/features(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalFeatures = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"features"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/gen_ai/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalGenAiUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"gen_ai"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/gen_ai/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalGenAi = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"gen_ai"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/gen_ai/requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalGenAiRequestUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"gen_ai"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/gen_ai/requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalGenAiRequest = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"gen_ai"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/gen_ai/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalGenAiRequestsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"gen_ai"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/gen_ai/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalGenAiRequests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"gen_ai"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/integration_keys/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalIntegrationKeyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"integration_keys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/integration_keys/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalIntegrationKey = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"integration_keys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/integration_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalIntegrationKeysUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"integration_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/integration_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalIntegrationKeys = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"integration_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/mark_archived(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalMarkArchivedUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"mark_archived"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/mark_archived(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalMarkArchived = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"mark_archived"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/production_coordinators/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalProductionCoordinatorsTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"production_coordinators"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/production_coordinators/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalProductionCoordinatorsTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"production_coordinators"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalProjectUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalProject = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/project_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalProjectTemplatesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"project_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/project_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalProjectTemplates = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"project_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalProjectsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalProjects = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/qa_review_states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalQaReviewStatesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"qa_review_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/qa_review_states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalQaReviewStates = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"qa_review_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/qa_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalQaReviewsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"qa_reviews"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/qa_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalQaReviews = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"qa_reviews"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/qa_specialists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalQaSpecialistsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"qa_specialists"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/qa_specialists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalQaSpecialists = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"qa_specialists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/refund_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalRefundStatusIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"refund_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/refund_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalRefundStatusIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"refund_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/report/cancellations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportCancellationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/report/cancellations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportCancellation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/report/cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/report/cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/report/cancellations/fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportCancellationsFieldsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/report/cancellations/fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportCancellationsFields = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/report/cancellations/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportCancellationsNotesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/report/cancellations/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportCancellationsNotes = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/report/designer_transfers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportDesignerTransferUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"designer_transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/report/designer_transfers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportDesignerTransfer = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"designer_transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/report/designer_transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportDesignerTransfersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"designer_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/report/designer_transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportDesignerTransfers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"designer_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/report/designer_transfers/fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportDesignerTransfersFieldsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"designer_transfers"],[2,[7,"/"],[2,[6,"fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/report/designer_transfers/fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalReportDesignerTransfersFields = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"designer_transfers"],[2,[7,"/"],[2,[6,"fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalRequestUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalRequest = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/request_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalRequestFilesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"request_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/request_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalRequestFiles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"request_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalRequestsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalRequests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/saved_searches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSavedSearchUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"saved_searches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/saved_searches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSavedSearch = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"saved_searches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/saved_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSavedSearchesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"saved_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/saved_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSavedSearches = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"saved_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSearchIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSearchIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/skills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSkillUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/skills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSkill = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/skills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSkillsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"skills"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/skills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalSkills = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"skills"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTeamsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTeams = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/collab_invitations(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketCollabInvitationsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"collab_invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/collab_invitations(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketCollabInvitations = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"collab_invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/conversations/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketConversationUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/conversations/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketConversation = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/conversations(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketConversationsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/conversations(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketConversations = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/designers(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketDesignersUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"designers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/designers(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketDesigners = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"designers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/detail_tasks(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketDetailTasksUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"detail_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/detail_tasks(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketDetailTasks = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"detail_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/directions(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketDirectionsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"directions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/directions(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketDirections = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"directions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/ticket_feedbacks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFeedbackUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/ticket_feedbacks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFeedback = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/ticket_feedbacks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFeedbacksUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/ticket_feedbacks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFeedbacks = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/ticket_file_versions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFileVersionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_file_versions"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/ticket_file_versions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFileVersions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_file_versions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/ticket_file_versions/filter_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFileVersionsFilterOptionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_file_versions"],[2,[7,"/"],[2,[6,"filter_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/ticket_file_versions/filter_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFileVersionsFilterOptions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_file_versions"],[2,[7,"/"],[2,[6,"filter_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/ticket_file_versions/new_tickets_subjects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFileVersionsNewTicketsSubjectsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_file_versions"],[2,[7,"/"],[2,[6,"new_tickets_subjects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/ticket_file_versions/new_tickets_subjects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFileVersionsNewTicketsSubjects = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_file_versions"],[2,[7,"/"],[2,[6,"new_tickets_subjects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/ticket_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFilesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/ticket_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketFiles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/share/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketShareUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/share/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketShare = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/stock_assets(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketStockAssetsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"stock_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/stock_assets(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketStockAssets = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"stock_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/stop-watching(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketStopWatchingUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"stop-watching"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/stop-watching(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketStopWatching = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"stop-watching"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/submissions(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketSubmissionsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"submissions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/submissions(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketSubmissions = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"submissions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/submissions/feedback(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketSubmissionsFeedbackUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"submissions"],[2,[7,"/"],[2,[6,"feedback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/submissions/feedback(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketSubmissionsFeedback = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"submissions"],[2,[7,"/"],[2,[6,"feedback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/ticket_files/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketTicketFileUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/ticket_files/:id(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketTicketFile = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/ticket_files(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketTicketFilesUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/ticket_files(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketTicketFiles = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/watch(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketWatchUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"watch"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/watch(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketWatch = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"watch"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/watching(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketWatchingUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"watching"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/watching(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketWatching = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"watching"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketsTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketsTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketsTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalTicketsTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/video_annotation_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalUpdateVideoAnnotationDetailsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"video_annotation_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/video_annotation_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalUpdateVideoAnnotationDetails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"video_annotation_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/video_annotations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalVideoAnnotationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"video_annotations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/video_annotations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalVideoAnnotation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"video_annotations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/video_annotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalVideoAnnotationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"video_annotations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/video_annotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiInternalVideoAnnotations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"video_annotations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/onboarding/account_validations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingAccountValidationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"account_validations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/onboarding/account_validations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingAccountValidations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"account_validations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/onboarding/design_needs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingDesignNeedsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"design_needs"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/onboarding/design_needs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingDesignNeeds = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"design_needs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/onboarding/designer_timezones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingDesignerTimezonesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"designer_timezones"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/onboarding/designer_timezones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingDesignerTimezones = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"designer_timezones"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/onboarding/pickle_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingPicklePlansUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"pickle_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/onboarding/pickle_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingPicklePlans = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"pickle_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/onboarding/signups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingSignupsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"signups"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/onboarding/signups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiOnboardingSignups = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"signups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/smiirl/counters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiSmiirlCountersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"smiirl"],[2,[7,"/"],[2,[6,"counters"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/smiirl/counters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiSmiirlCounters = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"smiirl"],[2,[7,"/"],[2,[6,"counters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2BrandsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"brands"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2Brands = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"brands"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/integration_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2IntegrationKeysUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"integration_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/integration_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2IntegrationKeys = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"integration_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/microsoft/teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2MicrosoftTeamsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"microsoft"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/microsoft/teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2MicrosoftTeams = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"microsoft"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/microsoft/teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2MicrosoftTeamsIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"microsoft"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/microsoft/teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2MicrosoftTeamsIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"microsoft"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/microsoft/teams/notification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2MicrosoftTeamsNotificationUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"microsoft"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[6,"notification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/microsoft/teams/notification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2MicrosoftTeamsNotification = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"microsoft"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[6,"notification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2RequestUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2Request = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/request_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2RequestTypeUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"request_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/request_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2RequestType = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"request_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/request_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2RequestTypesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"request_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/request_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2RequestTypes = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"request_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2RequestsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV2Requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/approve1(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve1AdminMedicalReimbursementUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve1"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/approve1(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve1AdminMedicalReimbursement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve1"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/approve2(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve2AdminMedicalReimbursementUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve2"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/approve2(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve2AdminMedicalReimbursement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve2"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /appsumo-freshstock-annual(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const appsumoFreshstockAnnualUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"appsumo-freshstock-annual"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /appsumo-freshstock-annual(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const appsumoFreshstockAnnual = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"appsumo-freshstock-annual"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archiveApiInternalTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archiveApiInternalTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/directions/are_all_completed(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const areAllCompletedApiInternalAdminTicketDirectionsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"directions"],[2,[7,"/"],[2,[6,"are_all_completed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/directions/are_all_completed(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const areAllCompletedApiInternalAdminTicketDirections = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"directions"],[2,[7,"/"],[2,[6,"are_all_completed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/assigned_to_team(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assignedToTeamAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"assigned_to_team"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/assigned_to_team(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assignedToTeamAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"assigned_to_team"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers/attendance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attendanceAdminDesignersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"attendance"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers/attendance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attendanceAdminDesigners = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"attendance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /attention(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attentionUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"attention"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /attention(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attention = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"attention"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/auto_assign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autoAssignAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"auto_assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/auto_assign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autoAssignAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"auto_assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/back_to_admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backToAdminUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"back_to_admin"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/back_to_admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backToAdminUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"back_to_admin"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/bank_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bankAccountBillingUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"bank_account"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /billing/bank_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bankAccountBilling = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"bank_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billingUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /billing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /box_oauth2_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const boxOauth2CallbackUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"box_oauth2_callback"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /box_oauth2_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const boxOauth2Callback = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"box_oauth2_callback"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /brands/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brands/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brand = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /brand_assets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandAssetUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brand_assets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brand_assets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandAsset = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brand_assets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /brand_assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandAssetsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brand_assets"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /brand_assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandAssets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brand_assets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /brand_colors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandColorUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brand_colors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brand_colors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandColor = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brand_colors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /brand/duplicate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandDuplicateUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brand"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brand/duplicate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandDuplicate = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brand"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brandsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brands"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /brands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brands = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brands"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /brightback/cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brightbackCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brightback"],[2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brightback/cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brightbackCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brightback"],[2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /brightback/coupons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brightbackCouponsUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brightback"],[2,[7,"/"],[2,[6,"coupons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /brightback/coupons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brightbackCoupons = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brightback"],[2,[7,"/"],[2,[6,"coupons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /brightback/on-hold(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brightbackOnHoldUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brightback"],[2,[7,"/"],[2,[6,"on-hold"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brightback/on-hold(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brightbackOnHold = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brightback"],[2,[7,"/"],[2,[6,"on-hold"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /brightback/webhooks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brightbackWebhooksUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brightback"],[2,[7,"/"],[2,[6,"webhooks"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brightback/webhooks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const brightbackWebhooks = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brightback"],[2,[7,"/"],[2,[6,"webhooks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/detail_tasks/bulk_create(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkCreateApiInternalTicketDetailTasksUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/detail_tasks/bulk_create(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkCreateApiInternalTicketDetailTasks = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/ticket_files/bulk_create(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkCreateApiInternalTicketTicketFilesUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:ticket_id/ticket_files/bulk_create(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkCreateApiInternalTicketTicketFiles = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkCreateApiInternalTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkCreateApiInternalTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/bulk_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkUpdateAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/bulk_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkUpdateAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/calendar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const calendarAdminPendingLeaveDaysUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"calendar"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/calendar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const calendarAdminPendingLeaveDays = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"calendar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelApiInternalTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelApiInternalTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/tickets/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelApiInternalTicketsTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/tickets/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelApiInternalTicketsTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/requests/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelApiV2RequestUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/requests/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelApiV2Request = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelUserRegistrationUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelUserRegistration = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/cancellation_request(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancellationRequestAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"cancellation_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/cancellation_request(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancellationRequestAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"cancellation_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /settings/card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardSettingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"card"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /settings/card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardSettings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"card"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/change_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const changePasswordUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"change_password"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/change_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const changePasswordUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"change_password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:ticket_id/components/child_navigation(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const childNavigationTicketComponentsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"components"],[2,[7,"/"],[2,[6,"child_navigation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:ticket_id/components/child_navigation(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const childNavigationTicketComponents = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"components"],[2,[7,"/"],[2,[6,"child_navigation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/client_reply(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clientReplyAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"client_reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/client_reply(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clientReplyAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"client_reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tags/cloud(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cloudAdminTagsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"cloud"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tags/cloud(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cloudAdminTags = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"cloud"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const companyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/:company_id/on_hold(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const companyOnHoldUrl = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"on_hold"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /companies/:company_id/on_hold(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const companyOnHold = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"on_hold"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completeApiInternalTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completeApiInternalTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/requests/:id/complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completeApiV2RequestUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/requests/:id/complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completeApiV2Request = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/confirmation_instructions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmationInstructionsAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"confirmation_instructions"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/confirmation_instructions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmationInstructionsAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"confirmation_instructions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /conversation_images(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conversationImagesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conversation_images"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /conversation_images(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conversationImages = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conversation_images"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/conversation_mention(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conversationMentionAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"conversation_mention"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/conversation_mention(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conversationMentionAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"conversation_mention"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/company_tags/:id/count(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const countApiInternalCompanyTagUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/company_tags/:id/count(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const countApiInternalCompanyTag = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"company_tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/create_conversation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createConversationAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_conversation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/create_conversation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createConversationAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_conversation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/create_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createUserUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"create_user"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/create_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const createUserUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"create_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/customer_clarification(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerClarificationApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"customer_clarification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/customer_clarification(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customerClarificationApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"customer_clarification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/:id/dashboard_panel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboardPanelAdminQrTeamUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"dashboard_panel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/:id/dashboard_panel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboardPanelAdminQrTeam = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"dashboard_panel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id/delete_assets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deleteAssetsFreshStockCollectionUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id/delete_assets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deleteAssetsFreshStockCollection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_files/delete_current_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deleteCurrentVersionAdminTicketFilesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"delete_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_files/delete_current_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deleteCurrentVersionAdminTicketFiles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"delete_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/delete_current_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deleteCurrentVersionApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/delete_current_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deleteCurrentVersionApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/deny(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const denyAdminMedicalReimbursementUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deny"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/deny(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const denyAdminMedicalReimbursement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deny"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers/:id/designer_dpus(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const designerDpusAdminDesignerUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"designer_dpus"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers/:id/designer_dpus(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const designerDpusAdminDesigner = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"designer_dpus"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/designer_overweight(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const designerOverweightAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"designer_overweight"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/designer_overweight(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const designerOverweightAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"designer_overweight"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/designer_underweight(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const designerUnderweightAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"designer_underweight"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/designer_underweight(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const designerUnderweightAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"designer_underweight"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/:qr_team_id/designer_clusters/:id/designers(.:format)
 * @param {any} qrTeamId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const designersAdminQrTeamDesignerClusterUrl = __jsr.r({"qr_team_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"qr_team_id"],[2,[7,"/"],[2,[6,"designer_clusters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"designers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/:qr_team_id/designer_clusters/:id/designers(.:format)
 * @param {any} qrTeamId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const designersAdminQrTeamDesignerCluster = __jsr.r({"qr_team_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"qr_team_id"],[2,[7,"/"],[2,[6,"designer_clusters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"designers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_files/destroy_all_current_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroyAllCurrentVersionAdminTicketFilesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"destroy_all_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_files/destroy_all_current_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroyAllCurrentVersionAdminTicketFiles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"destroy_all_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/:id/destroy_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroyImageAdminPendingLeaveDayUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/:id/destroy_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroyImageAdminPendingLeaveDay = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroyUserSessionUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroyUserSession = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_files/download_all_current_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadAllCurrentVersionAdminTicketFilesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"download_all_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_files/download_all_current_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadAllCurrentVersionAdminTicketFiles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"download_all_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadApiInternalTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadApiInternalTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/brands/:id/download_assets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadAssetsAdminBrandUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/brands/:id/download_assets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadAssetsAdminBrand = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/download_limit_reached(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadLimitReachedAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"download_limit_reached"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/download_limit_reached(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadLimitReachedAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"download_limit_reached"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /photos/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadPhotoUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /photos/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadPhoto = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock/:id/download_selected(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadSelectedFreshStockUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_selected"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock/:id/download_selected(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadSelectedFreshStock = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_selected"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/projects/:id/duplicate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicateApiInternalProjectUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/projects/:id/duplicate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicateApiInternalProject = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/duplicate_email_check(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicateEmailCheckRequestsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"duplicate_email_check"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/duplicate_email_check(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicateEmailCheckRequests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"duplicate_email_check"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /address/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAddressUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"address"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /address/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAddress = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"address"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/admins/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminAdminUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admins/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminAdmin = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/announcements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminAnnouncementUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/announcements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminAnnouncement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/apms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminApmUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"apms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/apms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminApm = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"apms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_assigner_time_zones/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminAutoAssignerTimeZoneUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/auto_assigner_time_zones/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminAutoAssignerTimeZone = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/canned_responses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminCannedResponseUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/canned_responses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminCannedResponse = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/canned_response_folders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminCannedResponseFolderUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_response_folders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/canned_response_folders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminCannedResponseFolder = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_response_folders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/conversations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminConversationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/conversations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminConversation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminDesignerUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminDesigner = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/formats/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminFormatUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/formats/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminFormat = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminFreshStockAssetUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminFreshStockAsset = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminFreshStockAssetFileUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminFreshStockAssetFile = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminMedicalReimbursementUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminMedicalReimbursement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminPendingLeaveDayUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminPendingLeaveDay = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_coupons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminPickleCouponUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_coupons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminPickleCoupon = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_specialists/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminQaSpecialistUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_specialists/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminQaSpecialist = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminQrTeamUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminQrTeam = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/skills/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminSkillUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/skills/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminSkill = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/skill_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminSkillCategoryUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/skill_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminSkillCategory = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/skills/:skill_id/skill_sizes/:id/edit(.:format)
 * @param {any} skillId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminSkillSkillSizeUrl = __jsr.r({"skill_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"skill_id"],[2,[7,"/"],[2,[6,"skill_sizes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/skills/:skill_id/skill_sizes/:id/edit(.:format)
 * @param {any} skillId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminSkillSkillSize = __jsr.r({"skill_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"skill_id"],[2,[7,"/"],[2,[6,"skill_sizes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tags/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminTagUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tags/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminTag = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tag_teams/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminTagTeamUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tag_teams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tag_teams/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminTagTeam = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tag_teams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editAdminUser = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/skills/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editApiInternalAdminSkillUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/skills/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editApiInternalAdminSkill = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editBillingUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /billing/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editBilling = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /brands/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editBrandUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /brands/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editBrand = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /brand_assets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editBrandAssetUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brand_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /brand_assets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editBrandAsset = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"brand_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock/:id/edit_collection_assets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editCollectionAssetsFreshStockUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_collection_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock/:id/edit_collection_assets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editCollectionAssetsFreshStock = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_collection_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editFreshStockCollectionUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editFreshStockCollection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/brands/:id/edit_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editNotesAdminBrandUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/brands/:id/edit_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editNotesAdminBrand = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/edit_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editNotesAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/edit_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editNotesAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editNotesAdminUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/:id/edit_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editNotesAdminUser = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pickle_subscriptions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editPickleSubscriptionUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pickle_subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pickle_subscriptions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editPickleSubscription = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pickle_subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plan/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editPlanUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plan"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /plan/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editPlan = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plan"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /onboarding/powerplan/:hc/edit(.:format)
 * @param {any} hc
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editPowerPlanPreferencesUrl = __jsr.r({"hc":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"powerplan"],[2,[7,"/"],[2,[3,"hc"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /onboarding/powerplan/:hc/edit(.:format)
 * @param {any} hc
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editPowerPlanPreferences = __jsr.r({"hc":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"powerplan"],[2,[7,"/"],[2,[3,"hc"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editRailsConductorInboundEmailUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editRailsConductorInboundEmail = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cancellations/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/cancellations/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminCompaniesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminCompanies = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/conversations/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminConversationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/conversations/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminConversations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designer_trackings/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminDesignerTrackingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designer_trackings"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designer_trackings/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminDesignerTrackings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designer_trackings"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminDesignersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminDesigners = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminFreshStockAssetsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminFreshStockAssets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminPendingLeaveDaysUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminPendingLeaveDays = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_coupons/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminPickleCouponsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_coupons/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminPickleCoupons = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_plans/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminPicklePlansUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_plans"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_plans/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminPicklePlans = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_plans"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_specialists/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminQaSpecialistsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_specialists/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminQaSpecialists = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminQrTeamsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminQrTeams = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/cancellations/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminReportsCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/cancellations/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminReportsCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/designer_sentiments/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminReportsDesignerSentimentsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_sentiments"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/designer_sentiments/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminReportsDesignerSentiments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_sentiments"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/request_abandonments/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminReportsRequestAbandonmentsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"request_abandonments"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/request_abandonments/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminReportsRequestAbandonments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"request_abandonments"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/requests/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminRequestsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/requests/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminRequests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_feedbacks/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminTicketFeedbacksUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_feedbacks/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminTicketFeedbacks = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/edit_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSegmentAdminUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /slack/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSlackUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /slack/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSlack = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/edit_subject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSubjectAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_subject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/edit_subject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editSubjectAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_subject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUser = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock/:id/edit_user_flagging(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUserFlaggingFreshStockUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_user_flagging"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock/:id/edit_user_flagging(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUserFlaggingFreshStock = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_user_flagging"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUserPasswordUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUserPassword = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUserRegistrationUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUserRegistration = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /extensibility/slack/oauth_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const extensibilitySlackOauthCallbackUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"extensibility"],[2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"oauth_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /extensibility/slack/oauth_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const extensibilitySlackOauthCallback = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"extensibility"],[2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"oauth_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/qa_reviews/:id/fail(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const failApiInternalAdminTicketQaReviewUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"qa_reviews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/qa_reviews/:id/fail(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const failApiInternalAdminTicketQaReview = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"qa_reviews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:ticket_id/components/files(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filesTicketComponentsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"components"],[2,[7,"/"],[2,[6,"files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:ticket_id/components/files(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filesTicketComponents = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"components"],[2,[7,"/"],[2,[6,"files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /onboarding/power_plans/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finishPowerPlanSignupUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"power_plans"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /onboarding/power_plans/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finishPowerPlanSignup = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"power_plans"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStock = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockCollectionUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockCollection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock_collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockCollectionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock_collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockCollections = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/fresh_stock_downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockDownloadsCompaniesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"fresh_stock_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /companies/fresh_stock_downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockDownloadsCompanies = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"fresh_stock_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /fresh_stock_photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockPhotosUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_photos"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock_photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freshStockPhotos = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_photos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/watson/hubspot(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hubspotApiWatsonIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"watson"],[2,[7,"/"],[2,[6,"hubspot"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/watson/hubspot(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hubspotApiWatsonIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"watson"],[2,[7,"/"],[2,[6,"hubspot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /inbox(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inboxUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inbox"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /inbox(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inbox = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inbox"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/conversations/:id/intercom(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const intercomAdminConversationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"intercom"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/conversations/:id/intercom(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const intercomAdminConversation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"intercom"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/intercom(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const intercomAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"intercom"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/intercom(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const intercomAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"intercom"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/watson/internship(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internshipAdminWatsonIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"watson"],[2,[7,"/"],[2,[6,"internship"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/watson/internship(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internshipAdminWatsonIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"watson"],[2,[7,"/"],[2,[6,"internship"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoiceUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoicesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoices = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /billing/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoicesBillingUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /billing/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoicesBilling = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/job_function_to_role(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const jobFunctionToRoleUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"job_function_to_role"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/job_function_to_role(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const jobFunctionToRoleUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"job_function_to_role"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/leave_approved(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leaveApprovedAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"leave_approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/leave_approved(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leaveApprovedAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"leave_approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/leave_days_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leaveDaysStatsAdminPendingLeaveDaysUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"leave_days_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/leave_days_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leaveDaysStatsAdminPendingLeaveDays = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"leave_days_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/leave_denied(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leaveDeniedAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"leave_denied"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/leave_denied(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leaveDeniedAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"leave_denied"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /annotations/:asset_type/:asset_id(.:format)
 * @param {any} assetType
 * @param {any} assetId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loadAnnotationsUrl = __jsr.r({"asset_type":{"r":true},"asset_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"annotations"],[2,[7,"/"],[2,[3,"asset_type"],[2,[7,"/"],[2,[3,"asset_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /annotations/:asset_type/:asset_id(.:format)
 * @param {any} assetType
 * @param {any} assetId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loadAnnotations = __jsr.r({"asset_type":{"r":true},"asset_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"annotations"],[2,[7,"/"],[2,[3,"asset_type"],[2,[7,"/"],[2,[3,"asset_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:id/load_archived_files(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loadArchivedFilesTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"load_archived_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:id/load_archived_files(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loadArchivedFilesTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"load_archived_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock_photos/load_collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loadCollectionsFreshStockPhotosUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_photos"],[2,[7,"/"],[2,[6,"load_collections"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock_photos/load_collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loadCollectionsFreshStockPhotos = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_photos"],[2,[7,"/"],[2,[6,"load_collections"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/login_as(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loginAsAdminUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"login_as"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/:id/login_as(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loginAsAdminUser = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"login_as"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notifications/mark_all_read(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const markAllReadAdminNotificationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"mark_all_read"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/notifications/mark_all_read(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const markAllReadAdminNotifications = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"mark_all_read"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:id/mark_archived(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const markArchivedTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_archived"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:id/mark_archived(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const markArchivedTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_archived"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:id/mark_complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const markCompleteTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:id/mark_complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const markCompleteTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cancellations/:id/mark_refunded(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const markRefundedAdminCancellationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_refunded"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/cancellations/:id/mark_refunded(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const markRefundedAdminCancellation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_refunded"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/me(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"me"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/me(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"me"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/medical_reimbursement_approved(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medicalReimbursementApprovedAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"medical_reimbursement_approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/medical_reimbursement_approved(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medicalReimbursementApprovedAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"medical_reimbursement_approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/medical_reimbursement_denied(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medicalReimbursementDeniedAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"medical_reimbursement_denied"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/medical_reimbursement_denied(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medicalReimbursementDeniedAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"medical_reimbursement_denied"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/medical_reimbursement_paid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medicalReimbursementPaidAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"medical_reimbursement_paid"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/medical_reimbursement_paid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medicalReimbursementPaidAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"medical_reimbursement_paid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/medical_reimbursement_pending_approval(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medicalReimbursementPendingApprovalAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"medical_reimbursement_pending_approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/medical_reimbursement_pending_approval(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medicalReimbursementPendingApprovalAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"medical_reimbursement_pending_approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/merge(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mergeAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/merge(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mergeAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/merge(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mergeApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/merge(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mergeApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/merge_search(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mergeSearchAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge_search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/merge_search(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mergeSearchAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge_search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/skills/most_used(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mostUsedApiInternalSkillsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[6,"most_used"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/skills/most_used(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mostUsedApiInternalSkills = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[6,"most_used"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/move_files_to_another_asset(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const moveFilesToAnotherAssetAdminFreshStockAssetUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_files_to_another_asset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/move_files_to_another_asset(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const moveFilesToAnotherAssetAdminFreshStockAsset = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_files_to_another_asset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_files/:id/move_to_current_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const moveToCurrentVersionAdminTicketFileUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_to_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_files/:id/move_to_current_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const moveToCurrentVersionAdminTicketFile = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_to_current_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/move_to_fresh_stock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const moveToFreshStockAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_to_fresh_stock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/move_to_fresh_stock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const moveToFreshStockAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_to_fresh_stock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:id/move_to_top(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const moveToTopTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_to_top"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:id/move_to_top(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const moveToTopTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move_to_top"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admins/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminAdminUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admins/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminAdmin = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/announcements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminAnnouncementUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/announcements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminAnnouncement = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/apms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminApmUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"apms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/apms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminApm = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"apms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_assigner_time_zones/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminAutoAssignerTimeZoneUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/auto_assigner_time_zones/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminAutoAssignerTimeZone = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_assigner_time_zones"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/canned_responses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminCannedResponseUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/canned_responses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminCannedResponse = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_responses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/canned_response_folders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminCannedResponseFolderUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_response_folders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/canned_response_folders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminCannedResponseFolder = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"canned_response_folders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminCompanyUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminCompany = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/conversations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminConversationUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/conversations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminConversation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/formats/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminFormatUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/formats/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminFormat = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"formats"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminFreshStockAssetUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminFreshStockAsset = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminFreshStockAssetFileUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminFreshStockAssetFile = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminMedicalReimbursementUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminMedicalReimbursement = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminPendingLeaveDayUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminPendingLeaveDay = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_coupons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminPickleCouponUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_coupons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminPickleCoupon = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminQrTeamUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminQrTeam = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/skills/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminSkillUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/skills/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminSkill = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/skill_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminSkillCategoryUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/skill_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminSkillCategory = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skill_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/skills/:skill_id/skill_sizes/new(.:format)
 * @param {any} skillId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminSkillSkillSizeUrl = __jsr.r({"skill_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"skill_id"],[2,[7,"/"],[2,[6,"skill_sizes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/skills/:skill_id/skill_sizes/new(.:format)
 * @param {any} skillId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminSkillSkillSize = __jsr.r({"skill_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"skills"],[2,[7,"/"],[2,[3,"skill_id"],[2,[7,"/"],[2,[6,"skill_sizes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tag_teams/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminTagTeamUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tag_teams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tag_teams/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newAdminTagTeam = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tag_teams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /brands/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newBrandUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brands/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newBrand = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /brand_assets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newBrandAssetUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brand_assets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brand_assets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newBrandAsset = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brand_assets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /brand_colors/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newBrandColorUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brand_colors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brand_colors/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newBrandColor = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brand_colors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cancellations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /cancellations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/:company_id/on_hold/new(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newCompanyOnHoldUrl = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"on_hold"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /companies/:company_id/on_hold/new(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newCompanyOnHold = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"on_hold"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /detail_tasks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newDetailTaskUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /detail_tasks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newDetailTask = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newPasswordUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new_password"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/new_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newPasswordUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new_password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pickle_subscriptions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newPickleSubscriptionUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pickle_subscriptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pickle_subscriptions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newPickleSubscription = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pickle_subscriptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newRailsConductorInboundEmailUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newRailsConductorInboundEmail = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newRailsConductorInboundEmailSourceUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newRailsConductorInboundEmailSource = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cancellations/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/cancellations/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminCompaniesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminCompanies = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/conversations/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminConversationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/conversations/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminConversations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designer_trackings/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminDesignerTrackingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designer_trackings"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designer_trackings/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminDesignerTrackings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designer_trackings"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminDesignersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminDesigners = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminFreshStockAssetsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminFreshStockAssets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminPendingLeaveDaysUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminPendingLeaveDays = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_coupons/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminPickleCouponsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_coupons/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminPickleCoupons = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_plans/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminPicklePlansUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_plans"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_plans/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminPicklePlans = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_plans"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_specialists/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminQaSpecialistsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_specialists/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminQaSpecialists = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminQrTeamsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminQrTeams = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/cancellations/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminReportsCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/cancellations/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminReportsCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/designer_sentiments/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminReportsDesignerSentimentsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_sentiments"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/designer_sentiments/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminReportsDesignerSentiments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_sentiments"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/request_abandonments/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminReportsRequestAbandonmentsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"request_abandonments"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/request_abandonments/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminReportsRequestAbandonments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"request_abandonments"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/requests/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminRequestsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/requests/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminRequests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_feedbacks/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminTicketFeedbacksUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_feedbacks/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminTicketFeedbacks = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/new_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSegmentAdminUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subscription/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSubscriptionUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscription"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /subscription/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newSubscription = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscription"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tickets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newTicketUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newTicket = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /past_requests/:ticket_id/conversations/new(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newTicketConversationUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"past_requests"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /past_requests/:ticket_id/conversations/new(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newTicketConversation = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"past_requests"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUser = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserConfirmationUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserConfirmation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserPasswordUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserPassword = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sign_up(/:stripe_plan_id)(/:coupon)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserRegistrationUrl = __jsr.r({"stripe_plan_id":{},"coupon":{},"format":{}}, [2,[7,"/"],[2,[6,"sign_up"],[2,[1,[2,[7,"/"],[3,"stripe_plan_id"]]],[2,[1,[2,[7,"/"],[3,"coupon"]]],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /sign_up(/:stripe_plan_id)(/:coupon)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserRegistration = __jsr.r({"stripe_plan_id":{},"coupon":{},"format":{}}, [2,[7,"/"],[2,[6,"sign_up"],[2,[1,[2,[7,"/"],[3,"stripe_plan_id"]]],[2,[1,[2,[7,"/"],[3,"coupon"]]],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserSessionUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserSession = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new_user"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/new_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newUserUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/new_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newVersionApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"new_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/new_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newVersionApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"new_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /settings/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notificationsSettingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /settings/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notificationsSettings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /onboarding/signin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const onboardingSigninUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"signin"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /onboarding/signin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const onboardingSignin = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"signin"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/overview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const overviewAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/overview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const overviewAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/paid(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paidAdminMedicalReimbursementUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"paid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/:id/paid(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paidAdminMedicalReimbursement = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"paid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/qa_reviews/:id/pass(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passApiInternalAdminTicketQaReviewUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"qa_reviews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pass"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/qa_reviews/:id/pass(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passApiInternalAdminTicketQaReview = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"qa_reviews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pass"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/pause(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pauseAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pause"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/pause(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pauseAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pause"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/pause(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pauseApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pause"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/pause(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pauseApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pause"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pexels_photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pexelsPhotosUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pexels_photos"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /pexels_photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pexelsPhotos = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pexels_photos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /photos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photoUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /photos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photo = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photosUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photos = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pickle_subscriptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pickleSubscriptionUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pickle_subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pickle_subscriptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pickleSubscription = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pickle_subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pickle_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pickleSubscriptionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pickle_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /pickle_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pickleSubscriptions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pickle_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /plan(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const planUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plan"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /plan(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plan"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tickets/:ticket_id/components/player(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const playerTicketComponentsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"components"],[2,[7,"/"],[2,[6,"player"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:ticket_id/components/player(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const playerTicketComponents = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"components"],[2,[7,"/"],[2,[6,"player"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_queues/position(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const positionAdminTicketQueuesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_queues"],[2,[7,"/"],[2,[6,"position"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_queues/position(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const positionAdminTicketQueues = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_queues"],[2,[7,"/"],[2,[6,"position"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /promo_signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const promoSignupUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"promo_signup"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /promo_signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const promoSignup = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"promo_signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/put_on_hold(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const putOnHoldAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"put_on_hold"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/put_on_hold(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const putOnHoldAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"put_on_hold"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/queue(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const queueAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"queue"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/queue(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const queueAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"queue"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signedBlobId
 * @param {any} variationKey
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsBlobRepresentationUrl = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signedBlobId
 * @param {any} variationKey
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsBlobRepresentation = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signedBlobId
 * @param {any} variationKey
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsBlobRepresentationProxyUrl = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signedBlobId
 * @param {any} variationKey
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsBlobRepresentationProxy = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmail = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
 * @param {any} inboundEmailId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailIncinerateUrl = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
 * @param {any} inboundEmailId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailIncinerate = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inboundEmailId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailRerouteUrl = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inboundEmailId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailReroute = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailSourcesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailSources = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsDirectUploadsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsDirectUploads = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encodedKey
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsDiskServiceUrl = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encodedKey
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsDiskService = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMailgunInboundEmailsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMailgunInboundEmails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMandrillInboundEmailsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMandrillInboundEmails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMandrillInboundHealthCheckUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMandrillInboundHealthCheck = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsPostmarkInboundEmailsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsPostmarkInboundEmails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsRelayInboundEmailsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsRelayInboundEmails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsSendgridInboundEmailsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsSendgridInboundEmails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signedId
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsServiceBlobUrl = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signedId
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsServiceBlob = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signedId
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsServiceBlobProxyUrl = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signedId
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsServiceBlobProxy = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/reassociate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reassociateAdminUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reassociate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/:id/reassociate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reassociateAdminUser = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reassociate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /recent_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recentUpdatesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recent_updates"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /recent_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recentUpdates = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recent_updates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/internal/domains/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refreshApiInternalDomainUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/domains/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refreshApiInternalDomain = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/reimbursement_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reimbursementListAdminMedicalReimbursementsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[6,"reimbursement_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/medical_reimbursements/reimbursement_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reimbursementListAdminMedicalReimbursements = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medical_reimbursements"],[2,[7,"/"],[2,[6,"reimbursement_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/rejected_new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rejectedNewAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"rejected_new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/rejected_new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rejectedNewAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"rejected_new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/remove_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeAccountBillingUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"remove_account"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /billing/remove_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeAccountBilling = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"remove_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/remove_company_coupon(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeCompanyCouponAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_company_coupon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/remove_company_coupon(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeCompanyCouponAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_company_coupon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/remove_subscription_coupon(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeSubscriptionCouponAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_subscription_coupon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/remove_subscription_coupon(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeSubscriptionCouponAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_subscription_coupon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/projects/:id/remove_tickets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeTicketsApiInternalProjectUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/projects/:id/remove_tickets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const removeTicketsApiInternalProject = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requestUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const request = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /requests/:request_id/accept_completition(.:format)
 * @param {any} requestId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requestAcceptCompletitionUrl = __jsr.r({"request_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"request_id"],[2,[7,"/"],[2,[6,"accept_completition"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /requests/:request_id/accept_completition(.:format)
 * @param {any} requestId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requestAcceptCompletition = __jsr.r({"request_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"request_id"],[2,[7,"/"],[2,[6,"accept_completition"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /request_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requestTypesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"request_types"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /request_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requestTypes = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"request_types"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/reset_password_instructions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resetPasswordInstructionsAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"reset_password_instructions"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/reset_password_instructions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resetPasswordInstructionsAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"reset_password_instructions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/reset_password(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resetPasswordUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/:id/reset_password(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resetPasswordUser = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/resume(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resumeApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resume"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/resume(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resumeApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resume"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rootUrl = __jsr.r({}, [7,"/"], true);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /brands/samples(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sampleBrandsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[6,"samples"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /brands/samples(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sampleBrands = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[6,"samples"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock/:id/save_user_flagging(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const saveUserFlaggingFreshStockUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"save_user_flagging"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock/:id/save_user_flagging(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const saveUserFlaggingFreshStock = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"save_user_flagging"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/companies/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchApiInternalAdminCompaniesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/companies/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchApiInternalAdminCompanies = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock/:id/select_download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selectDownloadFreshStockUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"select_download"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock/:id/select_download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selectDownloadFreshStock = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"select_download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/self_quality_review_fail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selfQualityReviewFailApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"self_quality_review_fail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/self_quality_review_fail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selfQualityReviewFailApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"self_quality_review_fail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/self_quality_review_pass(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selfQualityReviewPassApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"self_quality_review_pass"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/self_quality_review_pass(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selfQualityReviewPassApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"self_quality_review_pass"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/conversations/:id/send_to_intercom(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sendToIntercomApiInternalAdminTicketConversationUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_intercom"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/conversations/:id/send_to_intercom(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sendToIntercomApiInternalAdminTicketConversation = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_intercom"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers/sentiment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sentimentAdminDesignersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"sentiment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers/sentiment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sentimentAdminDesigners = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"sentiment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/:id/set_as_featured(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setAsFeaturedAdminFreshStockAssetFileUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_as_featured"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/:id/set_as_featured(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setAsFeaturedAdminFreshStockAssetFile = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_as_featured"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_files/:id/set_as_featured(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setAsFeaturedAdminTicketFileUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_as_featured"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_files/:id/set_as_featured(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setAsFeaturedAdminTicketFile = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_as_featured"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/set_default_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setDefaultPaymentBillingUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"set_default_payment"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /billing/set_default_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setDefaultPaymentBilling = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"set_default_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tags/set_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setTagsAdminTagsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"set_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tags/set_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setTagsAdminTags = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"set_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settingsCompaniesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /companies/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settingsCompanies = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shareApiInternalTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/:id/share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shareApiInternalTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /share/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shareTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /share/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shareTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/tickets/share/:ticket_guid(.:format)
 * @param {any} ticketGuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sharedTicketApiInternalTicketsUrl = __jsr.r({"ticket_guid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[3,"ticket_guid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/tickets/share/:ticket_guid(.:format)
 * @param {any} ticketGuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sharedTicketApiInternalTickets = __jsr.r({"ticket_guid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[3,"ticket_guid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/show_fresh_stock_downloads(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const showFreshStockDownloadsAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_fresh_stock_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/show_fresh_stock_downloads(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const showFreshStockDownloadsAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_fresh_stock_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiqWebUrl = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]], true);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiqWeb = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /admin/tickets/skill_changes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const skillChangesAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"skill_changes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/skill_changes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const skillChangesAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"skill_changes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /slack(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slackUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /slack(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slack = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /slack/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slackCallbackUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /slack/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slackCallback = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/:id/soft_delete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const softDeleteAdminPendingLeaveDayUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"soft_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/:id/soft_delete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const softDeleteAdminPendingLeaveDay = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"soft_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/sort(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sortTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"sort"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/sort(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sortTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"sort"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/split(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const splitAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"split"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/split(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const splitAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"split"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/split(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const splitApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"split"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/split(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const splitApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"split"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/start(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const startApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"start"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/start(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const startApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"start"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/qa_reviews/:id/start(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const startApiInternalAdminTicketQaReviewUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"qa_reviews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"start"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/qa_reviews/:id/start(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const startApiInternalAdminTicketQaReview = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"qa_reviews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"start"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/status_pending(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const statusPendingAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"status_pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/status_pending(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const statusPendingAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"status_pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/stop(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stopAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/stop(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stopAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/stop(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stopApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/stop(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stopApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/stop_design(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stopDesignAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop_design"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/stop_design(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stopDesignAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stop_design"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /stripe/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripeWebhookUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stripe"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /stripe/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripeWebhook = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stripe"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /adobe_stock/subscribe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscribeAdobeStockIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adobe_stock"],[2,[7,"/"],[2,[6,"subscribe"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /adobe_stock/subscribe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscribeAdobeStockIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"adobe_stock"],[2,[7,"/"],[2,[6,"subscribe"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock/subscribe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscribeFreshStockIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[6,"subscribe"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock/subscribe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscribeFreshStockIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[6,"subscribe"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscriptionUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscription"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscription = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscription"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscriptionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscriptions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/team(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teamCompaniesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"team"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /companies/team(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teamCompanies = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"team"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/ticket_assigned(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketAssignedAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"ticket_assigned"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/ticket_assigned(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketAssignedAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"ticket_assigned"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /past_requests/:ticket_id/conversations(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketConversationsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"past_requests"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /past_requests/:ticket_id/conversations(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketConversations = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"past_requests"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:ticket_id/duplications(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketDuplicationsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"duplications"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:ticket_id/duplications(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketDuplications = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"duplications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ticket_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketFileUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /ticket_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketFile = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketFileVersionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"assets"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketFileVersions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"assets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ticket_file_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketFileVideoUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ticket_file_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /ticket_file_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketFileVideo = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ticket_file_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ticket_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketFilesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /ticket_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketFiles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ticket_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tickets/:ticket_id/conversations/review_form_rating(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketReviewFormRatingUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"review_form_rating"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:ticket_id/conversations/review_form_rating(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketReviewFormRating = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"review_form_rating"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:ticket_id/conversations/review_form_success(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketReviewFormSuccessUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"review_form_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:ticket_id/conversations/review_form_success(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketReviewFormSuccess = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"review_form_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tickets/:ticket_id/stock_assets(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketStockAssetsUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"stock_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:ticket_id/stock_assets(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketStockAssets = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"stock_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /past_requests/:ticket_id/ticket_feedbacks(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketTicketFeedbacksUrl = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"past_requests"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /past_requests/:ticket_id/ticket_feedbacks(.:format)
 * @param {any} ticketId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketTicketFeedbacks = __jsr.r({"ticket_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"past_requests"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /fresh_stock/:id/toggle_add_to_collection(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleAddToCollectionFreshStockUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_add_to_collection"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock/:id/toggle_add_to_collection(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleAddToCollectionFreshStock = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_add_to_collection"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/:id/toggle_approved(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleApprovedAdminFreshStockAssetFileUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_asset_files/:id/toggle_approved(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleApprovedAdminFreshStockAssetFile = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_asset_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/detail_tasks/:id/toggle_complete(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleCompleteApiInternalAdminTicketDetailTaskUrl = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:ticket_id/detail_tasks/:id/toggle_complete(.:format)
 * @param {any} ticketId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleCompleteApiInternalAdminTicketDetailTask = __jsr.r({"ticket_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"ticket_id"],[2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /detail_tasks/:id/toggle_complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleCompleteDetailTaskUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /detail_tasks/:id/toggle_complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleCompleteDetailTask = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"detail_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/toggle_deleted(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleDeletedAdminFreshStockAssetUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_deleted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/toggle_deleted(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleDeletedAdminFreshStockAsset = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_deleted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id/toggle_deleted(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleDeletedFreshStockCollectionUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_deleted"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /fresh_stock_collections/:id/toggle_deleted(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleDeletedFreshStockCollection = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fresh_stock_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_deleted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cancellations/:id/toggle_preventable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const togglePreventableAdminCancellationUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_preventable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/cancellations/:id/toggle_preventable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const togglePreventableAdminCancellation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_preventable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/toggle_resolved_flagging(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleResolvedFlaggingAdminFreshStockAssetsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"toggle_resolved_flagging"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/toggle_resolved_flagging(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggleResolvedFlaggingAdminFreshStockAssets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"toggle_resolved_flagging"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/transfer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transferAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/transfer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transferAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/transfer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transferApiInternalAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/internal/admin/tickets/:id/transfer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transferApiInternalAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /subscribe/trial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trialSubscriptionsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscribe"],[2,[7,"/"],[2,[6,"trial"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /subscribe/trial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trialSubscriptions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscribe"],[2,[7,"/"],[2,[6,"trial"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/undelete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const undeleteAdminUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"undelete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/:id/undelete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const undeleteAdminUser = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"undelete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/emailer/unpaid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unpaidAdminEmailerIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"unpaid"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/emailer/unpaid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unpaidAdminEmailerIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"emailer"],[2,[7,"/"],[2,[6,"unpaid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/unpause(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unpauseAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unpause"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/unpause(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unpauseAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unpause"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /unsplash_photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsplashPhotosUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unsplash_photos"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /unsplash_photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsplashPhotos = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unsplash_photos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/update_fields(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateFieldsAdminFreshStockAssetUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/:id/update_fields(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateFieldsAdminFreshStockAsset = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/update_from_stripe(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateFromStripeAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_from_stripe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/update_from_stripe(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateFromStripeAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_from_stripe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/brands/:id/update_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateNotesAdminBrandUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/brands/:id/update_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateNotesAdminBrand = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"brands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/update_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateNotesAdminCompanyUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/update_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateNotesAdminCompany = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/update_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateNotesAdminUserUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/:id/update_notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateNotesAdminUser = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /onboarding/powerplan/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updatePowerPlanPreferencesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"powerplan"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /onboarding/powerplan/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updatePowerPlanPreferences = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"powerplan"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers/:id/update_profile_photo(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateProfilePhotoAdminDesignerUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_profile_photo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers/:id/update_profile_photo(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateProfilePhotoAdminDesigner = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_profile_photo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/update_queue_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateQueueStatusAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_queue_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/update_queue_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateQueueStatusAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_queue_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encodedToken
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateRailsDiskServiceUrl = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encodedToken
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateRailsDiskService = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cancellations/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/cancellations/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminCompaniesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminCompanies = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/conversations/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminConversationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/conversations/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminConversations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designer_trackings/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminDesignerTrackingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designer_trackings"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designer_trackings/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminDesignerTrackings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designer_trackings"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/designers/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminDesignersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/designers/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminDesigners = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"designers"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminFreshStockAssetsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminFreshStockAssets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/availability_schedule/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminPendingLeaveDaysUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/availability_schedule/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminPendingLeaveDays = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"availability_schedule"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_coupons/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminPickleCouponsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_coupons/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminPickleCoupons = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_coupons"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pickle_plans/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminPicklePlansUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_plans"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/pickle_plans/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminPicklePlans = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pickle_plans"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qa_specialists/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminQaSpecialistsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qa_specialists/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminQaSpecialists = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qa_specialists"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qr_teams/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminQrTeamsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/qr_teams/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminQrTeams = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qr_teams"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/cancellations/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminReportsCancellationsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/cancellations/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminReportsCancellations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"cancellations"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/designer_sentiments/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminReportsDesignerSentimentsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_sentiments"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/designer_sentiments/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminReportsDesignerSentiments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"designer_sentiments"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reports/request_abandonments/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminReportsRequestAbandonmentsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"request_abandonments"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/reports/request_abandonments/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminReportsRequestAbandonments = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"request_abandonments"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/requests/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminRequestsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/requests/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminRequests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_feedbacks/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminTicketFeedbacksUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_feedbacks/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminTicketFeedbacks = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_feedbacks"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminTicketsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminTickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminUsersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/users/update_segment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSegmentAdminUsers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update_segment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /settings/update_signature(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSignatureSettingsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"update_signature"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /settings/update_signature(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSignatureSettings = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"update_signature"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tickets/:id/update_subject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSubjectAdminTicketUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_subject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/tickets/:id/update_subject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateSubjectAdminTicket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_subject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ticket_files/update_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateVersionAdminTicketFilesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"update_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/ticket_files/update_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateVersionAdminTicketFiles = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ticket_files"],[2,[7,"/"],[2,[6,"update_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plan/upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upgradePlanUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plan"],[2,[7,"/"],[2,[6,"upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /plan/upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upgradePlan = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plan"],[2,[7,"/"],[2,[6,"upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/azure_activedirectory_v2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userAzureActivedirectoryV2OmniauthAuthorizeUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"azure_activedirectory_v2"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/azure_activedirectory_v2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userAzureActivedirectoryV2OmniauthAuthorize = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"azure_activedirectory_v2"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/azure_activedirectory_v2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userAzureActivedirectoryV2OmniauthCallbackUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"azure_activedirectory_v2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/azure_activedirectory_v2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userAzureActivedirectoryV2OmniauthCallback = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"azure_activedirectory_v2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userConfirmationUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userConfirmation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userFacebookOmniauthAuthorizeUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userFacebookOmniauthAuthorize = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/facebook/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userFacebookOmniauthCallbackUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/facebook/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userFacebookOmniauthCallback = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userGoogleOauth2OmniauthAuthorizeUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/google_oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userGoogleOauth2OmniauthAuthorize = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userGoogleOauth2OmniauthCallbackUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userGoogleOauth2OmniauthCallback = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/linkedin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userLinkedinOmniauthAuthorizeUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"linkedin"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/linkedin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userLinkedinOmniauthAuthorize = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"linkedin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/linkedin/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userLinkedinOmniauthCallbackUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"linkedin"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/linkedin/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userLinkedinOmniauthCallback = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"linkedin"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userPasswordUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userPassword = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userRegistrationUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userRegistration = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userSessionUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const userSession = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const usersUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/view_flaggings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const viewFlaggingsAdminFreshStockAssetsUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"view_flaggings"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/fresh_stock_assets/view_flaggings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const viewFlaggingsAdminFreshStockAssets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fresh_stock_assets"],[2,[7,"/"],[2,[6,"view_flaggings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/watson/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhookApiWatsonIndexUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"watson"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/watson/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhookApiWatsonIndex = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"watson"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/gen_ai/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooksGenAiUrl = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"gen_ai"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /webhooks/gen_ai/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooksGenAi = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"gen_ai"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/stripe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooksStripeUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"stripe"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /webhooks/stripe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooksStripe = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"stripe"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/update_ticket(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooksUpdateTicketUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"update_ticket"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /webhooks/update_ticket(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooksUpdateTicket = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"update_ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/white_label(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whiteLabelCompaniesUrl = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"white_label"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /companies/white_label(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whiteLabelCompanies = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"white_label"],[1,[2,[8,"."],[3,"format"]]]]]]]);


