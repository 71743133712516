import { InputHTMLAttributes, forwardRef } from 'react'

const Radio = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(({ ...props }, ref) => {
  return (
    <input
      type={'radio'}
      className="tw-rounded-full tw-border-solid tw-bg-white tw-appearance-none tw-ring-2 tw-ring-offset-2 tw-ring-offset-white tw-ring-transparent tw-h-4 tw-w-4 tw-border-gray-400 tw-text-cornflower-600 tw-background-opacity-0 focus:tw-ring-cornflower-600 tw-m-0 checked:tw-bg-cornflower-600 checked:tw-border-transparent checked:tw-background-opacity-100"
      style={{
        outline: 'none',
        backgroundPosition: 'center',
        backgroundOrigin: 'border-box',
        backgroundSize: '100% 100%',
        backgroundImage:
          "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e\")",
      }}
      ref={ref}
      {...props}
    />
  )
})
Radio.displayName = 'Radio'

export default Radio
