import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from 'components/core/text-input/text-input'
import { Brand } from 'lib/api/companies/companies'
import BrandsFilter from './brands-filter'
import FormatsFilter from './formats-filter'
import SourcesFilter from './sources-filter'
import { emptyFilters, useTicketFileVersionsContext } from './ticket-file-versions-provider'
import { useFilterOptionsContext } from './filter-options-provider'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { useEffect, useState } from 'react'
import { FilterOptions } from 'lib/api/ticket-files/ticket-file-versions'
import SubmittedByFilter from './submitted-by-filter'
import Button from 'components/core/button'
import { hasOnlyEmptyValues } from 'lib/object/utils'
import { SubmittedByUser } from 'lib/api/ticket-files/ticket-file-versions'

export default function TicketFileFilters(): JSX.Element {
  const [options, setOptions] = useState<FilterOptions>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { formats, sources, users } = options || {}
  const { user } = useTicketFileVersionsContext()
  const { getTicketFileVersionList, filters, setFilters } = useTicketFileVersionsContext()
  const { getFilterOptionsValues, isLoading } = useFilterOptionsContext()

  useEffect(() => {
    getOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getOptions() {
    const options = await getFilterOptionsValues()
    setOptions(options)
  }

  function fetchNewList(params = null) {
    getTicketFileVersionList({ page: '1', ...params })
  }

  function onSelectFilters(value: Brand[] | SubmittedByUser[] | string[] | string, name: string) {
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const onSubmit = (e) => {
    try {
      e.preventDefault()
      fetchNewList()
      setSubmitting(true)
    } catch (err) {
      console.error(err)
    } finally {
      setSubmitting(false)
    }
  }

  if (isLoading) return <LoadingScreen size="2x" />

  return (
    <div className="tw-sticky tw-z-10">
      <form onSubmit={onSubmit}>
        <div className="tw-flex tw-justify-start tw-items-center tw-py-2 tw-px-3 tw-bg-peppercorn-50">
          <div className="tw-relative tw-flex tw-items-center tw-mr-2">
            <span className="tw-absolute tw-top-3 tw-left-3">
              <FontAwesomeIcon icon={['fal', 'search']} className="tw-text-neutral-800" />
            </span>
            <TextInput
              name="name"
              placeholder="Search"
              className="tw-w-full tw-px-8 tw-h-12"
              focusClass="focus:tw-ring-0"
              value={filters.name}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="tw-mx-2">
            <FormatsFilter
              formats={formats}
              onSelectFormats={(e) => onSelectFilters(e, 'formats')}
              selectedFormats={filters.formats}
            />
          </div>
          <div className="tw-mx-2">
            <SourcesFilter
              sources={sources}
              onSelectSources={(e) => onSelectFilters(e, 'source')}
              selectedSources={filters.source}
            />
          </div>
          <div className="tw-mx-2">
            <SubmittedByFilter
              users={users}
              selectedUsers={filters.users}
              onSelectUsers={(e) => onSelectFilters(e, 'users')}
            />
          </div>
          <div className="tw-mx-2">
            <BrandsFilter
              companyId={user.companyId}
              onSelectBrand={(e) => onSelectFilters(e, 'brands')}
              selectedBrands={filters.brands}
            />
          </div>
          <Button type="submit" disabled={submitting} color="purple" className="tw-hidden">
            Apply Filters
          </Button>
          {hasOnlyEmptyValues(filters) ? null : (
            <div
              className="tw-text-lg tw-text-peppercorn-400 hover:tw-text-peppercorn-600 tw-cursor-pointer"
              onClick={() => {
                setFilters(emptyFilters)
                fetchNewList({ invalidateCache: true })
              }}
            >
              Clear
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
