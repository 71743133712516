import { useEffect, useState, useCallback } from 'react'
import TextInput from 'lib/components/text-input/text-input'
import { Check, Copy, LucideLoaderCircle, Pencil, RefreshCw, SquareArrowOutUpRight, TriangleAlert } from 'lucide-react'
import { ChangeEvent } from 'react'
import { FormState, UpdateFormState } from './custom-branding'
import { CustomBrandingSettings } from './custom-branding-page'
import { fetchBrand, refreshBrandDns } from 'lib/api/custom-branding/custom-branding'
import Button from 'components/core/button'
import { toast } from 'lib/components/toast/toast'

export const normalizeUrl = (url: string): string => {
  // Remove protocol
  let normalized = url.replace(/^(https?:\/\/)/i, '')

  // Remove trailing slash
  normalized = normalized.replace(/\/$/, '')

  return normalized.toLowerCase()
}

export const isValidDomain = (url: string): boolean => {
  const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.){1,2}[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i
  return domainRegex.test(url)
}

export function CustomBrandingUrlSettings({
  formState,
  updateFormState,
  customBranding,
  customBrandingStatus,
}: {
  formState: FormState
  updateFormState: UpdateFormState
  customBranding: CustomBrandingSettings
  customBrandingStatus: boolean
}) {
  const [isGeneratingCname, setIsGeneratingCname] = useState(!customBranding?.mappedTo && !!customBranding?.url)
  const [mappedTo, setMappedTo] = useState(customBranding?.mappedTo || '')
  const [isInputDisabled, setIsInputDisabled] = useState(!!customBranding?.url)
  const [isEditing, setIsEditing] = useState(false)

  const classNames = {
    tableHeader: 'tw-bg-gray-100 tw-text-gray-700',
    tableCell: 'tw-p-3 tw-border-r tw-border-gray-200 tw-border-solid',
  }

  const pollBrand = useCallback(async () => {
    if (!customBranding?.id) return

    try {
      const response = await fetchBrand(customBranding.id)
      if (response?.data?.mappedTo) {
        setMappedTo(response?.data?.mappedTo)
        setIsGeneratingCname(false)
      }
    } catch (error) {
      console.error('Error fetching brand:', error)
    }
  }, [customBranding?.id])

  useEffect(() => {
    if (isGeneratingCname) {
      const pollInterval = setInterval(pollBrand, 5000) // Poll every 5 seconds
      const timeout = setTimeout(() => {
        clearInterval(pollInterval)
        setIsGeneratingCname(false)
      }, 60 * 1000) // Stop polling after 1 minute

      return () => {
        clearInterval(pollInterval)
        clearTimeout(timeout)
      }
    }
  }, [isGeneratingCname, pollBrand])

  useEffect(() => {
    if ((customBranding?.url || formState.url.value) && !isEditing) {
      setIsInputDisabled(true)
    }
  }, [customBranding?.url, formState.url.value, isEditing])

  useEffect(() => {
    setMappedTo(customBranding?.mappedTo || '')
  }, [customBranding?.mappedTo])

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    const normalizedUrl = normalizeUrl(event.target.value)
    updateFormState('url', normalizedUrl)
    setIsEditing(true)
  }

  const handleUrlBlur = () => {
    const normalizedUrl = formState.url.value
    if (normalizedUrl && !isValidDomain(normalizedUrl)) {
      updateFormState('url', normalizedUrl, 'Please enter a valid domain')
    } else {
      updateFormState('url', normalizedUrl, null) // Clear any previous error
      setIsEditing(false)
    }
  }

  const showRefreshButton =
    customBranding?.url &&
    !customBrandingStatus &&
    Date.now() - new Date(customBranding.createdAt).getTime() > 60 * 60 * 1000 // only show one hour after creation to prevent users spamming the button

  const [isDnsRefreshing, setIsDnsRefreshing] = useState(false)

  const handleRefresh = async () => {
    setIsDnsRefreshing(true)
    try {
      await refreshBrandDns(customBranding.id)
      toast.success('DNS Status Successfully Refreshed - Please Refresh Your Page')
    } catch (e) {
      console.error(e)
      toast.error('DNS Status Refresh Failed')
    }
    setIsDnsRefreshing(false)
  }

  const handleCopy = async () => {
    await navigator.clipboard.writeText(mappedTo)
    toast.success('Value Copied to Clipboard')
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-max-w-3xl">
      <h4>Add your domain</h4>
      <div className="tw-flex tw-items-center tw-flex-row tw-gap-4 tw-justify-start">
        <div className="tw-relative tw-flex-1 tw-max-w-sm">
          <TextInput
            type="text"
            id="url"
            name="url"
            placeholder="review.yourdomain.com"
            value={formState.url.value}
            onChange={handleUrlChange}
            onBlur={handleUrlBlur}
            error={!!formState.url.error}
            className="tw-w-auto tw-mt-0 tw-mb-0 tw-pr-6 tw-pl-14"
            disabled={isInputDisabled}
          />
          <div className="tw-absolute tw-h-full tw-top-0 tw-w-14 tw-flex tw-items-center tw-justify-end tw-text-peppercorn-300 tw-pointer-events-none">
            https://
          </div>
          {isInputDisabled && (
            <div
              className="tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-right-1 tw-h-8 tw-w-8 tw-p-2 tw-flex tw-items-center tw-text-cornflower-500 hover:tw-text-cornflower-800 hover:tw-bg-peppercorn-100 tw-rounded-md tw-cursor-pointer"
              onClick={() => setIsInputDisabled(false)}
            >
              <Pencil className="tw-w-4" />
            </div>
          )}
        </div>
        <div className="tw-text-sm">
          {customBranding?.url && (
            <>
              {customBrandingStatus && customBranding?.mappedTo ? (
                <div className="tw-text-gherkin tw-flex tw-items-center tw-gap-1">
                  <Check className="lu-md" />
                  Connected
                </div>
              ) : (
                <div className="tw-text-sunnyyellow-600 tw-flex tw-items-center tw-gap-1">
                  <TriangleAlert className="lu-md" />
                  Pending Connection (
                  <a
                    href="https://help.designpickle.com/en/articles/9487693-share-review-how-to-update-your-domain-s-dns-settings"
                    target="_blank"
                    rel="noreferrer"
                    className="tw-flex tw-items-center tw-gap-1 tw-font-bold"
                  >
                    How to connect your domain
                    <SquareArrowOutUpRight className="lu-sm" />
                  </a>
                  )
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {formState.url.error && <div className="tw-text-flushpink-500">{formState.url.error}</div>}
      {showRefreshButton && (
        <Button color={'lightGray'} onClick={handleRefresh} className="tw-self-start tw-flex tw-items-center tw-gap-2">
          <RefreshCw className={`lu-sm ${isDnsRefreshing && 'tw-animate-spin'}`} />
          Refresh DNS Status
        </Button>
      )}
      <p className="tw-mb-0">
        You can use a subdomain. E.g. subdomain.yourdomain.com. You will need to point your domain to our CNAME via your
        custom DNS settings.
      </p>
      <table className={`tw-rounded-md tw-border-solid tw-border-gray-200 ${!customBranding?.url && 'tw-opacity-30'}`}>
        <tbody>
          <tr className={classNames.tableHeader}>
            <th className={classNames.tableCell}>Type</th>
            <th className={classNames.tableCell}>Host Name</th>
            <th className={classNames.tableCell}>Value/Points To</th>
          </tr>
          <tr>
            <td className={classNames.tableCell}>CNAME</td>
            <td className={classNames.tableCell}>{customBranding?.url ?? '(Your custom domain)'}</td>
            <td className={classNames.tableCell}>
              {customBranding?.url && (
                <>
                  {mappedTo ? (
                    <div className="tw-cursor-pointer hover:tw-opacity-70" onClick={handleCopy}>
                      <Copy className="lu-sm tw-mr-2" />
                      <span data-testid="mappedToUrl">{mappedTo}</span>
                    </div>
                  ) : isGeneratingCname ? (
                    <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrl">
                      <LucideLoaderCircle className="tw-animate-spin lu-sm tw-mr-1" />
                      Generating - please wait
                    </span>
                  ) : (
                    <span className="tw-italic tw-text-sunnyyellow-600" data-testid="pendingMappedUrlFallback">
                      Refresh the page to check for updates.
                    </span>
                  )}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <p className="tw-mb-0">
        CNAME information might take up to a few minutes to generate. You can refresh the page to check for updates.
      </p>
    </div>
  )
}
