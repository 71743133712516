import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import {
  acceptGenAiRequest,
  cancelGenAiRequest,
  createGenAiRequest,
  createInpaintingRequest,
  dismissGenAiRequest,
  GenAiRequest,
  GenAiRequestStatus,
  GenAiRequestTypes,
  getAllPendingGenAiRequestsForTicket,
  getGenAiRequest,
} from 'lib/api/gen-ai/gen-ai-requests'
import { useRequestContext } from './request-provider'

export interface GenAiContextValue {
  createCreateAssist: (prompt: string, type: GenAiRequestTypes, imageUrl?: string) => Promise<GenAiRequest>
  createEditAssist: (
    prompt: string,
    originalImageUrl: string,
    maskImageUrl: string,
    ticketFileId: number
  ) => Promise<GenAiRequest>
  createRequestAssist: (prompt: string) => Promise<GenAiRequest>
  genAiRequests: GenAiRequest[]
  get?: (genAiRequestId: GenAiRequestId) => Promise<GenAiRequest> // TODO: will be implemented in a future PR
  removeAndAccept: (genAiRequestId: GenAiRequestId) => void
  removeAndCancel: (genAiRequestId: GenAiRequestId) => void
  removeAndDismiss: (genAiRequestId: GenAiRequestId) => void
  removeAndRetry: (genAiRequest: GenAiRequest, openModal?: boolean) => void
  selectedRequestId: GenAiRequestId
  setSelectedRequestId: (id: GenAiRequestId) => void
}

interface GenAiProviderProps {
  children: ReactNode
}

type GenAiRequestId = number

type PollRegistry = Record<GenAiRequestId, boolean>

interface PollerSettings {
  poller_attempt_limit: number
  poller_initial_delay: number
  poller_delay: number
}

const GenAiContext = createContext({})

export function useGenAiContext(): GenAiContextValue {
  return useContext(GenAiContext) as GenAiContextValue
}

export default function GenAiProvider({ children }: GenAiProviderProps): ReactElement {
  const [genAiRequests, setGenAiRequests] = useState<GenAiRequest[]>([])
  const [polls, setPolls] = useState<PollRegistry>({})
  const [selectedRequestId, setSelectedRequestId] = useState<GenAiRequestId>(null)
  const { ticket } = useRequestContext()

  const createCreateAssist = useCallback(
    async (prompt, type, imageUrl) => {
      return await create(ticket.id, type, prompt, imageUrl)
    },
    [ticket?.id]
  )

  const createEditAssist = useCallback(
    (prompt, originalImageUrl, maskImageUrl, ticketFileId) => {
      return inpaint(ticket.id, prompt, originalImageUrl, maskImageUrl, ticketFileId)
    },
    [ticket?.id]
  )

  const createRequestAssist = useCallback(
    async (prompt) => {
      return await create(ticket.id, GenAiRequestTypes.Elaborate, prompt)
    },
    [ticket?.id]
  )

  const pollForStatusChanges = useCallback(
    async (genAiRequestId: GenAiRequestId, requestType: GenAiRequestTypes, times = 0) => {
      const settings = pollerSettings(requestType)

      if (times) {
        const response = await getGenAiRequest(genAiRequestId)
        if (!isPollable(response.genAiRequest.status)) {
          setGenAiRequests(updateRequestCallback(response.genAiRequest))
          removeFromPolls(genAiRequestId)
          return
        }

        if (times > settings.poller_attempt_limit) {
          console.error(
            `GenAI poller request limit of ${settings.poller_attempt_limit} reached for request ${genAiRequestId}`
          )
          setPolls((prevPolls) => {
            delete prevPolls[genAiRequestId]
            return prevPolls
          })
          return
        }
      }
      const delay = times ? settings.poller_delay : settings.poller_initial_delay
      setTimeout(() => pollForStatusChanges(genAiRequestId, requestType, times + 1), delay)
      if (!times) {
        setPolls((prevPolls) => ({ ...prevPolls, [genAiRequestId]: true }))
      }
    },
    []
  )

  const removeAndAccept = useCallback((genAiRequestId) => {
    setGenAiRequests(removeRequestCallback(genAiRequestId))
    acceptGenAiRequest(genAiRequestId).catch(() => {
      alert('There was a problem accepting the AI results. Please try again.')
    })
  }, [])

  const removeAndCancel = useCallback(async (genAiRequestId) => {
    setGenAiRequests(removeRequestCallback(genAiRequestId))
    await cancelGenAiRequest(genAiRequestId)
  }, [])

  const removeAndDismiss = useCallback(async (genAiRequestId) => {
    setGenAiRequests(removeRequestCallback(genAiRequestId))
    await dismissGenAiRequest(genAiRequestId)
  }, [])

  const removeAndRetry = useCallback(
    async (genAiRequest: GenAiRequest) => {
      await removeAndDismiss(genAiRequest.id)
      try {
        if (genAiRequest.requestType === GenAiRequestTypes.Elaborate) {
          await create(ticket.id, genAiRequest.requestType, genAiRequest.message)
        } else if (genAiRequest.requestType === GenAiRequestTypes.Inpainting) {
          await inpaint(
            ticket.id,
            genAiRequest.message,
            genAiRequest.request.original_image,
            genAiRequest.request.mask_image,
            genAiRequest.request.ticket_file_id || null
          )
        } else {
          await create(ticket.id, genAiRequest.requestType, genAiRequest.message, genAiRequest.request.original_image)
        }
      } catch (error) {
        console.error('Create Gen AI Request Error', error)
        alert('There was an error creating your request. Please try again.')
      }
    },
    [removeAndDismiss, ticket.id]
  )

  async function create(ticketId: number, type: GenAiRequestTypes, prompt: string, imageUrl: string = undefined) {
    const response = await createGenAiRequest({
      ticketId: ticketId,
      message: prompt,
      requestType: type,
      imageUrl,
    })

    setGenAiRequests((prevItems) => [...prevItems, response.genAiRequest])
    setSelectedRequestId(response.genAiRequest.id)
    return response.genAiRequest
  }

  async function inpaint(
    ticketId: number,
    prompt: string,
    originalImageUrl: string,
    maskImageUrl: string,
    ticketFileId: number
  ): Promise<GenAiRequest> {
    try {
      const response = await createInpaintingRequest(ticketId, originalImageUrl, maskImageUrl, prompt, ticketFileId)
      setGenAiRequests((prevItems) => [...prevItems, response.genAiRequest])
      setSelectedRequestId(response.genAiRequest.id)
      return response.genAiRequest
    } catch (err) {
      console.error('GenAiProvider - error in createInpaintingRequest:', err)
    }
  }

  function removeFromPolls(genAiRequestId: number) {
    setPolls((prevPolls) => {
      delete prevPolls[genAiRequestId]
      return prevPolls
    })
  }

  const contextValue = {
    createCreateAssist,
    createEditAssist,
    createRequestAssist,
    genAiRequests,
    removeAndAccept,
    removeAndCancel,
    removeAndDismiss,
    removeAndRetry,
    selectedRequestId,
    setSelectedRequestId,
  }

  useEffect(() => {
    if (ticket?.id) {
      getAllPendingGenAiRequestsForTicket(ticket.id)
        .then((response) => {
          if (response.error) {
            throw new Error(response.error as string)
          }
          setGenAiRequests((prevItems) => [...prevItems, ...response.genAiRequests])
        })
        .catch((err) => {
          console.error('GenAI requests could not be fetched:', err)
        })
    }
  }, [ticket?.id])

  useEffect(() => {
    const newRequests = genAiRequests.filter((request) => request.status === GenAiRequestStatus.New)
    newRequests.forEach((request) => {
      if (!Object.hasOwn(polls, request.id)) {
        pollForStatusChanges(request.id as GenAiRequestId, request.requestType).catch(() => null)
      }
    })
  }, [genAiRequests, pollForStatusChanges, polls])

  return <GenAiContext.Provider value={contextValue}>{children}</GenAiContext.Provider>
}

function isPollable(status: GenAiRequestStatus): boolean {
  return ![GenAiRequestStatus.Completed, GenAiRequestStatus.Error, GenAiRequestStatus.Cancelled].includes(status)
}

function pollerSettings(requestType: GenAiRequestTypes): PollerSettings {
  const defaultSettings = { poller_attempt_limit: 120, poller_initial_delay: 5000, poller_delay: 1000 }
  const settingsMap = {
    [GenAiRequestTypes.Elaborate]: { poller_attempt_limit: 120, poller_initial_delay: 5000, poller_delay: 1000 },
    [GenAiRequestTypes.IMG2IMG]: { poller_attempt_limit: 120, poller_initial_delay: 20000, poller_delay: 1000 },
    [GenAiRequestTypes.Inpainting]: { poller_attempt_limit: 120, poller_initial_delay: 20000, poller_delay: 1000 },
    [GenAiRequestTypes.TXT2IMG]: { poller_attempt_limit: 120, poller_initial_delay: 20000, poller_delay: 1000 },
  }

  return settingsMap[requestType] || defaultSettings
}

function removeRequestCallback(genAiRequestId: GenAiRequestId) {
  return (prevItems: GenAiRequest[]) => prevItems.filter((item) => item.id !== genAiRequestId)
}

function updateRequestCallback(updatedItem: GenAiRequest) {
  return (prevItems: GenAiRequest[]) => prevItems.map((item) => (item.id === updatedItem.id ? updatedItem : item))
}
