import { ReactElement, ReactNode } from 'react'

import { CompanyTag } from 'lib/api/company-tags/company-tags'
import Badge from 'lib/components/badge/badge'
import { SearchTicketCompanyTag } from 'interfaces/ticket'

interface CompanyTaggingPopoverTitleProps {
  selectedTags: Array<CompanyTag | SearchTicketCompanyTag>
  emptyTagTitleElement: ReactNode
}

export default function CompanyTaggingPopoverTitle({
  selectedTags,
  emptyTagTitleElement,
}: CompanyTaggingPopoverTitleProps): ReactElement {
  const maxCharacters = 55

  if (selectedTags.length === 0) {
    return <>{emptyTagTitleElement}</>
  }

  const numberOfCharacters = selectedTags.reduce((acc, tag) => acc + tag.name.length, 0)

  if (numberOfCharacters < maxCharacters) {
    return <SelectedTags selectedTags={selectedTags} />
  }

  const truncatedTags = []
  let truncatedTagsLength = 0

  for (const tag of selectedTags) {
    if (tag.name.length + truncatedTagsLength < maxCharacters) {
      truncatedTags.push(tag)
      truncatedTagsLength += tag.name.length
    } else {
      const truncationLength = maxCharacters + 5 - truncatedTagsLength
      truncatedTags.push({ ...tag, name: `${tag.name.slice(0, truncationLength)}...` })
      break
    }
  }

  const numberOfRemainingTags = selectedTags.length - truncatedTags.length
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
      <SelectedTags selectedTags={truncatedTags} />
      {numberOfRemainingTags > 0 && (
        <span className="tw-text-sm tw-text-neutral-500 tw-min-w-16">+ {numberOfRemainingTags} more</span>
      )}
    </div>
  )
}

interface SelectedTagsProps {
  selectedTags: Array<CompanyTag | SearchTicketCompanyTag>
  truncateText?: boolean
}

function SelectedTags({ selectedTags }: SelectedTagsProps) {
  return (
    <div className="tw-flex tw-flex-nowrap tw-gap-2">
      {selectedTags.map((tag) => (
        <Badge color="light" key={tag.id} fontWeight="normal">
          {tag.name}
        </Badge>
      ))}
    </div>
  )
}
