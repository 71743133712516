import { Search, X } from 'lucide-react'
import { cn } from 'lib/util/cn'
import { HTMLAttributes } from 'react'

interface SearchInputProps {
  className?: HTMLAttributes<HTMLDivElement>['className']
  value: string
  onChange: (value: string) => void
  placeholder?: string
}

const SearchInput = ({
  className,
  value,
  onChange,
  placeholder = 'Search by title, directions, annotations, tags, and more',
}: SearchInputProps) => {
  return (
    <div className={cn('tw-flex tw-justify-center tw-relative', className)}>
      <Search className="tw-text-neutral-500 tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-left-3 tw-w-4 tw-h-4 tw-pointer-events-none" />
      <input
        placeholder={placeholder}
        className="tw-block tw-appearance-none tw-rounded-md tw-border tw-border-solid tw-p-2 tw-pl-10 tw-shadow-none tw-outline-none placeholder:tw-text-neutral-500 focus:tw-ring-2 focus:tw-ring-cornflower-500 tw-w-full tw-h-10 tw-pr-8"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {!!value && (
        <div
          onClick={(e) => {
            e.stopPropagation()
            onChange('')
            return
          }}
          className="tw-absolute tw-right-0 tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-10 tw-border tw-border-solid tw-w-8 hover:tw-bg-peppercorn-50 tw-cursor-pointer tw-rounded-r-md"
        >
          <X className="lu-sm tw-inline-block" data-testid="multiselect-clear" />
        </div>
      )}
    </div>
  )
}

export default SearchInput
