import { ReactElement, useMemo } from 'react'
import { Download, Star } from 'lucide-react'

import MultipageNavigation from 'lib/components/multipage/multipage-navigation'
import IconButton from 'lib/components/buttons/icon-button'
import { IconLinkButton } from 'lib/components/buttons/icon-link-button'
import { filestackCdnUrl } from 'lib/util/filestack'

import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { useAdminMediaContext } from '../providers/admin-media-provider'
import AdminDeleteMediaButton from '../delete-media-button/admin-delete-media-button'
import FileMetadata from 'lib/components/file-metadata/file-metadata'
import { toast } from 'lib/components/toast/toast'

const classNames = {
  header: `tw-flex tw-items-center tw-justify-between
      tw-py-6 tw-px-4 tw-break-all tw-border-solid tw-border-0 tw-border-b tw-border-gray-200`,
  ticketFileName: 'tw-neutral-800 tw-flex tw-flex-col tw-gap-2 tw-whitespace-nowrap tw-overflow-hidden',
}

export default function AdminMediaViewerHeader(): ReactElement {
  const { extractedPreviewIndex, selectedFile, setExtractedPreviewIndex } = useAdminMediaContext()

  const downloadUrl = useMemo(() => {
    if (selectedFile.stockAsset?.viewUrl) {
      return selectedFile.stockAsset.viewUrl
    }
    if (selectedFile.handle) {
      return `${filestackCdnUrl}/content=t:attachment/${selectedFile.handle}`
    }
    if (selectedFile.previewUrl && !selectedFile.previewUrl.includes('file_placeholders')) {
      return `${selectedFile.previewUrl}`
    }
  }, [selectedFile.handle, selectedFile.previewUrl, selectedFile.stockAsset?.viewUrl])

  return (
    <header className={classNames.header}>
      <div className={classNames.ticketFileName}>
        <strong className="tw-overflow-ellipsis tw-overflow-hidden">{selectedFile.name}</strong>
        <FileMetadata selectedFile={selectedFile} />
      </div>
      <div className="tw-flex">
        {selectedFile.isExtractable && selectedFile.extractedPages.length !== 0 && (
          <MultipageNavigation
            index={extractedPreviewIndex}
            count={selectedFile.extractedPages.length}
            setIndex={setExtractedPreviewIndex}
          />
        )}
        <FeaturedFile />
        {downloadUrl && (
          <IconLinkButton
            color="transparent"
            url={downloadUrl}
            className="tw-mx-1 tw-p-3"
            download={selectedFile.name}
            dataTestId="download-file"
            target={selectedFile.stockAsset?.viewUrl ? '_blank' : '_self'}
          >
            <Download className="lu-light lu-sm" />
          </IconLinkButton>
        )}
        {/* TODO: Add this back in when we hook this up */}
        {/*{selectedFile.meta?.permissions?.destroy && (*/}
        {/*  <IconLinkButton color="transparent" url="#" className="tw-mx-1 tw-p-3" dataTestId="upload-file">*/}
        {/*    <Upload className="lu-light lu-sm" />*/}
        {/*  </IconLinkButton>*/}
        {/*)}*/}
        <AdminDeleteMediaButton file={selectedFile} icon="trash" />
      </div>
    </header>
  )
}

function StarIcon({ isSelectedFileFeatured }: { isSelectedFileFeatured: boolean }) {
  return (
    <Star
      className={`lu-sm tw-text-sunnyyellow-600 ${isSelectedFileFeatured ? 'lu-solid' : 'lu-light'}`}
      data-testid="feature-file-icon"
    />
  )
}

function FeaturedFile(): ReactElement | null {
  const { selectedFile } = useAdminMediaContext()
  const { ticket, saveAsFeaturedFile } = useAdminTicketContext()

  const ticketPermissions = ticket.meta.permissions
  const isSelectedFileFeatured = ticket.featuredFileId === selectedFile.id
  const canUpdateFeaturedFile =
    selectedFile.ticketVersion === ticket.currentVersion &&
    selectedFile.uploadedByCreative &&
    ticketPermissions.update.featuredFileId

  async function handleSaveAsFeaturedFile() {
    try {
      await saveAsFeaturedFile(isSelectedFileFeatured ? null : selectedFile.id)
    } catch (error) {
      toast.error('There was an error saving the file as featured. Try again later')
      console.error('Error saving file as featured', error)
    }
  }

  if (!ticketPermissions.update.featuredFileId && isSelectedFileFeatured) {
    return (
      <div className="tw-px-4 tw-pt-2">
        <StarIcon isSelectedFileFeatured />
      </div>
    )
  }

  if (!canUpdateFeaturedFile) {
    return null
  }

  return (
    <>
      <IconButton
        color="transparent"
        className="tw-mx-1 tw-p-3"
        dataTestid="feature-file-button"
        onClick={handleSaveAsFeaturedFile}
      >
        <Star className={`lu-sm tw-text-sunnyyellow-600 ${isSelectedFileFeatured ? 'lu-solid' : 'lu-light'}`} />
      </IconButton>
    </>
  )
}
