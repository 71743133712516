import { DesignRequestStatus } from 'lib/api/tickets/tickets'

export function isStatusCompleted(status: string): boolean {
  return status === DesignRequestStatus.completed
}

export function isStatusForReview(status: string): boolean {
  return status === DesignRequestStatus.review
}

export function isStatusOnDeck(status: string): boolean {
  return (
    status === DesignRequestStatus.onDeck ||
    status === DesignRequestStatus.preview ||
    status === DesignRequestStatus.wipOnDeck
  )
}

export function isStatusDraft(status: string): boolean {
  return status === DesignRequestStatus.draft
}

function isStatusArchived(status: string): boolean {
  return status === DesignRequestStatus.archived
}

export function isStatusActive(status: string): boolean {
  return !isStatusCompleted(status) && !isStatusDraft(status) && !isStatusArchived(status) && !isStatusCanceled(status)
}

export function haveFiles(ticketFilesCount: bigint | number): boolean {
  return ticketFilesCount > 0
}

export function isStatusCanceled(status: string): boolean {
  return status === DesignRequestStatus.canceled
}

export function isStatusInProgress(status: string): boolean {
  return status === DesignRequestStatus.inProgress
}

export function canBeRated(status: string): boolean {
  return ['archived', 'closed'].includes(status)
}

export function showDownloadAction(status: string, ticketFilesCount: bigint | number): boolean {
  return isStatusCompleted(status) && haveFiles(ticketFilesCount)
}

export function fileTypeSrc(format: string): string {
  try {
    return require(`/public/images/file_placeholders/${format}.png`)
  } catch (e) {
    console.error('Error getting file type src', e)
    return require('/public/images/file_placeholders/default.png')
  }
}
