import Button from 'components/core/button'
import Modal from 'lib/components/modals/modal'
import TextInput from 'lib/components/text-input/text-input'
import { useState } from 'react'
import { useAdminTicketContext } from '../../providers/admin-ticket-provider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'lib/components/toast/toast'

interface SplitRequestModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const SplitRequestModal = ({ open, setOpen }: SplitRequestModalProps) => {
  const { split } = useAdminTicketContext()
  const [numRequests, setNumRequests] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  const closeModal = () => setOpen(false)
  const splitRequest = async () => {
    setLoading(true)
    try {
      await split(parseInt(numRequests))
      toast.success('Successfully split ticket')
      closeModal()
    } catch (e) {
      console.error('Error splitting ticket', e)
      toast.error('Error splitting ticket')
    }
    setLoading(false)
  }

  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <Modal.Body setOpen={setOpen} closeButton>
        <h4 className="tw-text-center">Split Request</h4>
        <p>
          After splitting, this request will become the <em>Parent Request</em> and be deleted. Parent Requests are only
          for reference and should not be worked on. The new <em>Child Requests</em> will reference this Parent Request
          so you can see the original details.
        </p>
        <p>How many requests would you like to split this request into?</p>
        <TextInput
          placeholder="TOTAL # of requests"
          type="number"
          value={numRequests}
          onChange={(e) => setNumRequests(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-gap-2 tw-mt-8 tw-justify-center">
          <Button type="button" color="outlineDark" onClick={closeModal}>
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            disabled={parseInt(numRequests) < 2 || numRequests === ''}
            onClick={splitRequest}
          >
            {loading ? <FontAwesomeIcon spin icon="spinner-third" /> : 'Split'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default SplitRequestModal
