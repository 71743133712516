import Avatar from 'components/core/avatar'
import Tooltip from 'lib/components/tooltip/tooltip'

interface UserAvatarProps {
  priorityModeEnabled?: boolean
  avatar?: string
  name?: string
  isDesigner?: boolean
}

export default function UserAvatar({ name, avatar, priorityModeEnabled, isDesigner }: UserAvatarProps) {
  return (
    <div className="tw-flex tw-items-center">
      <div className="tw-flex-shrink-0 tw-h-8 tw-w-8">
        <Avatar src={avatar} name={name} />
      </div>
      <div className="tw-ml-4">
        <div className="tw-text-xs tw-font-medium tw-text-gray-700">
          {name ? (
            name
          ) : priorityModeEnabled && isDesigner ? (
            <Tooltip content="Your request is currently waiting to be assigned to a designer. If it was previously assigned, it may have been unassigned due to a change in request priority.">
              To be assigned
            </Tooltip>
          ) : (
            'To be assigned'
          )}
        </div>
      </div>
    </div>
  )
}
