import { ReactElement, useEffect, useRef, useState } from 'react'
import { AppSettings, CurrentUser } from 'interfaces/app'
import { isStatusCanceled } from 'lib/ticket/utils'
import RequestHeader from './request-header/request-header'
import AnnotationsProvider from './providers/annotations-provider'
import TimelineProvider from './providers/timeline-provider'
import GenAiProvider from './providers/gen-ai-provider'
import RequestProvider, { useRequestContext } from './providers/request-provider'
import MediaContainer from './media/media-container'
import { AnimatedLoadingScreen } from '../requests/empty-screens'
import MediaProvider, { useMediaContext } from './media/media-provider'
import RightPanel from './right-panel/right-panel'
import UserProvider from 'providers/user-provider'
import MediaLeftSidebar from './media/media-left-sidebar'
import TicketError from '../../elements/ticket-error'
import { moveIntercom } from 'lib/util/intercom'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'
import AllCompanyTagsProvider from 'providers/all-company-tags-provider'
import { Toaster } from 'lib/components/toast/toast'

interface TicketProps {
  user: CurrentUser
  settings: AppSettings
}

const classNames = {
  container: 'tw-h-screen tw-w-screen tw-grid tw-grid-cols-1 tw-grid-rows-2',
  main: 'tw-flex tw-flex-row tw-overflow-hidden tw-border-0 tw-border-t tw-border-solid tw-border-gray-200',
}

export default function Ticket({ settings, user }: TicketProps): ReactElement {
  useEffect(() => {
    moveIntercom()
  }, [])

  return (
    <>
      <Toaster />
      <UserProvider settings={settings} user={user}>
        <RequestProvider>
          <FeatureFlagsProvider>
            <TicketContentWrapper />
          </FeatureFlagsProvider>
        </RequestProvider>
      </UserProvider>
    </>
  )
}

function TicketContentWrapper() {
  const { fetchAndSetTicket, ticket, ticketError, showRevisions, editMode } = useRequestContext()

  if (!showRevisions && isStatusCanceled(ticket?.status)) {
    window.location.assign(`/past_requests/${ticket.id}/conversations/new`)
    return null
  } else if (ticketError) {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <TicketError />
      </div>
    )
  } else if (!ticket) {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <AnimatedLoadingScreen />
      </div>
    )
  } else {
    return (
      <TimelineProvider>
        <GenAiProvider>
          <AllCompanyTagsProvider>
            <div className={classNames.container} style={{ gridTemplateRows: 'min-content auto' }}>
              <MediaProvider
                fetchAndSetTicket={fetchAndSetTicket}
                ticket={ticket}
                editMode={editMode}
                showRevisions={showRevisions}
              >
                <AnnotationsProvider isCollaboratorView={false}>
                  <RequestHeader />
                  <main className={classNames.main}>
                    <TicketContent />
                  </main>
                </AnnotationsProvider>
              </MediaProvider>
            </div>
          </AllCompanyTagsProvider>
        </GenAiProvider>
      </TimelineProvider>
    )
  }
}

function TicketContent() {
  const { selectedFile } = useMediaContext()
  const [showMainContent, setShowMainContent] = useState<boolean>(false)
  const [showMainOverflow, setShowMainOverflow] = useState<boolean>(false)
  const timeoutRef = useRef<number>(null)

  function close() {
    setShowMainContent(false)
    setShowMainOverflow(false)
  }

  function open() {
    setShowMainContent(true)
    timeoutRef.current = window.setTimeout(() => {
      setShowMainOverflow(true)
    }, 750)
  }

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])

  useEffect(() => {
    if (selectedFile) {
      open()
    } else {
      close()
    }
  }, [selectedFile])

  return (
    <>
      <MediaLeftSidebar showMainContent={showMainContent} openMainContent={open} />
      <MediaContainer show={showMainContent} showOverflow={showMainOverflow} closeMainContent={close} />
      <RightPanel />
    </>
  )
}
