import IconButton, { Colors } from './icon-button'
import Tooltip, { Direction } from '../tooltip/tooltip'
import { useState } from 'react'

interface CopyTextButtonProps {
  textToCopy: string
  tooltipDirection?: Direction
  defaultButtonColor?: Colors
  tooltipCopyText?: string
  tooltipCopiedText?: string
}
export default function CopyTextButton({
  textToCopy,
  tooltipDirection = 'left',
  defaultButtonColor = 'secondary',
  tooltipCopyText = 'Copy to clipboard',
  tooltipCopiedText = 'Copied!',
}: CopyTextButtonProps) {
  const [copyPressed, setCopyPressed] = useState(false)

  async function handleCopyText() {
    await navigator.clipboard.writeText(textToCopy)

    setCopyPressed(true)
  }

  function resetCopyPressed() {
    setCopyPressed(false)
  }

  return (
    <Tooltip
      content={copyPressed ? tooltipCopiedText : tooltipCopyText}
      width={400}
      direction={tooltipDirection}
      leftOffset={-10}
    >
      <IconButton
        color={copyPressed ? 'success' : defaultButtonColor}
        icon={copyPressed ? ['far', 'check'] : ['far', 'clone']}
        onClick={handleCopyText}
        onMouseLeave={resetCopyPressed}
      />
    </Tooltip>
  )
}
