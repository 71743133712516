import { SearchTitleFilter } from 'components/elements/admin-filters'
import SingleSelect from 'components/core/single-select'
import Button from 'components/core/button'
import { QaReviewFiltersParams } from './qa-reviews-page'
import { getQaSpecialists, QaSpecialist } from 'lib/api/qa_specialists/qa_specialists'
import { useEffect, useState } from 'react'
import { hasOnlyEmptyValues } from 'lib/object/utils'
import QaReviewsStatesFilter from './qa-reviews-state-filter'

interface QaReviewsFiltersProps {
  filters: QaReviewFiltersParams
  handleClear: (e) => void
  handleSubmit: (e) => void
  setFilters: (object) => void
}

export default function QaReviewsFilters({
  filters,
  handleClear,
  handleSubmit,
  setFilters,
}: QaReviewsFiltersProps): JSX.Element {
  const [qaSpecialists, setQaSpecialists] = useState<QaSpecialist[]>([])
  const qaSpecialistsOptions = qaSpecialists.map((specialist) => ({ value: specialist.id, label: specialist.name }))
  const selectedQaSpecialist = qaSpecialistsOptions.filter((option) => option.value === filters.qaSpecialistIdEq)[0]
  const classNames = {
    clearButton: `tw-m-2 ${hasOnlyEmptyValues(filters, false) ? 'tw-invisible' : 'tw-visble'}`,
    filtersWrapper: 'tw-mb-4 tw-relative tw-flex tw-justify-between tw-max-w-5xl',
    selectFilter: 'tw-text-xs tw-w-full tw-my-2',
    searchButton: 'tw-m-2',
    separator: 'tw-px-2 tw-pt-2.5 tw-text-xl tw-text-peppercorn-100',
  }

  useEffect(() => {
    fetchQaSpecialists()
  }, [])

  async function fetchQaSpecialists() {
    try {
      const { specialists } = await getQaSpecialists()
      setQaSpecialists(specialists)
    } catch (error) {
      console.error('Error fetching QA specialists', error)
    }
  }

  return (
    <form data-testid="qa-reviews-filters-form" onSubmit={handleSubmit}>
      <div className={classNames.filtersWrapper}>
        <SearchTitleFilter
          value={filters.ticketIdEq}
          updateValue={(value) => setFilters({ ...filters, ticketIdEq: value })}
          placeholder="Ticket ID"
        />
        <span className={classNames.separator}>|</span>
        <QaReviewsStatesFilter filters={filters} setFilters={setFilters} />
        <span className={classNames.separator}>|</span>
        <SingleSelect
          options={qaSpecialistsOptions || []}
          onChange={(e) => setFilters({ ...filters, qaSpecialistIdEq: e?.value })}
          value={selectedQaSpecialist ? selectedQaSpecialist : null}
          className={classNames.selectFilter}
          placeholder="Quality Specialist"
          isClearable
        />
        <span className={classNames.separator}>|</span>
        <Button color="green" type="submit" className={classNames.searchButton}>
          Search
        </Button>
        <Button color="outlineDark" type="button" className={classNames.clearButton} onClick={handleClear}>
          Clear
        </Button>
      </div>
    </form>
  )
}
