import { useRequestContext } from '../providers/request-provider'
import { ReactElement, useEffect, useState } from 'react'
import { DesignRequest } from 'lib/api/tickets/tickets'
import { displayDate } from 'lib/util/date'
import { abbreviatedUnits } from 'lib/string/utils'
import { useUserContext } from 'providers/user-provider'
import CompanyTaggingProvider from 'providers/company-tagging-provider'
import { PencilCompanyTaggingPopover } from 'components/elements/company-tagging/company-tagging-popover'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'

function CustomSizes({ ticket }: { ticket: DesignRequest }): ReactElement {
  if (ticket?.size) {
    const ticketSizes = ticket?.size?.split(';')
    return (
      <>
        {ticketSizes.map((size) => (
          <p key={size}>{size}</p>
        ))}
      </>
    )
  }
  return null
}

const classNames = {
  h6: 'tw-text-neutral-500 tw-text-xs tw-font-regular',
}
export default function RequestDetailsText() {
  const { user } = useUserContext()
  const { ticket, fetchAndSetTicket } = useRequestContext()
  const [selectedTags, setSelectedTags] = useState(ticket.tags)
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { companyTags } = useAllCompanyTagsContext()

  useEffect(() => {
    setSelectedTags((prevSelectedTags) => {
      return prevSelectedTags
        .filter((selectedTag) => companyTags.some((companyTag) => companyTag.id === selectedTag.id))
        .map((selectedTag) => companyTags.find((tag) => tag.id === selectedTag.id))
    })
  }, [companyTags])

  useEffect(() => {
    setSelectedTags(ticket.tags)
  }, [ticket.tags])

  return (
    <div className="tw-text-neutral-800 tw-text-sm tw-font-semibold tw-pt-6">
      <h2 className="tw-text-base tw-m-0">Request Details</h2>
      <div className="tw-pl-4 tw-pt-4">
        <div>
          <h6 className={classNames.h6}>Request Type</h6>
          <p>{ticket?.skill?.name}</p>
        </div>
        <div>
          <h6 className={classNames.h6}>Size(s)*</h6>
          <div>
            {ticket?.selectedSkillSizes?.map((size) => (
              <p key={size.id}>
                {size.name}: {size.details}
                {abbreviatedUnits(size.units)}
              </p>
            ))}
            <CustomSizes ticket={ticket} />
          </div>
        </div>
        <div>
          <h6 className={classNames.h6}>Format(s)*</h6>
          <div>{ticket?.selectedFormats?.join(', ')}</div>
        </div>
        {ticket?.selectedBrand && (
          <div>
            <h6 className={classNames.h6}>Brand Profile</h6>
            <div>{ticket?.selectedBrand.name}</div>
          </div>
        )}
        <div>
          <h6 className={classNames.h6}>Delivered By</h6>
          <div>{ticket?.deliveredBy}</div>
        </div>
        <div>
          <h6 className={classNames.h6}>Submitted By</h6>
          <div>{user?.id === ticket?.submittedBy.id ? 'You' : ticket?.submittedBy.fullName}</div>
        </div>
        {ticket?.submittedAt && (
          <div>
            <h6 className={classNames.h6}>Submitted At</h6>
            <div>{displayDate(ticket?.submittedAt)}</div>
          </div>
        )}
        <div>
          <h6 className={classNames.h6}>Last Updated</h6>
          <div>{displayDate(ticket?.updatedAt)}</div>
        </div>
        {isFeatureFlagEnabled('company_tags') && (
          <div>
            <h6 className={classNames.h6}>Tags</h6>
            <CompanyTaggingProvider
              taggableId={ticket.id}
              taggableType="Ticket"
              selectedTags={selectedTags}
              updateTaggableState={() => fetchAndSetTicket()}
            >
              <PencilCompanyTaggingPopover openDirection="bottom" />
            </CompanyTaggingProvider>
          </div>
        )}
      </div>
    </div>
  )
}
