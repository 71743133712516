import { useState } from 'react'
import { Pencil } from 'lucide-react'

import IconButton from 'lib/components/buttons/icon-button'
import {
  CannedResponse,
  CannedResponseFolder,
  createCannedResponse,
  updateCannedResponse,
} from 'lib/api/admin/canned-responses/canned-responses'
import Modal from 'lib/components/modals/modal'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'
import SelectBox, { SelectBoxOption } from 'lib/components/dropdown/select-box'
import TextInput from 'lib/components/text-input/text-input'

import Button from '../core/button'
import { Toaster, toast } from 'lib/components/toast/toast'

interface EditCannedResponseProps {
  response: CannedResponse
  folders: CannedResponseFolder[]
}
export default function CannedResponseButton({ response, folders }: EditCannedResponseProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleButtonClick = () => {
    setIsModalOpen((prev) => !prev)
  }

  return (
    <div>
      <Toaster />
      {response ? (
        <IconButton color="transparent" onClick={handleButtonClick} dataTestid="pencil-button">
          <Pencil className="lu-sm tw-text-sunnyyellow-600" />
        </IconButton>
      ) : (
        <Button color="green" onClick={handleButtonClick}>
          Add Canned Response
        </Button>
      )}
      <CannedResponseModal setOpen={setIsModalOpen} open={isModalOpen} folders={folders} response={response} />
    </div>
  )
}

interface EditCannedResponseModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  response?: CannedResponse
  folders: CannedResponseFolder[]
}

const CannedResponseModal = ({ open, setOpen, response, folders }: EditCannedResponseModalProps) => {
  const [responseBody, setResponseBody] = useState(response?.body || '')
  const [responseSubject, setResponseSubject] = useState(response?.subject || '')
  const [selectedFolder, setSelectedFolder] = useState(response?.cannedResponseFolderId || folders[0].id)

  const folderSelectOptions: SelectBoxOption[] = folders.map((folder) => ({
    value: folder.id,
    displayElement: <div>{folder.name}</div>,
  }))

  const handleFolderChange = (selectedValue: number) => {
    setSelectedFolder(selectedValue as number)
  }

  const handleCancelClick = () => {
    setOpen(false)
    setResponseSubject(response?.subject || '')
    setResponseBody(response?.body || '')
    setSelectedFolder(response?.cannedResponseFolderId || folders[0].id)
  }

  const updateResponse = async () => {
    try {
      await updateCannedResponse({
        id: response.id,
        body: responseBody,
        subject: responseSubject,
        cannedResponseFolderId: selectedFolder,
      })
      setOpen(false)
      window.location.reload()
    } catch (error) {
      toast.error('Error updating canned response, try again later')
      console.error('Error updating canned response', error)
    }
  }

  const createResponse = async () => {
    try {
      await createCannedResponse({
        body: responseBody,
        subject: responseSubject,
        cannedResponseFolderId: selectedFolder,
      })
      setOpen(false)
      window.location.reload()
    } catch (error) {
      toast.error('Error creating canned response, try again later')
      console.error('Error creating canned response', error)
    }
  }

  const handleSubmit = async () => {
    if (response) {
      await updateResponse()
    } else {
      await createResponse()
    }
  }

  return (
    <Modal setOpen={setOpen} open={open} size="lg">
      <Modal.Header>Edit Canned Response</Modal.Header>
      <Modal.Body setOpen={setOpen}>
        <div className="tw-flex tw-flex-col tw-gap-4 tw-text-neutral-800">
          <div>
            <SelectBox
              options={folderSelectOptions}
              handleChange={handleFolderChange}
              selectedValue={selectedFolder}
              label="Folder"
              labelClassName="tw-text-neutral-800"
            />
          </div>
          <div>
            <TextInput
              id={`canned-response-subject-${response?.id}`}
              label="Subject"
              value={responseSubject}
              onChange={(e) => setResponseSubject(e.target.value)}
            />
          </div>
          <div>
            <div>
              You may use the following expressions to insert ticket-specific information:
              <div>
                <span className="tw-font-semibold">{`{{jar_ticket_id}}`}:</span> Ticket DPP ID
              </div>
              <div>
                <span className="tw-font-semibold">{`{{email}}`}:</span> Ticket user&apos;s email address
              </div>
              <div>
                <span className="tw-font-semibold">{`{{file_url}}`}:</span> Download all current files
              </div>
              <div>
                <span className="tw-font-semibold">{`{{preview_images}}`}:</span> Uploaded file links with thumbnails
              </div>
              <div>
                <span className="tw-font-semibold">{`{{subject}}`}:</span> Ticket subject
              </div>
              <div>
                <span className="tw-font-semibold">{`{{first_name}}`}:</span> Ticket user&apos;s first name
              </div>
              <div>
                <span className="tw-font-semibold">{`{{full_name}}`}:</span> Ticket user&apos;s full name
              </div>
            </div>
          </div>
          <div>
            <label>Body</label>
            <div className="tw-p-4 tw-bg-neutral-50 tw-rounded-md">
              <WYSIWYGTextarea defaultValue={response?.body || ''} onChange={setResponseBody} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-items-center tw-justify-end tw-w-full tw-gap-4">
          <Button color="lightGray" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button color="purple" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
