import { baseRequest, BaseResponse, getApi, patchApi, Metadata, postApi, deleteApi } from '../api'

export const routes = {
  index: '/admin/auto_assigner_time_zones',
  edit: '/admin/auto_assigner_time_zones/:id/edit',
  new: '/admin/auto_assigner_time_zones/new',
}

export interface AutoAssignerTimeZone {
  id: number
  endTime: string
  name: string
  position: number
  startTime: string
}

export interface AutoAssignerTimeZonesResponse extends BaseResponse {
  autoAssignerTimeZones: AutoAssignerTimeZone[]
  meta: Metadata
}

export async function getAutoAssignerTimeZones(): Promise<AutoAssignerTimeZonesResponse> {
  const autoAssignerTimeZonesUrl = '/api/internal/admin/auto_assigner_time_zones'
  const response = await baseRequest(autoAssignerTimeZonesUrl, getApi)
  const { data, meta } = response.data

  return {
    error: response.error,
    status: response.status,
    autoAssignerTimeZones: data as unknown as AutoAssignerTimeZone[],
    meta: meta as Metadata,
  }
}

export async function updateAutoAssignerTimeZone(id, params): Promise<BaseResponse> {
  const updateAutoAssignerTimeZoneUrl = `/api/internal/admin/auto_assigner_time_zones/${id}`
  return await baseRequest(updateAutoAssignerTimeZoneUrl, patchApi, params)
}

export async function createAutoAssignerTimeZone(params): Promise<BaseResponse> {
  const createAutoAssignerTimeZoneUrl = '/api/internal/admin/auto_assigner_time_zones'
  return await baseRequest(createAutoAssignerTimeZoneUrl, postApi, params)
}

export async function destroyAutoAssignerTimeZone(id): Promise<BaseResponse> {
  const destroyAutoAssignerTimeZoneUrl = `/api/internal/admin/auto_assigner_time_zones/${id}`
  return await baseRequest(destroyAutoAssignerTimeZoneUrl, deleteApi)
}
