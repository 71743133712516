import { DesignRequestStatus, EditMode } from 'lib/api/tickets/tickets'

export function getEditMode(
  status: DesignRequestStatus,
  isNewState: boolean,
  currentVersion: number,
  lastDeliveredVersion: number
) {
  switch (status) {
    case DesignRequestStatus.draft:
      return EditMode.draft
    case DesignRequestStatus.onDeck:
    case DesignRequestStatus.preview:
    case DesignRequestStatus.wipOnDeck:
      if (isNewState) {
        return EditMode.draft
      } else if (currentVersion !== lastDeliveredVersion) {
        return EditMode.processing
      }
      return EditMode.revise
    case DesignRequestStatus.attention:
    case DesignRequestStatus.review:
      return EditMode.revise
    case DesignRequestStatus.inProgress:
      return EditMode.processing
    case DesignRequestStatus.completed:
    case DesignRequestStatus.canceled:
    case DesignRequestStatus.archived:
    default:
      return EditMode.complete
  }
}
