import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Trash } from 'lucide-react'

import { CompanyTag } from 'lib/api/company-tags/company-tags'
import TextInput from 'lib/components/text-input/text-input'

import Button from 'components/core/button'
import { useCompanyTaggingContext, ViewStates } from 'providers/company-tagging-provider'
import { errorMessage } from './input-validation'
import TagCount from 'components/elements/company-tagging/tag-count'
import { cn } from 'lib/util/cn'
import { toast } from 'lib/components/toast/toast'

interface EditTagProps {
  tag: CompanyTag
}

export default function EditCompanyTag({ tag }: EditTagProps) {
  const [newName, setNewName] = useState(tag?.name)
  const nameInputRef = useRef(null)
  const { updateCompanyTag, setCurrentView } = useCompanyTaggingContext()

  const errorMessageText = errorMessage(newName)

  async function handleSave() {
    try {
      await updateCompanyTag(tag, newName)
    } catch (error) {
      toast.error('Error updating tag')
      console.error('Error updating tag', error)
    }
  }

  useEffect(() => {
    if (open && nameInputRef.current) {
      nameInputRef.current.focus()
    }
  })

  return (
    <>
      <div className="tw-pt-4 tw-pb-2">
        <TextInput
          error={errorMessageText}
          name="tag-name"
          value={newName}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setNewName(event.target.value)}
          placeholder="Tag name"
          ref={nameInputRef}
        />
        {errorMessageText && <span className="tw-text-red-600">{errorMessageText}</span>}
      </div>
      <TagCount tagId={tag.id} />
      <div
        className={cn('tw-flex tw-items-center tw-mt-4', {
          'tw-justify-between': tag.meta.permissions.destroy,
          'tw-justify-end': !tag.meta.permissions.destroy,
        })}
      >
        {tag.meta.permissions.destroy && (
          <Button color="lightGray" onClick={() => setCurrentView({ viewState: ViewStates.Delete, tag })}>
            <div className="tw-flex tw-items-center tw-gap-2 tw-text-red-600">
              <Trash className="lu-md tw-text-red-500" />
              Delete Tag
            </div>
          </Button>
        )}
        <Button color="purple" disabled={!!errorMessageText || newName === tag?.name} onClick={handleSave}>
          Save
        </Button>
      </div>
    </>
  )
}
