import React, { ReactElement } from 'react'
import { Cancellation } from 'interfaces/cancellation'
import axios from 'axios'

import { ConvertDateToAmerican, DateDifferenceInDays } from '../shared/table/column-elements'

interface CancelationProps {
  cancellation: Cancellation
  onCancellationChange?: () => void
}

export const DaysUntilCancellation = ({ cancellation }: CancelationProps): ReactElement => {
  // this might be the exact same as DaysUntilTransfer, and as such, both could be made generic and moved
  // to column-elements.tsx. Won't do it now because DaysUntilTransfer might even not make sense to exist
  // as it has fields that don't exist in Cancellation, like endedAt & elegibleForRefund.
  const { activeUntil, endedAt, elegibleForRefund, createdAt } = cancellation
  let remainingDays = 'N/A'
  let secondLine = ''
  let refundDate = ''

  if (activeUntil) {
    const daysUntilCancellation = DateDifferenceInDays(Date.parse(activeUntil), Date.now())
    if (daysUntilCancellation > 0) remainingDays = `${daysUntilCancellation} days`
    secondLine = `Active til ${ConvertDateToAmerican(activeUntil)}`
  } else {
    if (endedAt) secondLine = `Ended ${ConvertDateToAmerican(endedAt)}`
  }

  if (elegibleForRefund && !endedAt) refundDate = `(Refunds ${ConvertDateToAmerican(createdAt)})` // need to sum 4 days (?) here, this is not correct yet

  return (
    <td className="" data-testid="company-days-until-cancellation-container">
      {remainingDays}
      <br />
      {secondLine}
      <br />
      {refundDate}
    </td>
  )
}

export const NumberOfRequests = ({ cancellation }: CancelationProps): ReactElement => (
  <td>
    <a
      href={window.Routes.adminTicketsUrl({
        company_id: cancellation.company.id,
      })}
      target="_blank"
      rel="noreferrer"
    >
      {cancellation.company.numberOfRequests}
    </a>
  </td>
)

export const RefundStatus = ({ cancellation, onCancellationChange }: CancelationProps): ReactElement => {
  const { refundStatus } = cancellation

  if (!refundStatus) return <td>Not Eligible</td>
  if (refundStatus !== 'Pending') return <td> {refundStatus}</td>

  const HandleCancelRefund = async () => {
    const url = window.Routes.apiInternalReportCancellationUrl({
      id: cancellation.id,
    })

    if (!window.confirm(`Are you sure you want to cancel the refund for this cancellation?`)) return

    await axios.put(url, {
      refund_status: 'cancelled',
    })
    onCancellationChange()
  }

  const HandleMarkRefunded = async () => {
    const url = window.Routes.apiInternalReportCancellationUrl({
      id: cancellation.id,
    })

    if (!window.confirm(`Are you sure you have refunded this customer in Stripe already?`)) return

    await axios.put(url, {
      refund_status: 'refunded',
    })
    onCancellationChange()
  }

  const CancelRefund = () => (
    <span className="tw-text-red-500 tw-cursor-pointer" onClick={HandleCancelRefund}>
      Cancel Refund
    </span>
  )

  const MarkRefunded = () => (
    <span className="tw-text-blue-300 tw-cursor-pointer" onClick={HandleMarkRefunded}>
      Mark Refunded
    </span>
  )

  return (
    <td>
      {refundStatus}
      <br />
      <CancelRefund />
      <br />
      <MarkRefunded />
    </td>
  )
}
