import { Plus, Upload } from 'lucide-react'
import { useState } from 'react'

import { AdminTicketState } from 'lib/api/admin/tickets/admin-tickets'
import Button, { LinkLikeButton } from 'components/core/button'

import AddLinkModal from '../add-link-modal/add-link-modal'
import { useAdminMediaContext } from '../providers/admin-media-provider'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import AdminCreateNewVersionModal from '../create-new-version-modal/admin-create-new-version-modal'
import { useAdminUserContext } from 'providers/admin-user-provider'

const allowableAddLinkFormats = Object.freeze(['figma', 'canva', 'google_slides'])

function AddLinkButton() {
  const [openAddLinkModal, setOpenAddLinkModal] = useState(false)

  return (
    <>
      <Button
        color="neutralGray"
        onClick={() => setOpenAddLinkModal(true)}
        className="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2"
      >
        <Plus className="lu-md" />
        Add Link
      </Button>
      <AddLinkModal open={openAddLinkModal} setOpen={setOpenAddLinkModal} />
    </>
  )
}

function MediaUploadButton() {
  const { setIsFileUploaderOpen } = useAdminMediaContext()
  return (
    <Button
      color="purple"
      onClick={() => {
        setIsFileUploaderOpen(true)
      }}
      className="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2"
    >
      <Upload className="lu-md" />
      Upload Assets
    </Button>
  )
}

function CreateNewVersionButton() {
  const [isCreateNewVersionModalOpen, setIsCreateNewVersionModalOpen] = useState(false)

  return (
    <>
      <LinkLikeButton
        onClick={() => {
          setIsCreateNewVersionModalOpen(true)
        }}
      >
        <div className="tw-flex tw-items-center tw-gap-2">
          <Plus className="lu-md" />
          Create new version
        </div>
      </LinkLikeButton>
      <AdminCreateNewVersionModal open={isCreateNewVersionModalOpen} setOpen={setIsCreateNewVersionModalOpen} />
    </>
  )
}

export default function AdminMediaButtonsFooter({ selected }: { selected: string }) {
  const { ticket } = useAdminTicketContext()
  const { selectedVersion } = useAdminMediaContext()
  const { user } = useAdminUserContext()

  if (
    selected !== 'creative' ||
    ticket?.selectedFormats == null ||
    ticket.currentVersion !== selectedVersion ||
    ticket.state !== AdminTicketState.inProgress
  ) {
    return null
  }

  const allowedToAddLinkFormats = ticket.selectedFormats.some((format) => allowableAddLinkFormats.includes(format))
  const isAssignedDesigner = ticket.designer.id === user.designerId
  const hasCreateFilePermission = user.isDPMember ? isAssignedDesigner : user.isDPAdmin || user.isDPSuperAdmin

  const showAddLinkButton = allowedToAddLinkFormats && hasCreateFilePermission

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-gap-3 tw-sticky tw-bottom-0 tw-left-0 tw-px-6 tw-py-4 tw-w-full">
      {hasCreateFilePermission && <MediaUploadButton />}
      {showAddLinkButton && <AddLinkButton />}
      {<CreateNewVersionButton />}
    </div>
  )
}
