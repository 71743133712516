import { ReactElement } from 'react'
import { ChevronLeft } from 'lucide-react'

import { routes } from 'lib/api/admin/tickets/admin-tickets'

import AdminHeaderDetails from './admin-header-details'

export default function AdminHeader(): ReactElement {
  return (
    <header className="tw-py-4 tw-px-2">
      <a
        href={routes.assignedToMe}
        className="tw-text-neutral-400 hover:tw-text-cornflower-500 tw-flex tw-items-center tw-w-max tw-mb-2"
      >
        <ChevronLeft className="lu-light lu-md" />
        Back to all tickets
      </a>
      <AdminHeaderDetails />
    </header>
  )
}
