import Button from 'components/core/button'
import Modal from 'lib/components/modals/modal'
import TextInput from 'lib/components/text-input/text-input'
import { useMemo, useState } from 'react'
import { useAdminTicketContext } from '../../providers/admin-ticket-provider'
import debounce from 'lib/util/debounce'
import { getTicket } from 'lib/api/tickets/tickets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'lib/components/toast/toast'

interface MergeRequestModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

enum TicketInputError {
  valid = 0,
  bad_input = 1,
  bad_ticket = 2,
}

const MergeRequestModal = ({ open, setOpen }: MergeRequestModalProps) => {
  const { mergeAndCloseTicket } = useAdminTicketContext()
  const [mergeTicketId, setMergeTicketId] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [errorType, setErrorType] = useState<TicketInputError>(null)

  const closeModal = () => setOpen(false)
  const mergeRequest = async () => {
    setLoading(true)
    try {
      await mergeAndCloseTicket(parseInt(mergeTicketId))
      toast.success('Successfully merged ticket')
      closeModal()
    } catch (e) {
      console.error('Error merging ticket', e)
      toast.error('Error merging ticket')
    }
    setLoading(false)
  }

  const debouncedOnChange = useMemo(() => {
    return debounce(async (value: string) => {
      try {
        await getTicket(parseInt(value))
        setErrorType(TicketInputError.valid)
      } catch (e) {
        console.error(`Error fetching ticket #${value}`, e)
        setErrorType(TicketInputError.bad_ticket)
      }
      setSearchLoading(false)
    }, 1000)
  }, [])

  const handleChange = (value: string) => {
    if (!value.match(/\d+/)) {
      setErrorType(TicketInputError.bad_input)
    } else {
      setSearchLoading(true)
      setErrorType(null)
      setMergeTicketId(value)
      debouncedOnChange(value)
    }
  }

  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <Modal.Header>
        <div className="tw-font-bold tw-text-xl">Merge and Close Request</div>
      </Modal.Header>
      <Modal.Body closeButton setOpen={setOpen}>
        <h5>Are you sure you want to merge and close this request?</h5>
        <p>
          After merging, all conversations for this ticket will move to the new ticket. This request will close and move
          to the trash. Both requests will have a note to reference that the merge was made.
        </p>
        <p>
          Please type the <span className="tw-font-bold">DPP ID</span> of the ticket you would like this merged into.
        </p>

        <TextInput
          disabled={loading}
          error={!!errorType}
          placeholder="Ticket ID"
          value={mergeTicketId}
          onChange={(e) => handleChange(e.target.value)}
        />

        <div data-testid="ticket-error" className="tw-text-flushpink-500">
          {errorType === TicketInputError.bad_input && 'Invalid ID. Please verify and try again.'}
          {errorType === TicketInputError.bad_ticket &&
            'No ticket found with the associated ID. Please verify and try again.'}
        </div>
        <p>
          You can find the DPP ID in the URL of the ticket. Ex.{' '}
          <em>https://jar.designpickle.com/admin/tickets/[DPP ID]</em>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-gap-2 tw-mt-8 tw-justify-center">
          <Button type="button" color="lightGray" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" color="red" onClick={mergeRequest} disabled={errorType !== TicketInputError.valid}>
            {loading || searchLoading ? <FontAwesomeIcon spin icon="spinner-third" /> : 'Merge and close request'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default MergeRequestModal
