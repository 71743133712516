import React from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from 'lib/array/utils'
import styles from './dropdown-menu.module.scss'

type Size = 'md' | 'lg'
type DropdownType = 'no-bg' | 'white-bg'

interface DropdownMenuProps {
  children: React.ReactNode
  dropDownType?: DropdownType
  size?: Size
  renderOnTop?: boolean
  sticky?: boolean
}

const menuTypeClass = (dropdownType: DropdownType) =>
  ({
    'no-bg': styles.noBgMenu,
    'white-bg': styles.whiteBgMenu,
  }[dropdownType] || styles.whiteBgMenu)

const defaultClassNames = {
  menu: 'tw-static tw-inline-block tw-items-center tw-justify-items-center tw-mx-1',
  buttonWrapper: 'tw-items-center tw-justify-items-center',
  button: 'tw-border-0 tw-rounded-full tw-flex tw-items-center tw-justify-items-center tw-text-peppercorn-400',
  icon: 'far fa-ellipsis-v tw-text-lg',
}

const defaultSizeClass = {
  button: 'tw-h-8 tw-w-8',
  icon: 'tw-ml-2',
}

const customSizeClass = (size: Size) =>
  ({
    md: defaultSizeClass,
    lg: {
      button: 'tw-h-10 tw-w-10',
      icon: 'tw-ml-3',
    },
  }[size] || defaultSizeClass)

/**
 * Overflow menu component
 * @deprecated use IconFlyoutMenu instead from lib/components/icon-flyout-menu/icon-flyout-menu.tsx
 */
export default function DropdownMenu({
  children,
  dropDownType,
  size,
  renderOnTop = false,
  sticky = false,
}: DropdownMenuProps): JSX.Element {
  return (
    <Menu as="div" className={defaultClassNames.menu}>
      <div className={defaultClassNames.buttonWrapper}>
        <Menu.Button
          className={classNames(menuTypeClass(dropDownType), defaultClassNames.button, customSizeClass(size).button)}
          data-testid="overflow-button"
        >
          <span className="tw-sr-only">Open options</span>
          <i className={classNames(defaultClassNames.icon, customSizeClass(size).icon)} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="tw-transition tw-ease-out tw-duration-100"
        enterFrom="tw-transform tw-opacity-0 tw-scale-95"
        enterTo="tw-transform tw-opacity-100 tw-scale-100"
        leave="tw-transition tw-ease-in tw-duration-75"
        leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
        leaveTo="tw-transform tw-opacity-0 tw-scale-95"
      >
        <Menu.Items
          className={sticky ? styles.menuItemsSticky : styles.menuItems}
          style={renderOnTop ? { bottom: '3.25rem' } : {}}
        >
          <div className="tw-py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
