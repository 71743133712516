import { baseRequest, BaseResponse, postApi } from '../api'

export interface AuthenticateCollaboratorResponse extends BaseResponse {
  auth: AuthenticateCollaboratorResponseData
}

interface AuthenticateCollaboratorResponseData {
  token: string
}

export async function authenticateCollaborator(
  email: string,
  password: string
): Promise<AuthenticateCollaboratorResponse> {
  const response = await baseRequest('/api/internal/collaborators/login', postApi, {
    data: {
      collaborator: {
        email,
        password,
      },
    },
  })

  return {
    auth: response.data.data as unknown as AuthenticateCollaboratorResponseData,
    error: response.error,
    status: response.status,
  }
}

export async function createCollaborator(
  email: string,
  firstName: string,
  lastName: string,
  password: string
): Promise<AuthenticateCollaboratorResponse> {
  const response = await baseRequest('/api/internal/collaborators', postApi, {
    data: {
      collaborator: {
        email: email.toLowerCase(),
        first_name: firstName,
        last_name: lastName,
        password,
      },
    },
  })

  return {
    auth: response.data.data as unknown as AuthenticateCollaboratorResponseData,
    error: response.error,
    status: response.status,
  }
}

export async function updatePassword(password: string, reset_password_token: string) {
  await postApi(`/api/internal/collaborators/update_password`, {
    data: {
      collaborator: { password, reset_password_token },
    },
  })
  return null
}

export async function resetPassword(email: string, redirect_url: string) {
  const response = await postApi(`/api/internal/collaborators/reset_password`, {
    data: { collaborator: { email, redirect_url } },
  })
  return response
}
