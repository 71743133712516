import Pagination from 'components/elements/pagination'

import { columnIds, useTable } from './use-table'
import { flexRender } from '@tanstack/react-table'
import Breadcrumb from 'components/core/breadcrumb'
import { AnimatedLoadingScreen, EmptySearchScreen } from '../requests/empty-screens'
import Filters from './filters'
import Button from 'components/core/button'
import { ArrowDown, ArrowDownUp, ArrowUp, LucideRefreshCcw } from 'lucide-react'
import { useFirstRender } from './use-first-render'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'
import SavedSearches from './saved-searches'
import BulkActionsButtons from '../requests/bulk-actions-buttons'
import BulkActionsBar from 'components/elements/bulk-actions-bar'
import { Skeleton } from 'lib/components/skeleton/skeleton'
import { cn } from 'lib/util/cn'

const sortIcons = {
  asc: <ArrowUp className="lu-sm tw-text-neutral-500 tw-cursor-pointer" />,
  desc: <ArrowDown className="lu-sm tw-text-neutral-500 tw-cursor-pointer" />,
  false: <ArrowDownUp className="lu-sm tw-text-neutral-500 tw-cursor-pointer" />,
}

const classNames = {
  tr: 'tw-border-solid tw-border-r-0 tw-border-l-0 tw-border-t-2 tw-border-b-2 tw-border-neutral-200',
  td: 'tw-p-4',
}

export const getResultsTitle = (search: string, count: number) => {
  if (search) {
    return `Results for "${search.slice(0, 50)}${search.length > 50 ? '...' : ''}" (${count})`
  }
  return `Results (${count})`
}

const SkeletonLoader = ({ cols }: { cols: number }) => (
  <tr className={classNames.tr}>
    <td className={cn(classNames.td)}>
      <Skeleton className="tw-bg-neutral-200 tw-h-4 tw-w-4 tw-mx-auto" />
    </td>
    <td className={cn(classNames.td, 'tw-flex tw-gap-4 tw-w-full tw-items-center')}>
      <Skeleton className="tw-rounded-full tw-bg-neutral-200 tw-h-10 tw-w-10" />
      <div className="tw-flex tw-flex-col tw-w-2/3 tw-gap-2">
        <Skeleton className="tw-h-4 tw-bg-neutral-200 tw-rounded tw-mt-2" />
        <Skeleton className="tw-h-4 tw-bg-neutral-200 tw-rounded" />
      </div>
    </td>
    {Array.from({ length: cols - 2 }).map((_, index) => (
      <td className={classNames.td} key={index}>
        <Skeleton className="tw-h-4 tw-bg-neutral-200 tw-rounded" />
      </td>
    ))}
    <td className={classNames.td}>
      <Skeleton className="tw-bg-neutral-200 tw-h-3 tw-w-1" />
    </td>
  </tr>
)

export const Search = () => {
  const { pagination, query, count, loading, error, table } = useTable()

  const { isModalVisible, modalData, setModalVisible, menuButtonActionWithCallbacks } = useConfirmationModalContext()

  const { loading: loadingFirstRender } = useFirstRender()

  if (loading || loadingFirstRender) {
    return (
      <div className="tw-h-screen-60 tw-flex tw-items-center tw-justify-center">
        <AnimatedLoadingScreen
          variant="binoculars"
          text="We're searching your requests..."
          subText="Hang tight while we locate these."
        />
      </div>
    )
  }

  const emptyScreen = query.data?.data?.length === 0 && <EmptySearchScreen />
  const errorScreen = error && (
    <>
      <AnimatedLoadingScreen
        variant="errorHole"
        text="Uh oh! Looks like your search didn't load..."
        subText="You can retry or head back to your queue."
      />
      <Button
        onClick={() => {
          window.location.reload()
        }}
        color="purple"
        className="tw-mt-4"
      >
        <LucideRefreshCcw className="tw-mr-1" /> Retry
      </Button>
    </>
  )

  return (
    <>
      <ConfirmationModal
        title={modalData['title']}
        message={modalData['message']}
        confirmBtnText={modalData['confirmBtnText']}
        confirmAction={modalData['confirmAction']}
        cancelAction={() => setModalVisible(false)}
        visible={isModalVisible}
      />
      <Breadcrumb className="tw-mt-3" text="Back to requests" href={`/requests`} />
      <div className="tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <h1 className="tw-text-2xl">
            {getResultsTitle(
              Array.isArray(table.getColumn(columnIds.query).getFilterValue())
                ? table.getColumn(columnIds.query).getFilterValue()[0]
                : table.getColumn(columnIds.query).getFilterValue(),
              count
            )}
          </h1>
          <SavedSearches setFilters={table.setColumnFilters} />
        </div>
        <div className="tw-flex tw-gap-4 tw-items-center">
          <Filters table={table} />
        </div>
      </div>
      {errorScreen || emptyScreen ? (
        <div className="tw-items-center tw-justify-center tw-flex tw-flex-col"> {errorScreen || emptyScreen}</div>
      ) : (
        <>
          <table className="tw-w-full tw-bg-white tw-my-2">
            <>
              <thead className="tw-bg-neutral-50 tw-border-solid tw-border-r-0 tw-border-l-0 tw-border-t tw-border-b-2 tw-border-neutral-200">
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <tr className="" key={headerGroup.id}>
                      {table.getSelectedRowModel().rows.length > 0 ? (
                        <th scope="col" colSpan={12} className="tw-text-left tw-text-xs tw-tracking-wider tw-pl-4">
                          <BulkActionsBar
                            totalSelected={table.getSelectedRowModel().rows.length}
                            bulkAllToggle={table.getToggleAllRowsSelectedHandler()}
                          >
                            <BulkActionsButtons
                              status={table.getState().globalFilter}
                              bulkActionIds={table
                                .getSelectedRowModel()
                                .rows.map((row) => ({ ticketId: row.original.ticket_id }))}
                              menuActionBtn={menuButtonActionWithCallbacks(query.refetch, () => {
                                table.resetRowSelection(null)
                                table.setGlobalFilter('')
                              })}
                              onTagUpdate={() => {
                                table.resetRowSelection(null)
                                query.refetch()
                              }}
                              editTags={false}
                            />
                          </BulkActionsBar>
                        </th>
                      ) : (
                        headerGroup.headers.map((header) => {
                          return (
                            <th
                              className="tw-p-2 tw-uppercase tw-text-xs tw-font-medium tw-text-black"
                              key={header.id}
                              id={header.id}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              <div className="tw-flex tw-items-center tw-gap-2">
                                {header.column.getCanSort() &&
                                  sortIcons[(header.column.getIsSorted() as string) ?? null]}
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </div>
                            </th>
                          )
                        })
                      )}
                    </tr>
                  )
                })}
              </thead>
              <tbody>
                {query.isFetching
                  ? Array.from({ length: count < 20 ? count : 20 }).map((_, index) => (
                      <SkeletonLoader key={index} cols={table.getVisibleFlatColumns().length - 1} />
                    ))
                  : table.getRowModel().rows.map((row) => {
                      return (
                        <tr className={classNames.tr} key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td className={classNames.td} key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
              </tbody>
            </>
          </table>
          <Pagination
            pageCount={query.data?.meta?.total / pagination.pageSize}
            onPageChange={({ selected }) => {
              table.setPageIndex(selected + 1)
            }}
            forcePage={pagination.pageIndex - 1}
          />
        </>
      )}
    </>
  )
}
