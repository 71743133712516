import { ReactNode, useMemo, useRef, useState, JSX } from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getClassNames } from 'lib/components/buttons/icon-button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface IconFlyoutProps {
  children: ReactNode
  color: 'primary' | 'secondary' | 'transparent' | 'danger' | 'success'
  icon: IconProp
  size?: 'xs' | 'sm' | 'lg' | 'xl' | '2xl'
  adjustedLeft?: boolean
  adjustedRight?: boolean
  viewportBottomThreshold?: number
  forceRenderOnTop?: boolean
}

const classNames = {
  menuItems: `tw-text-neutral-800
    tw-absolute
    tw-bg-white
    tw-flex
    tw-flex-col
    tw-items-start
    tw-min-w-48
    tw-mt-2
    tw-origin-top-left
    tw-py-1
    tw-ring-1
    tw-ring-black
    tw-ring-opacity-5
    tw-rounded
    tw-shadow-lg
    tw-w-max
    tw-z-20
    `,
  menuItem: `tw-border-none
    hover:tw-bg-neutral-100
    tw-bg-white
    tw-px-5
    tw-py-2
    tw-text-left
    tw-text-neutral-800
    tw-w-full
    tw-cursor-pointer
  `,
}

interface ItemProps {
  children: ReactNode
  onClick: () => void
  className?: string
}

function Link({
  children,
  url,
  target = '_blank',
  ...otherProps
}: {
  children: ReactNode
  url: string
  target?: string
}): JSX.Element {
  return (
    <Menu.Item>
      {() => (
        <a className={classNames.menuItem} href={url} target={target} rel="noreferrer" {...otherProps}>
          {children}
        </a>
      )}
    </Menu.Item>
  )
}

function Button({ children, onClick }: ItemProps): JSX.Element {
  return (
    <Menu.Item>
      {() => (
        <button className={classNames.menuItem} onClick={onClick}>
          {children}
        </button>
      )}
    </Menu.Item>
  )
}

function ContainerIconFlyoutMenu({
  children,
  color,
  icon,
  size = 'sm',
  adjustedLeft = false,
  adjustedRight = false,
  viewportBottomThreshold = 0,
  forceRenderOnTop = false,
}: IconFlyoutProps): JSX.Element {
  const menuRef = useRef<HTMLDivElement>(null)
  const [isOpenAbove, setIsOpenAbove] = useState<boolean>(false)

  const adjustItemsPositionClassName = useMemo(() => {
    if (adjustedRight) return 'tw--bottom-4 tw-left-12'

    return adjustedLeft ? 'tw-right-0' : ''
  }, [adjustedLeft, adjustedRight])

  function handleFocus() {
    if (viewportBottomThreshold || forceRenderOnTop) {
      setIsOpenAbove(() => {
        if (forceRenderOnTop) return true

        const rect = menuRef.current.getBoundingClientRect()
        const windowHeight = window.innerHeight || document.documentElement.clientHeight
        return rect.bottom > windowHeight - viewportBottomThreshold
      })
    }
  }

  return (
    <Menu as="div" ref={menuRef} className="tw-relative tw-inline-block" onFocus={handleFocus}>
      {({ open }) => (
        <>
          <Menu.Button
            className={getClassNames({ size, color, forceFocus: open })}
            data-testid={`icon-menu-button-${icon[1]}`}
          >
            <FontAwesomeIcon icon={icon as IconProp} size={size} />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            <Menu.Items
              className={`${classNames.menuItems} ${adjustItemsPositionClassName} ${isOpenAbove ? 'tw-bottom-12' : ''}`}
            >
              {children}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export const IconFlyoutMenu = Object.assign(ContainerIconFlyoutMenu, { Button, Link })
