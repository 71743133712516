import Button from 'components/core/button'
import Modal from 'lib/components/modals/modal'
import { Upload } from 'lucide-react'
import { FormState, UpdateFormState } from './custom-branding'
import { FilestackUploadDoneParams } from 'lib/util/filestack'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { client, PickerInline } from 'filestack-react'

export function CustomBrandingLogoSettings({
  apiKey,
  formState,
  updateFormState,
}: {
  apiKey: string
  formState: FormState
  updateFormState: UpdateFormState
}) {
  const [showUploaderModal, setShowUploaderModal] = useState(false)

  const onUpload = useCallback(
    async (result: client.PickerFileMetadata) => {
      const { filesUploaded, filesFailed } = result as FilestackUploadDoneParams
      if (filesFailed.length) {
        console.error('Logo failed to upload:', filesFailed)
        alert('Your logo failed to upload, please try again.')
      }
      if (filesUploaded.length) {
        updateFormState('logo', filesUploaded[0].url)
      }

      setShowUploaderModal(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const FilestackPicker = useMemo<ReactNode>(() => {
    const pickerOptions: client.PickerOptions = {
      accept: ['image/*'],
      exposeOriginalFile: true,
      fromSources: ['local_file_system'],
      storeTo: {
        location: 'S3',
      },
      maxFiles: 1,
      maxSize: 10 * 1024 * 500, // kb
    }
    return <PickerInline apikey={apiKey} pickerOptions={pickerOptions} onUploadDone={onUpload} />
  }, [apiKey, onUpload])

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-items-start">
      <div>
        <label className="tw-mb-0">Logo</label>
      </div>
      {!!formState?.logo?.value && (
        <div className="tw-p-4 tw-w-full tw-rounded-md tw-flex tw-flex-col tw-items-center tw-gap-4 tw-border tw-border-solid tw-border-gray-300">
          <img src={formState?.logo?.value} alt="White Label Logo" className="tw-w-36 tw-h-auto" />
        </div>
      )}
      <Button
        color={'lightGray'}
        onClick={() => setShowUploaderModal(true)}
        className={`tw-flex tw-items-center tw-gap-2 ${
          (formState?.logo?.error && 'tw-ring-2 tw-ring-flushpink-500') || ''
        }`}
      >
        <Upload className="lu-sm" />
        {formState?.logo?.value ? 'Replace' : 'Upload'} Logo
      </Button>
      {formState?.logo?.error && <div className="tw-text-flushpink-500">{formState?.logo?.error}</div>}
      <div>
        <strong>Recommended dimensions:</strong> 1024 x 1024px. Any logo bigger than this will be automatically resized.
        <br />
        <strong>Formats:</strong> JPG or PNG only
        <br />
        <strong>Size:</strong> Max Size 500kb
        <br />
      </div>
      <Modal open={showUploaderModal} setOpen={setShowUploaderModal} size="lg">
        <div className="tw-flex tw-flex-col tw-gap-4">{FilestackPicker}</div>
      </Modal>
    </div>
  )
}
