import { useState, useEffect, ReactElement, ChangeEvent } from 'react'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import {
  createBlocklist,
  deleteBlocklist,
  getBlocklist,
  getDesigners,
  routes,
} from 'lib/api/admin/blocklists/blocklists'
import { Company } from 'interfaces/company'
import { Designer } from 'interfaces/designer'
import Button from 'components/core/button'
import { ArrowLeftToLineIcon, Trash } from 'lucide-react'
import { Toaster, toast } from 'lib/components/toast/toast'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-16',
  header: 'tw-flex tw-justify-between navbar-custom',
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

interface BlocklistDetailsPageProps {
  company: Company
}

export default function BlocklistDetailsPage({ company }: BlocklistDetailsPageProps): ReactElement {
  return (
    <>
      <Toaster />
      <BlocklistView company={company} />
    </>
  )
}

function BlocklistView({ company }: { company: Company }): ReactElement {
  const [blocklists, setBlocklists] = useState([])
  const [designers, setDesigners] = useState<Designer[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [rowHidden, setRowHidden] = useState<boolean>(true)
  const [selectedDesignerId, setSelectedDesignerId] = useState<null | number>(null)

  useEffect(() => {
    getBlocklist(company.id)
      .then(
        ({ blocklistsData }) => {
          setBlocklists(blocklistsData)
        },
        (error) => {
          console.error(error)
        }
      )
      .finally(() => {
        setIsLoading(false)
      })

    getDesigners().then(
      ({ designerList }) => {
        setDesigners(designerList)
      },
      (error) => {
        console.error(error)
      }
    )
  }, [company.id])

  function handleClick() {
    setRowHidden((prevHidden) => !prevHidden)
  }

  async function handleDelete(id: number) {
    if (confirm('Are you sure you want to delete?')) {
      try {
        const { message, status } = await deleteBlocklist(id)
        if (status === 200) {
          toast.success(message)
          setBlocklists(blocklists.filter((blocklist) => blocklist.id !== id))
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  async function handleSubmit() {
    if (!selectedDesignerId) return
    try {
      const { status, blocklistData } = await createBlocklist({
        company_id: company.id,
        designer_id: selectedDesignerId,
      })
      if (status === 201) {
        toast.success('Blocklist created')
        setBlocklists([...blocklists, blocklistData])
        setSelectedDesignerId(null)
        setRowHidden(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const setDesignerParams = (ev: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDesignerId(+ev.target.value)
  }

  const blocklistedDesignerIds = (): number[] => blocklists.map(({ designer }) => designer.id)

  return (
    <div className={classNames.wrapper}>
      <BlocklistPageHeader name={company.name} />
      <div className={classNames.container}>
        <Button data-testid="add-creative" onClick={handleClick}>
          {rowHidden ? 'Add Creative to Blocklist' : 'Cancel'}
        </Button>
        <div className={classNames.tableWrapper}>
          <table className={classNames.table}>
            <BlocklistTableHeader />
            <tbody>
              <tr hidden={rowHidden}>
                <td className={classNames.tableCell}>
                  <select data-testid="select-designer" onChange={(ev) => setDesignerParams(ev)}>
                    <option key="0"></option>
                    {designers
                      .filter(({ id }) => blocklistedDesignerIds().indexOf(id) === -1)
                      .map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </select>
                </td>
                <td className={classNames.tableCell}>
                  <Button data-testid="submit-button" onClick={handleSubmit}>
                    Submit
                  </Button>
                </td>
              </tr>
              {isLoading ? (
                <LoadingScreenTableRow />
              ) : (
                blocklists.map(({ id, designer }) => (
                  <tr key={id} className={classNames.tableRow}>
                    <td className={classNames.tableCell}>{designer.name}</td>
                    <td className={classNames.tableCell}>
                      <span data-testid={`delete-blocklist-${id}`} title="Delete" onClick={() => handleDelete(id)}>
                        <Trash />
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const BlocklistPageHeader = ({ name }: { name: string }) => (
  <div id="page-header" className={classNames.header}>
    <h4>
      <a href={routes.index}>
        <ArrowLeftToLineIcon />
      </a>
      &nbsp; Blocklists for {name}
    </h4>
  </div>
)

const BlocklistTableHeader = () => (
  <thead>
    <tr>
      <th className={classNames.tableHeadCell}>Designer</th>
      <th className={classNames.tableHeadCell}>Actions</th>
    </tr>
  </thead>
)
