import { useEffect, useRef } from 'react'

const classNames = {
  checkboxWrapper: 'tw-flex tw-items-center tw-rounded-full tw-gap-2',
  checkbox:
    'tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600',
}

interface CheckboxProps {
  className?: string
  disabled?: boolean
  id?: string
  isChecked: boolean
  label?: string
  onClick: () => void
  indeterminate?: boolean
}

export default function Checkbox({
  isChecked,
  onClick,
  disabled = false,
  label,
  id = 'checked-checkbox',
  className = '',
  indeterminate = false,
}: CheckboxProps) {
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <div className={`${classNames.checkboxWrapper} ${className}`}>
      <input
        checked={isChecked}
        id={id}
        type="checkbox"
        value=""
        className={`${classNames.checkbox}`}
        onChange={onClick}
        disabled={disabled}
        ref={inputRef}
      />
      {label && (
        <label htmlFor={id} className="tw-p-0 tw-m-0 tw-text-neutral-700 tw-font-semibold">
          {label}
        </label>
      )}
    </div>
  )
}
