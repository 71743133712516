import { Dispatch, ReactElement, SetStateAction } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BetaBadge } from 'lib/components/badge/badge'
import Modal from 'lib/components/modals/modal'
import Textarea from 'lib/components/textarea/textarea'
import CopyTextButton from 'lib/components/buttons/copy-text-button'

interface DirectionReadOnlyAiTaskModalProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  promptText: string
}

const classNames = {
  title: 'tw-text-lg tw-font-bold tw-flex tw-items-center tw-gap-1',
}

export default function RequestAssistReadOnlyModal({
  open,
  setOpen,
  promptText,
}: DirectionReadOnlyAiTaskModalProps): ReactElement {
  return (
    <Modal open={open} setOpen={setOpen} size="lg">
      <Modal.Header>
        <span className={classNames.title}>
          <FontAwesomeIcon icon={['far', 'sparkles']} />
          <span>
            Request Assist <BetaBadge />
          </span>
        </span>
      </Modal.Header>
      <Modal.Body setOpen={setOpen} closeButton>
        <>
          <p>View the original prompt for the generated direction</p>
          <div className="tw-flex tw-items-end tw-justify-between">
            <label htmlFor="gen-ai-brief-assist-input-read-only" className="tw-font-bold tw-mb-0 tw-text-neutral-800">
              Your prompt
            </label>
          </div>
          <div className="tw-flex tw-items-start">
            <Textarea id="gen-ai-brief-assist-input-read-only" value={promptText} disabled />
            <div className="tw-ml-2 tw-mt-2">
              <CopyTextButton
                textToCopy={promptText}
                defaultButtonColor="transparent"
                tooltipDirection="left"
                tooltipCopyText="Copy prompt to clipboard"
                tooltipCopiedText="Prompt copied!"
              />
            </div>
          </div>
        </>
      </Modal.Body>
    </Modal>
  )
}
