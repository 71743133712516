import { useCallback, useEffect, useState } from 'react'
import {
  getAutoAssignerTimeZones,
  destroyAutoAssignerTimeZone,
  routes,
} from 'lib/api/auto-assigner-time-zones/auto-assigner-time-zones'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import { displayHoursAndMinutes } from 'lib/util/date'
import { Pencil, Trash2 } from 'lucide-react'
import { LinkButton } from 'components/core/button'
import IconButton from 'lib/components/buttons/icon-button'
import { Toaster, toast } from 'lib/components/toast/toast'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  header: 'tw-flex tw-justify-between navbar-custom',
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

function AutoAssignerTimeZones() {
  const [timeZones, setTimeZones] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const fetchTimeZones = useCallback(async () => {
    setIsLoading(true)

    try {
      const { autoAssignerTimeZones } = await getAutoAssignerTimeZones()
      setTimeZones(autoAssignerTimeZones)
    } catch (error) {
      console.error('AA Time Zones could not be fetched.', error)
      toast.error('AA Time Zones could not be fetched.')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchTimeZones()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleDestroy(e, id) {
    e.preventDefault()
    try {
      if (!window.confirm('Are you sure you want to delete this Auto Assigner Time Zone?')) return
      await destroyAutoAssignerTimeZone(id)
      toast.success('Auto Assigner Time Zone deleted successfully')
      fetchTimeZones()
    } catch (error) {
      console.error(error)
      toast.error('Failed to delete Auto Assigner Time Zone')
    }
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <h4>Time Zones</h4>
      </div>
      <div className={classNames.container}>
        <div className="tw-mb-4 tw-flex tw-justify-between tw-items-center">
          <LinkButton url={routes.new} className="tw-my-2">
            Add Time Zone
          </LinkButton>
        </div>
        <div className={classNames.tableWrapper}>
          <table className={classNames.table}>
            <TableHeader />
            <tbody>
              {isLoading ? (
                <LoadingScreenTableRow />
              ) : (
                timeZones.map(({ id, name, startTime, endTime, position }) => (
                  <tr key={id} className={classNames.tableRow}>
                    <td className={classNames.tableCell}>
                      <a
                        href={routes.edit.replace(':id', id.toString())}
                        className="tw-mr-3"
                        title="Edit company designer allocation"
                      >
                        <Pencil className="lu-light lu-md" />
                      </a>
                      {name}
                    </td>
                    <td className={classNames.tableCell}>{displayHoursAndMinutes(startTime)}</td>
                    <td className={classNames.tableCell}>{displayHoursAndMinutes(endTime)}</td>
                    <td className={classNames.tableCell}>{position}</td>
                    <td className={classNames.tableCell}>
                      <IconButton color="transparent" size="xs" onClick={(e) => handleDestroy(e, id)}>
                        <Trash2 />
                      </IconButton>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const TableHeader = () => (
  <thead>
    <tr>
      <th className={classNames.tableHeadCell}>Name</th>
      <th className={classNames.tableHeadCell}>Start Time</th>
      <th className={classNames.tableHeadCell}>End Time</th>
      <th className={classNames.tableHeadCell}>Position</th>
      <th className={classNames.tableHeadCell}></th>
    </tr>
  </thead>
)

export default function AutoAssignerTimeZonesPage() {
  return (
    <>
      <Toaster />
      <AutoAssignerTimeZones />
    </>
  )
}
