import { CircleHelp, Bell } from 'lucide-react'
import NewRequestButton from 'components/pages/request/new-request-button/new-request-button'
import { CurrentUser } from 'interfaces/app'
import SearchInput from '../search-input/search-input'
import { useState } from 'react'
import IconButton from '../buttons/icon-button'

interface HeaderProps {
  user: CurrentUser
}

export default function Header({ user }: HeaderProps) {
  const [search, setSearch] = useState('')

  return (
    <div className="tw-fixed tw-top-0 tw-z-50 tw-h-14 tw-bg-white tw-w-full tw-right-0 tw-ml-1 tw-flex tw-items-center tw-px-4 tw-gap-6">
      <NewRequestButton user={user} />
      <SearchInput value={search} onChange={setSearch} />
      <IconButton color="secondary" onClick={() => {}}>
        <Bell />
      </IconButton>
      <IconButton color="secondary" onClick={() => {}}>
        <CircleHelp />
      </IconButton>
    </div>
  )
}
