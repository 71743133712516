export const findById = (foundId, array) => {
  return array.find(({ id }) => id === foundId)
}

export const flatten = (arrays) => {
  return arrays.reduce((acc, array) => [...acc, ...array], [])
}

export const reorder = (array, startIndex, endIndex) => {
  // TO CHANGE POSITION
  const result = Array.from(array)

  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function classNames(...classes: string[]) {
  return classes.join(' ')
}

export function removeElement(array, element) {
  return array.filter(function (value) {
    return value != element
  })
}

export function createIntervalsBetweenValues(start: number, end: number, step = 1): number[] {
  const array = []

  while (start <= end) {
    array.push(start)
    start += step
  }

  return array
}

export function removeDuplicates(array) {
  return array.filter((value, index) => array.indexOf(value) === index)
}
