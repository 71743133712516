import { ReactElement } from 'react'

import { AppSettings, CurrentUser } from 'interfaces/app'

import UserProvider from 'providers/user-provider'
import PageHeader from 'components/elements/page-header'
import ExternalActionLinkItem from 'components/elements/dropdown/items/external-action-link-item'
import TicketList from './ticket-list'
import Announcements from 'lib/components/announcements/announcements'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'
import TicketRatingReminder from './ticket-rating-reminder'
import SingleQueueProvider, { useSingleQueueContext } from 'providers/single-queue-provider'
import TicketFeedbackProvider from 'providers/ticket-feedback-provider'
import { Ticket, GripHorizontal } from 'lucide-react'
import ConfirmationModalProvider from 'providers/confirmation-modal-provider'
import AllCompanyTagsProvider from 'providers/all-company-tags-provider'
import SingleQSelectedTagsProvider from 'components/pages/requests/single-q-selected-tags-provider'
import { Toaster } from 'lib/components/toast/toast'

const REQUESTS_CSV_URL = '/requests.csv'

interface SingleQueueProps {
  settings: AppSettings
  user: CurrentUser
}

function SingleQueue({ user }: SingleQueueProps): ReactElement {
  const { priorityMode, tickets } = useSingleQueueContext()

  return (
    <>
      <TicketFeedbackProvider>
        {user.ticketFeedback && !priorityMode && <TicketRatingReminder />}
        <PageHeader
          title={priorityMode ? ' Re-prioritize Requests ' : ' Requests '}
          icon={priorityMode ? <GripHorizontal /> : <Ticket />}
        >
          <ExternalActionLinkItem
            label="Export as CSV"
            iconClass="far fa-regular fa-file-export"
            route={REQUESTS_CSV_URL}
          />
        </PageHeader>
        <Announcements />
        <SingleQSelectedTagsProvider tickets={tickets}>
          <TicketList />
        </SingleQSelectedTagsProvider>
      </TicketFeedbackProvider>
    </>
  )
}

export default function SingleQueuePage({ settings, user }: SingleQueueProps): ReactElement {
  return (
    <>
      <Toaster />
      <UserProvider settings={settings} user={user}>
        <FeatureFlagsProvider>
          <SingleQueueProvider userId={user.id}>
            <ConfirmationModalProvider>
              <AllCompanyTagsProvider>
                <SingleQueue settings={settings} user={user} />
              </AllCompanyTagsProvider>
            </ConfirmationModalProvider>
          </SingleQueueProvider>
        </FeatureFlagsProvider>
      </UserProvider>
    </>
  )
}
