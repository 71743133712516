import { Ticket } from 'interfaces/ticket'
import { RatingsSteps, TicketFeedbackValues } from 'interfaces/ticket_feedback'
import { createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react'
import { useSingleQueueContext } from './single-queue-provider'
import { saveTicketFeedback } from 'lib/api/ticket-feedbacks/ticket-feedbacks'
import { snakeCaseKeys } from 'lib/object/utils'

interface TicketFeedbackContextValue {
  feedbackId: number
  isLoading: boolean
  rateTicket: (params: RateTicketParams) => Promise<void>
  ratingModalTicket: RatingModalTicket | null
  setFeedbackId: Dispatch<SetStateAction<number>>
  setRatingModalTicket: Dispatch<SetStateAction<RatingModalTicket>>
  setStep: Dispatch<SetStateAction<RatingsSteps>>
  showTicketRatingModal: boolean
  showTicketRatingReminder: boolean
  shuffledReasons: number[]
  step: RatingsSteps
  toggleFeedbackModalVisibility: (visible: boolean) => void
}

interface RateTicketParams {
  ticket: Ticket
  thumbRating: TicketFeedbackValues
}

const TicketFeedbackContext = createContext({})

export function useTicketFeedbackContext(): TicketFeedbackContextValue {
  return useContext(TicketFeedbackContext) as TicketFeedbackContextValue
}

export type RatingModalTicket = Pick<Ticket, 'id' | 'thumbnail' | 'subject' | 'feedbackReasons'>

function handleRateTicketErrors(response) {
  console.error(response)

  if (response.status === 403) {
    alert('You do not have permission to rate this request.')
    return
  }

  if (response.status === 409) {
    setTimeout(() => window.location.reload(), 1000)
    alert(response.response.data.message + '\nRefreshing the page.')
    return
  }

  alert('There was an error rating this request.')
}

export default function TicketFeedbackProvider({ children }: { children: ReactNode }): ReactElement {
  const [showTicketRatingReminder, setShowTicketRatingReminder] = useState<boolean>(true)
  const [showTicketRatingModal, setShowTicketRatingModal] = useState<boolean>(false)
  const [feedbackId, setFeedbackId] = useState<number>(null)
  const [step, setStep] = useState<RatingsSteps>(RatingsSteps.Feedback)
  const [ratingModalTicket, setRatingModalTicket] = useState<RatingModalTicket>(null)
  const [isLoading, setIsLoading] = useState(false)

  const { activeTab, fetchTickets, oops } = useSingleQueueContext()

  async function rateTicket({ ticket, thumbRating }: RateTicketParams) {
    setRatingModalTicket(ticket)
    setIsLoading(true)
    const params = { ticketId: ticket.id, thumbRating }

    return saveTicketFeedback(snakeCaseKeys(params))
      .then((response) => {
        setFeedbackId(response.id)
        if (thumbRating === TicketFeedbackValues.ThumbsUp) {
          setStep(RatingsSteps.ThankYou)
          return
        }
        toggleFeedbackModalVisibility(true)
      })
      .catch(handleRateTicketErrors)
      .finally(() => setIsLoading(false))
  }

  function toggleFeedbackModalVisibility(visible: boolean) {
    setShowTicketRatingModal(visible)
    if (!visible) {
      setStep(RatingsSteps.Feedback)
      if (activeTab === 'archived') fetchTickets().catch(oops)
      if (showTicketRatingReminder) {
        setShowTicketRatingReminder(false)
      }
    }
  }

  const shuffledReasons = useMemo(
    () => {
      const reasonsLength = ratingModalTicket?.feedbackReasons?.length || 0
      if (!reasonsLength) return []

      const indices = Array.from(Array(reasonsLength - 1).keys())
      indices.sort(() => 0.5 - Math.random())
      return [...indices, reasonsLength - 1]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ratingModalTicket?.id]
  )

  const context: TicketFeedbackContextValue = {
    feedbackId,
    isLoading,
    rateTicket,
    ratingModalTicket,
    setFeedbackId,
    setRatingModalTicket,
    setStep,
    showTicketRatingModal,
    showTicketRatingReminder,
    shuffledReasons,
    step,
    toggleFeedbackModalVisibility,
  }

  return <TicketFeedbackContext.Provider value={context}>{children}</TicketFeedbackContext.Provider>
}
