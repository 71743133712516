import { useMemo } from 'react'
import SelectBox, { SelectBoxOption } from 'lib/components/dropdown/select-box'
import Badge from 'lib/components/badge/badge'

function TicketVersionItem({ version, isLatestVersion }: { version: number; isLatestVersion: boolean }) {
  if (version === 0) return <span className="tw-block tw-truncate">All Versions</span>

  return (
    <>
      <span className="tw-truncate tw-flex tw-justify-between tw-pl-1 tw-w-full">
        <span>{`Version ${version}`}</span>
        {isLatestVersion && (
          <Badge style="pill" color="primary" className="tw-ml-3">
            Current
          </Badge>
        )}
      </span>
    </>
  )
}
interface TicketVersionSelectBoxProps {
  versions: number[]
  selectedVersion: number
  setSelectedVersion: (version: number) => void
}

export default function TicketVersionSelectBox({
  versions,
  selectedVersion,
  setSelectedVersion,
}: TicketVersionSelectBoxProps) {
  const latestVersion = Math.max(...versions)

  function handleChange(selectedValue: string) {
    setSelectedVersion(Number(selectedValue))
  }

  const options: SelectBoxOption[] = useMemo(() => {
    return versions.map((version: number) => ({
      value: version.toString(),
      displayElement: <TicketVersionItem version={version} isLatestVersion={version === latestVersion} />,
    }))
  }, [latestVersion, versions])

  return (
    <div>
      <SelectBox options={options} handleChange={handleChange} selectedValue={selectedVersion.toString()} />
    </div>
  )
}
