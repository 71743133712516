import { SavedSearch } from 'interfaces/saved-search'
import { SearchTicket } from 'interfaces/ticket'
import { TicketData } from './tickets/tickets'
import { Designer } from 'interfaces/designer'
import qs from 'query-string'

interface Endpoint {
  path: string
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'BLOB'
  baseUrl?: string
}

export const endpoints: Record<string, Endpoint> = {
  getSearch: { path: '/search', method: 'GET' },
  getTickets: { path: '/tickets', method: 'GET' },
  getSavedSearches: { path: '/saved_searches', method: 'GET' },
  createSavedSearch: { path: '/saved_searches', method: 'POST' },
  deleteSavedSearch: { path: '/saved_searches/:id', method: 'DELETE' },
  getCompanyDesigners: { path: '/company_designers', method: 'GET' },
}

interface Endpoints {
  getSearch: {
    data: SearchTicket[]
    meta: {
      nextPage: string
      pageSize: number
      previousPage: string
      total: number
    }
  }
  getTickets: TicketData
  getSavedSearches: { data: SavedSearch[] }
  createSavedSearch: Pick<SavedSearch, 'name' & 'parameters'>
  deleteSavedSearch: { id: string }
  getCompanyDesigners: { data: Designer[] }
}

interface Queries {
  getSearch: {
    query?: string
    page?: string
    sort_order?: string
    sort_key?: string
    page_size?: number
  }
  getTickets: {
    first_render?: string
    page: number
    sort_column?: string
    user_id?: number
  }
  getSavedSearches: null
  createSavedSearch: null
  deleteSavedSearch: { id: string }
  getCompanyDesigners: null
}

interface RequestOptions<T extends keyof Endpoints> extends Omit<RequestInit, 'body'> {
  body?: Endpoints[T]
}

interface RequestObject<T extends keyof Endpoints> {
  endpoint: keyof typeof endpoints & T
  query?: Queries[T]
  requestOptions?: Omit<RequestOptions<T>, 'body'>
  body?: Endpoints[T]
}

export const request = async <T extends keyof Endpoints>({
  endpoint,
  query,
  requestOptions,
  body,
}: RequestObject<T>): Promise<Endpoints[T]> => {
  const { path, method, baseUrl } = endpoints[endpoint]

  const url = getUrl(`${baseUrl || ''}/api/internal${path}`, query)

  const req = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...requestOptions?.headers,
    },
    ...requestOptions,
    body: body ? JSON.stringify(body) : undefined,
  })

  // Todo: standardize errors
  if (!req.ok) {
    throw new Error(`Request failed: ${req.statusText}`)
  }

  return req.json()
}

// Makes use-query snytax better
export const requestQuery =
  <T extends keyof Endpoints>(object: RequestObject<T>) =>
  () => {
    return request(object)
  }

export const getUrl = <T extends keyof Queries>(url: string, query: Queries[T]) => {
  if (!query) {
    return url
  }

  const newQuery = {}

  // Replace placeholders in the URL with the query parameters and encode them, any other query parameters are added as query string at the end of the URL
  const encodedPath = Object.entries(query).reduce((acc, [key, value]) => {
    if (acc.includes(`:${key}`)) {
      return acc.replace(`:${key}`, encodeURIComponent(value as string))
    }

    newQuery[key] = value
    return acc
  }, url)

  const queryString = decodeURIComponent(qs.stringify(newQuery))
  const fullUrl = queryString ? `${encodedPath}?${queryString}` : encodedPath

  return fullUrl
}
