import { ReactElement, useMemo, useState } from 'react'
import { generateRangeTo } from 'lib/util/range'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import { useRequestContext } from '../providers/request-provider'
import BannersList from '../banners/banners-list'
import DirectionInput from './directions-input'
import DirectionsList from './directions-list'
import DirectionsFlyoutMenu from './directions-flyout-menu'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'
import DirectionsHeader from 'components/pages/request/directions/directions-header'

const classNames = {
  directionsContainer: `tw-relative`,
  footer: {
    default: `tw-sticky tw-bottom-0 tw-px-5 tw-pb-5 tw-bg-white`,
    withDirections: `tw-pt-4`,
    withoutDirections: `tw-pt-0`,
  },
}

function DirectionsFooter(): ReactElement {
  const { directions } = useDirectionsContext()

  return (
    <footer
      data-testid="directions-footer"
      className={`${classNames.footer.default} ${
        directions?.length ? classNames.footer.withDirections : classNames.footer.withoutDirections
      }`}
    >
      <div className="tw-flex tw-gap-2 tw-items-start tw-w-full">
        <div className="tw-mt-3">
          <DirectionsFlyoutMenu />
        </div>
        <div className="tw-w-full">
          <DirectionInput placeholder="Start typing general direction" />
        </div>
      </div>
      <p className="tw--mt-8 tw-pt-2.5">Press enter for another line</p>
    </footer>
  )
}

export default function Directions(): ReactElement {
  const { showRevisions, ticket } = useRequestContext()
  const { canCreateDirections, directions, setFilters } = useDirectionsContext()
  const [selectedVersion, setSelectedVersion] = useState<number>(ticket?.lastDeliveredVersion || 1)

  const versions = useMemo(() => generateRangeTo(ticket?.lastDeliveredVersion), [ticket?.lastDeliveredVersion])

  const handleVersionChange = (version: number) => {
    setSelectedVersion(version)
    setFilters((previous) => ({ ...previous, version: Number(version) }))
  }

  return (
    <div className="tw-pt-6">
      <DirectionsHeader />
      <div className={classNames.directionsContainer}>
        {showRevisions && directions?.length > 0 && versions && (
          <TicketVersionSelectBox
            versions={versions}
            selectedVersion={selectedVersion}
            setSelectedVersion={handleVersionChange}
          />
        )}
        <BannersList />
        <DirectionsList />
        {canCreateDirections && <DirectionsFooter />}
      </div>
    </div>
  )
}
