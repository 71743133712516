import TableHead from 'components/core/table-head'
import { DroppableTableRow } from 'components/core/table-row'
import Button from 'components/core/button'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import { DroppableProvided } from 'react-beautiful-dnd'

interface TableHeaderProps {
  droppableProvided: DroppableProvided
}

export default function PriorityTableHeader({ droppableProvided }: TableHeaderProps) {
  const { setPriorityCancelModal, setPriorityConfirmModal } = useSingleQueueContext()

  return (
    <TableHead>
      <DroppableTableRow innerRef={droppableProvided.innerRef} droppableProvided={droppableProvided}>
        <th scope="col" className="tw-px-6 tw-text-left tw-text-xs tw-pr-0.5"></th>
        <th scope="col" colSpan={3} className="tw-text-left tw-text-xs tw-tracking-wider tw-pr-0.5">
          <div className="tw-align-middle tw-flex tw-justify-items-center">
            <div className=" tw-flex tw-items-center tw-float-left">
              <div className="tw-flex tw-items-center tw-px-6 tw-gap-4">
                <Button type={'button'} color={'lightGray'} size={'cut'} onClick={() => setPriorityCancelModal(true)}>
                  Cancel
                </Button>
                <Button type={'button'} color={'purple'} size={'cut'} onClick={() => setPriorityConfirmModal(true)}>
                  Save Priority
                </Button>
              </div>
            </div>
          </div>
        </th>
        <th
          scope="col"
          className="tw-pl-4 tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider tw-pr-0.5"
        >
          Status
        </th>
        <th
          scope="col"
          className="tw-pl-4 tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider tw-pr-0.5"
        >
          Request Type
        </th>
        <th
          scope="col"
          className="tw-pl-4 tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider tw-pr-0.5"
        >
          Designer
        </th>
        <th
          scope="col"
          className="tw-pl-4 tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider tw-pr-0.5"
        >
          Submitted By
        </th>
        <th
          scope="col"
          className="tw-pl-4 tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider tw-pr-0.5"
        >
          Last Updated
        </th>
      </DroppableTableRow>
    </TableHead>
  )
}
