import { ReactElement } from 'react'
import { getInitials } from 'lib/string/utils'

interface AvatarProps {
  src?: string
  name: string
}

export default function Avatar({ src, name }: AvatarProps): ReactElement {
  if (src) {
    return <img className="tw-h-8 tw-w-8 tw-rounded-full" src={src} alt={name} />
  } else if (name) {
    return (
      <div className="tw-flex tw-justify-center tw-h-8 tw-w-8 tw-rounded-full tw-font-semibold tw-text-xs tw-bg-gray-100 tw-text-center tw-align-middle tw-items-center">
        {getInitials(name)}
      </div>
    )
  }

  return (
    <img className="tw-h-8 tw-w-8 tw-rounded-full" src="/images/avatars/designer_photo_placeholder_125px.jpg" alt="" />
  )
}
