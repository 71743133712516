import { isValidHexColor } from 'lib/util/color'
import { FC, HTMLAttributes, useEffect, useState } from 'react'

interface ColorInputProps {
  id: string
  name: string
  className?: HTMLAttributes<HTMLInputElement>['className']
  defaultValue?: string
  value?: string
  error?: boolean
  onChange?: (color: string) => void
}

export const ColorInput: FC<ColorInputProps> = ({
  id,
  name,
  className,
  defaultValue,
  value,
  error,
  onChange = () => {
    return
  },
}) => {
  const [internalValue, setInternalValue] = useState(defaultValue ?? '#576EE7')

  useEffect(() => {
    if (value !== undefined) {
      setInternalValue(value)
    }
  }, [value])

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value
    setInternalValue(newColor)
    onChange?.(newColor)
  }

  const colorValue = value !== undefined ? value : internalValue

  return (
    <div className="tw-flex tw-items-center tw-relative">
      <div
        className="tw-w-6 tw-h-6 tw-absolute tw-top-2 tw-left-2 tw-rounded-md tw-pointer-events-none"
        style={{
          backgroundColor: isValidHexColor(colorValue) ? colorValue : '#576EE7',
        }}
      ></div>
      <input
        type="text"
        className={`tw-appearance-none tw-rounded-md tw-border tw-border-solid tw-h-10 tw-pl-10 tw-pr-2 tw-shadow-none tw-outline-none focus:tw-ring-2 tw-border-gray-300 focus:tw-ring-cornflower-500 ${className} ${
          error && 'tw-ring-2 tw-ring-flushpink-500'
        }`}
        id={id}
        name={name}
        placeholder="#000000"
        maxLength={7}
        value={colorValue}
        onChange={handleColorChange}
        data-testid="color_input"
      />
    </div>
  )
}
