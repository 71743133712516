import Select from 'react-select'
import { theme } from '../../../../../../tailwind.config'
import { QaReviewFiltersParams } from './qa-reviews-page'
import { getQaReviewStates } from 'lib/api/qa_reviews/qa_reviews'
import { humanize } from 'lib/string/utils'
import { useEffect, useState } from 'react'

interface QaReviewFiltersProps {
  filters: QaReviewFiltersParams
  setFilters: (object) => void
}

const { colors } = theme

const multiSelectStyles = {
  menuList: (provided) => ({
    ...provided,
    background: colors.white,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 100,
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? colors.gherkin : colors.white,
    opacity: state.isDisabled ? 0.5 : 1,
    color: state.isSelected ? colors.white : colors.black,
    cursor: state.isDisabled ? 'default' : 'pointer',
    '&:hover': {
      background: state.isDisabled ? colors.white : colors.cornflower[50],
      color: colors.black,
    },
  }),
  control: (styles) => ({
    ...styles,
    height: '38px',
    background: colors.white,
    cursor: 'pointer',
    borderColor: colors.peppercorn[100],
    boxShadow: colors.peppercorn[100],
    whiteSpace: 'nowrap',
    '&:hover': {
      borderColor: colors.peppercorn[100],
    },
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '12px', color: colors.peppercorn[600] }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: colors.peppercorn[100],
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: colors.peppercorn[800],
    fontSize: '12px',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: colors.peppercorn[800],
    ':hover': {
      backgroundColor: colors.peppercorn[800],
      color: colors.white,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
  }),
}

export default function QaReviewsStatesFilter({ filters, setFilters }: QaReviewFiltersProps) {
  const [qaReviewStates, setQaReviewStates] = useState([])
  const classNames = {
    select: 'tw-text-xs tw-my-2 tw-w-full',
  }

  useEffect(() => {
    fetchQaReviewStates()
  }, [])

  async function fetchQaReviewStates() {
    try {
      const { states } = await getQaReviewStates()
      setQaReviewStates(Object.entries(states).map(([label, value]) => ({ value, label: humanize(label) })))
    } catch (error) {
      console.error('Error fetching QA review states', error)
    }
  }

  return (
    <Select
      components={{ IndicatorSeparator: null }}
      className={classNames.select}
      value={
        filters.stateIn.length > 0
          ? filters.stateIn.map((state) => qaReviewStates.find(({ value }) => value === state))
          : []
      }
      placeholder={'State'}
      options={qaReviewStates}
      onChange={(e) => setFilters({ ...filters, stateIn: e.map((state) => state.value) })}
      styles={multiSelectStyles}
      isClearable
      isMulti
    />
  )
}
