import { Announcement, getAnnouncements } from 'lib/api/announcements/announcements'
import { ReactElement, useEffect, useState } from 'react'
import { Bell } from 'lucide-react'

const classNames = {
  wrapper: 'tw-mt-16 tw--mb-12',
  item: 'tw-flex tw-p-5 tw-mb-4 tw-bg-white tw-border-0 tw-border-t-4 tw-border-squash-400 tw-border-solid',
  icon: 'tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-12 tw-h-12 tw-rounded-full tw-bg-blue-100 tw-mr-3 tw-bg-squash-400 tw-rounded',
  iconSvg: 'tw-text-white',
  message: 'tw-flex-1 tw-pl-1 tw-mr-16',
  heading: 'tw-font-semibold tw-text-lg tw-text-squash-400',
  body: 'tw-text-sm',
}

export default function Announcements(): ReactElement {
  const [announcements, setAnnouncements] = useState([])

  useEffect(() => {
    getAnnouncements()
      .then((response) => {
        setAnnouncements(response.announcements)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  if (announcements.length === 0) return <div data-testid="empty-announcements" />

  return (
    <div className={classNames.wrapper}>
      {announcements.map((announcement: Announcement) => (
        <div key={announcement.id} className={classNames.item}>
          <div className={classNames.icon}>
            <Bell className={classNames.iconSvg} />
          </div>
          <div className={classNames.message}>
            <div className={classNames.heading}>{announcement.heading}</div>
            <div className={classNames.body} dangerouslySetInnerHTML={{ __html: announcement.body }}></div>
          </div>
        </div>
      ))}
    </div>
  )
}
