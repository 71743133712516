import { ReactElement } from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'

const classNames = {
  button: 'btn btn-icon tw-absolute tw-px-0 tw-cursor-pointer',
  chevron: 'tw-h-6 tw-w-6',
  container: 'tw-absolute tw-right-0 tw-top-1/2 tw-z-20',
  open: 'tw-bg-gray-50 tw-rounded-l-none tw-rounded-r-md tw-border-1 tw-border-l-0 tw-border-gray-200',
}
export default function OpenButton({ onClick }: { onClick: () => void }): ReactElement {
  return (
    <div className={classNames.container}>
      <button
        type="button"
        onClick={onClick}
        className={`${classNames.button} ${classNames.open}`}
        style={{ left: '-1px' }}
      >
        <ChevronRightIcon className={classNames.chevron} />
      </button>
    </div>
  )
}
