import { FileChartColumn, LucideIcon, Palette, Share2, Ticket, User, Users, Wrench } from 'lucide-react'

export interface MenuItem {
  label: string
  path?: string
  Icon?: LucideIcon | React.FC
  RightIcon?: LucideIcon
  submenu?: MenuItem[]
}

export const mainItems: MenuItem[] = [
  {
    label: 'Requests',
    Icon: Ticket,
  },
  {
    label: 'Brands',
    Icon: Palette,
  },
  {
    label: 'White Label',
    Icon: Share2,
  },
]

const toolsAdminItem: MenuItem = {
  label: 'Admin',
  submenu: [
    {
      label: 'Internship Bot',
    },
    {
      label: 'Tags',
    },
    {
      label: 'Wiki',
    },
  ],
}

const toolsAutoAssignerItem: MenuItem = {
  label: 'Auto Assigner',
  submenu: [
    {
      label: 'Pro Subscriptions DPU',
    },
    {
      label: 'Graphics Subscriptions DPU',
    },
    {
      label: 'Company Designer Allocations',
    },
    {
      label: 'Blocklisted Designers',
    },
    {
      label: 'Time Zones',
    },
  ],
}

const toolsFreshStockItem: MenuItem = {
  label: 'FreshStock',
  submenu: [
    {
      label: 'FreshStock Assets',
    },
  ],
}

const toolsCustomerSuccessItem: MenuItem = {
  label: 'Customer Success',
  submenu: [
    {
      label: 'Announcements',
    },
    {
      label: 'Canned Responses',
    },
    {
      label: 'Coupons',
    },
    {
      label: 'Emails',
    },
    {
      label: 'Plans',
    },
  ],
}

const toolsProductionItem: MenuItem = {
  label: 'Production',
  submenu: [
    {
      label: 'Feedback',
    },
    {
      label: 'Work Availability Calendar',
    },
    {
      label: 'Request Type Categories',
    },
    {
      label: 'Request Types',
    },
    {
      label: 'Formats',
    },
  ],
}

const toolsSalesMarketingItem: MenuItem = {
  label: 'Sales & Marketing',
  submenu: [
    {
      label: 'New Onboarding',
    },
  ],
}

export const adminItems: MenuItem[] = [
  {
    label: 'Tickets',
    Icon: Ticket,
    submenu: [
      {
        label: 'All Tickets',
      },
      {
        label: 'Assigned to me',
      },
      {
        label: '3CV (Old)',
      },
    ],
  },
  {
    label: 'People',
    Icon: User,
    submenu: [
      {
        label: 'Companies',
      },
      {
        label: 'Customers',
      },
    ],
  },
  {
    label: 'Teams',
    Icon: Users,
    submenu: [
      {
        label: 'APMs',
      },
      {
        label: 'Designers',
      },
      {
        label: 'Quality Specialists',
      },
      {
        label: 'TAGs',
      },
      {
        label: 'Team Dashboard',
      },
      {
        label: 'Teams',
      },
    ],
  },
  {
    label: 'Reports',
    Icon: FileChartColumn,
    submenu: [
      {
        label: 'Advanced Analytics',
      },
      {
        label: 'At A Glance',
      },
      {
        label: 'Attendance',
      },
      {
        label: 'Cancellations',
      },
      {
        label: 'Conversations',
      },
      {
        label: 'Quality Reviews',
      },
      {
        label: 'Designer Sentiment',
      },
      {
        label: 'Designer Records',
      },
      {
        label: 'Availability Schedule',
      },
      {
        label: 'Requests',
      },
      {
        label: 'Abandoned Requests',
      },
      {
        label: 'Ticket Feedback',
      },
    ],
  },
  {
    label: 'Tools',
    Icon: Wrench,
    submenu: [
      toolsAdminItem,
      toolsAutoAssignerItem,
      toolsFreshStockItem,
      toolsCustomerSuccessItem,
      toolsProductionItem,
      toolsSalesMarketingItem,
    ],
  },
]
