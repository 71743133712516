interface QrTeamsPreviewProps {
  qrTeamName?: string
  fullName?: string
  apmName?: string
}

interface QrTeamsPreviewItemProps {
  title?: string
  label?: string
}

export const QrTeamsPreviewItem = ({ title, label }: QrTeamsPreviewItemProps): JSX.Element => {
  return (
    <div data-testid="qrTeamsPreviewItem">
      <span className="tw-text-peppercorn tw-inline-block tw-mb-2">{label}</span>
      <p className="tw-border-solid tw-border-0 tw-border-b tw-border-gherkin">
        <strong>{title}</strong>
      </p>
    </div>
  )
}

export default function QrTeamsPreview({ qrTeamName, fullName, apmName }: QrTeamsPreviewProps): JSX.Element {
  return (
    <div className="tw-mb-8" data-testid="qrTeamsPreview">
      <h2 className="tw-text-peppercorn-800 tw-text-lg tw-font-semibold tw-mt-3 tw-mb-12">Team Settings</h2>

      {qrTeamName && <QrTeamsPreviewItem title={qrTeamName} label="Team Name" />}
      {fullName && <QrTeamsPreviewItem title={fullName} label="Team Leader" />}
      {apmName && <QrTeamsPreviewItem title={apmName} label="APM" />}
    </div>
  )
}
