import Button from '../core/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'

export default function TicketError(): ReactElement {
  return (
    <div className="tw-text-center">
      <img src="/images/states/none-exist.svg" alt="Ticket Loading Error" className="tw-block" />
      <div className="tw-font-bold tw-mb-2">Sorry, the options didn&apos;t load.</div>
      <div className="tw-mb-4">
        You can retry or head back to <a href="/requests">your queue</a>.
      </div>
      <Button
        rounded
        color="purple"
        onClick={(evt) => {
          evt.stopPropagation()
          window.location.reload()
        }}
      >
        <FontAwesomeIcon icon={['fal', 'sync-alt']} />
        <span className="tw-mx-1">Retry</span>
      </Button>
    </div>
  )
}
