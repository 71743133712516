import { Toaster as SonnerToaster, toast as SonnerToast } from 'sonner'
import { cn } from 'lib/util/cn'
import { X } from 'lucide-react'

const styles = {
  toast: `
    tw-py-2
    tw-px-6
    tw-w-80
    tw-min-h-12
    tw-box-border
    tw-rounded-lg
    tw-relative
    tw-border-2
    tw-text-neutral-900
    tw-flex
    tw-gap-2
    tw-flex-row
    tw-items-center
    tw-justify-between
    tw-drop-shadow-md
  `,
  icon: 'tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-drop-shadow-sm tw-h-4 tw-w-4',
  notice: 'tw-bg-picklegreen-200 tw-border-picklegreen-100 tw-text-picklegreen-800',
  alert: 'tw-bg-red-100 tw-border-red-200 tw-text-red-900',
}

const showToast = (message: string, className: string) =>
  SonnerToast.custom(
    (t) => (
      <div className={cn(styles.toast, className)} role="alert">
        <p className="tw-m-0 tw-text-sm tw-font-normal">{message}</p>
        <span className={styles.icon} onClick={() => SonnerToast.dismiss(t)}>
          <X />
        </span>
      </div>
    ),
    { position: 'bottom-right' }
  )

const success = (message: string) => showToast(message, styles.notice)
const error = (message: string) => showToast(message, styles.alert)

export const toast = {
  success,
  error,
}

export const Toaster = () => <SonnerToaster expand richColors closeButton />
