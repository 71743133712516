import { bindJWT, deleteApi, getApi, Metadata, patchApi, postApi } from 'lib/api/api'
import { snakeCaseKeys } from 'lib/object/utils'
import { Annotation } from '../../annotations/annotations'
import { updateDetailTask } from 'lib/api/detail-tasks/detail-tasks-api'

export enum AdminTaskType {
  Conversation = 'conversation',
  Annotation = 'annotation',
  Direction = 'direction',
  Copy = 'copy',
  GenAiRequest = 'gen_ai_request',
}

export enum AdminTimelineFilter {
  Notes = 'notes',
  Conversations = 'conversations',
  all = 'all',
}

export interface AdminTimelineItem {
  annotation?: Annotation
  completed: boolean
  id: number
  body: string
  draft: boolean
  createdAt: string
  incoming: boolean
  meta: {
    permissions: {
      update: boolean
      destroy: boolean
      sendToIntercom: boolean
    }
  }
  private: boolean
  taskType: AdminTaskType
  ticketId: number
  ticketVersion: number
  updatedAt: string
  user: {
    id: number
    fullName: string
    avatar: string
    submittedByDesigner: boolean
  }
}

const baseUrl = '/api/internal/admin/tickets'

export interface AdminTimelineFilters {
  ticketVersion: number
  directionType?: AdminTaskType
}

export interface GetAdminTimelineResponse {
  data: AdminTimelineItem[]
  meta: Partial<Metadata>
}

export const PAGE_SIZE = 50

function getNextPageUrlFromResponse(response: AdminTimelineItem[], ticketId: number, filterParams: string): string {
  if (response.length === PAGE_SIZE && response.length > 0) {
    const { createdAt } = response[0]
    return `${baseUrl}/${ticketId}/directions?last_created_at=${createdAt}&${filterParams}`
  }
  return null
}

const getFilterParams = (filters: AdminTimelineFilters) => {
  if (!filters) return null

  if (filters?.ticketVersion === 0) {
    const newFilters = Object.assign({}, filters)
    delete newFilters.ticketVersion
    return new URLSearchParams(snakeCaseKeys(newFilters))?.toString()
  }

  return new URLSearchParams(snakeCaseKeys(filters))?.toString()
}

export async function getTimelineItems(
  ticketId: number,
  filters: AdminTimelineFilters = null,
  nextPageUrl?: string,
  reverseSort: boolean = true,
  jwt: string = null
): Promise<GetAdminTimelineResponse> {
  const getWithJWT = bindJWT(getApi, jwt)
  const filterParams = getFilterParams(filters)
  const url = filterParams ? `${baseUrl}/${ticketId}/directions?${filterParams}` : `${baseUrl}/${ticketId}/directions`
  const response = await getWithJWT(nextPageUrl || url)
  const data: AdminTimelineItem[] = reverseSort ? response.data.data.reverse() : response.data.data
  const nextPage = getNextPageUrlFromResponse(data, ticketId, filterParams)

  return {
    data: response.data.data,
    meta: { nextPage },
  }
}

enum TimelineItemEndpointUrls {
  'direction' = '/api/internal/detail_tasks/:id',
  'conversation' = '/api/internal/admin/tickets/:ticket_id/conversations/:id',
  'annotation' = '/api/internal/annotations/:id',
  'toggle_completed' = '/api/internal/admin/tickets/:ticket_id/detail_tasks/:id/toggle_complete',
}
export interface AdminUpdateTimelineItemParams {
  ticketId: number
  item: AdminTimelineItem
  body: string
  draft?: boolean
  jwt?: string
}

export async function updateTimelineItem({
  ticketId,
  item,
  body,
  draft,
  jwt = null,
}: AdminUpdateTimelineItemParams): Promise<AdminTimelineItem> {
  const url = TimelineItemEndpointUrls[item.taskType]
    .replace(':ticket_id', ticketId.toString())
    .replace(':id', item.id.toString())

  const patchWithJWT = bindJWT(patchApi, jwt)

  if (item.taskType === AdminTaskType.Direction) {
    const response = await updateDetailTask(item.id, body)
    return {
      ...item,
      body: response.description,
    } as AdminTimelineItem
  }

  const patchBody = { body } as Record<string, unknown>
  if (draft !== undefined) {
    patchBody.draft = draft
  }

  const response = await patchWithJWT(url, patchBody)
  return response.data.data as AdminTimelineItem
}

export async function deleteTimelineItem(ticketId: number, item: AdminTimelineItem, jwt: string = null): Promise<void> {
  const url = TimelineItemEndpointUrls[item.taskType]
    .replace(':ticket_id', ticketId.toString())
    .replace(':id', item.id.toString())

  const deleteWithJWT = bindJWT(deleteApi, jwt)
  await deleteWithJWT(url)
}

export async function toggleCompletedStatus(
  ticketId: number,
  item: AdminTimelineItem,
  jwt: string = null
): Promise<AdminTimelineItem> {
  const url = TimelineItemEndpointUrls['toggle_completed']
    .replace(':ticket_id', ticketId.toString())
    .replace(':id', item.id.toString())

  const patchWithJWT = bindJWT(patchApi, jwt)
  const response = await patchWithJWT(`${url}`)
  return response.data.data as AdminTimelineItem
}

export async function createDetailTask(
  ticketId: number,
  description: string,
  isPrivate: boolean,
  jwt: string = null
): Promise<AdminTimelineItem> {
  const postWithJWT = bindJWT(postApi, jwt)
  const response = await postWithJWT(`/api/internal/tickets/${ticketId}/detail_tasks`, {
    description,
    private: isPrivate,
  })
  return response.data.detailTask as AdminTimelineItem
}

export async function areAllTasksComplete(ticketId: number, jwt: string = null): Promise<boolean> {
  const getWithJWT = bindJWT(getApi, jwt)
  const response = await getWithJWT(`${baseUrl}/${ticketId}/directions/are_all_completed`)
  return response.data.data.areAllCompleted as boolean
}
