import { ReactElement, useEffect, useRef, useState } from 'react'
import { ClipboardList, Info } from 'lucide-react'
import IconButton from 'lib/components/buttons/icon-button'
import { useUserContext } from 'providers/user-provider'
import Timeline from '../timeline/timeline'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import { RevisionsViews, useRequestContext } from '../providers/request-provider'
import RequestDetailsText from '../request-details-text/request-details-text'
import DeprecatedRightPanel from './deprecated-right-panel'

interface CurrentViewProps {
  view: RevisionsViews
}

const classNames = {
  scrollable: 'tw-flex-auto tw-overflow-y-auto',
  stationary: 'tw-flex-auto',
  iconContainer: `tw-flex
    tw-flex-col
    tw-border-0
    tw-border-r
    tw-border-solid
    tw-border-gray-200
    tw-p-3
    tw-gap-2
    tw-h-full
    tw-fixed
  `,
  contentContainer: 'tw-px-5 tw-w-full',
}

function CurrentView({ view }: CurrentViewProps): ReactElement {
  const [mountedViews, setMountedViews] = useState<RevisionsViews[]>([])

  const endOfTimelineRef = useRef<HTMLDivElement>(null)

  function scrollTimeline(behaviorIsSmooth = false) {
    endOfTimelineRef.current?.scrollIntoView({ behavior: behaviorIsSmooth ? 'smooth' : 'auto' })
  }

  useEffect(() => {
    setMountedViews((previous) => {
      if (!previous.includes(view)) {
        return [...previous, view]
      } else {
        return previous
      }
    })

    if (view === 'timeline') {
      scrollTimeline()
    }
  }, [view])

  return (
    <>
      <div className={view == 'timeline' ? 'tw-block' : 'tw-hidden'}>
        {mountedViews.includes('timeline') && <Timeline onLoad={scrollTimeline} />}
        <div ref={endOfTimelineRef}>{` ` /* Target needs to have a footprint */}</div>
      </div>
      <div className={view == 'request-details' ? 'tw-block' : 'tw-hidden'}>
        {mountedViews.includes('request-details') && <RequestDetailsText />}
      </div>
    </>
  )
}

function RevisionsSidebarContent(): ReactElement {
  const { showDirectionErrors } = useDirectionsContext()
  const { showRevisions, currentView, setCurrentView } = useRequestContext()
  const { areAnnotationsValid, highlightedAnnotation, showAnnotationCreator } = useAnnotationsContext()

  function timelineIconColor() {
    if (showDirectionErrors && !areAnnotationsValid()) {
      return 'danger'
    } else if (currentView === 'timeline') {
      return 'primary'
    } else {
      return 'secondary'
    }
  }

  function updateCurrentView(view: RevisionsViews) {
    setCurrentView(view)
  }

  useEffect(() => {
    if (highlightedAnnotation || showAnnotationCreator) {
      setCurrentView('timeline')
    }
  }, [highlightedAnnotation, showAnnotationCreator])

  return (
    <div className="tw-flex tw-h-full tw-relative">
      <div className={classNames.iconContainer}>
        <IconButton
          color={timelineIconColor()}
          invert={currentView === 'timeline' || showDirectionErrors}
          selected={currentView === 'timeline'}
          onClick={() => updateCurrentView('timeline')}
          dataTestid="timeline-button"
        >
          <ClipboardList className="lu-light lu-md" />
        </IconButton>
        {showRevisions && (
          <IconButton
            color={currentView === 'request-details' ? 'primary' : 'secondary'}
            invert={currentView === 'request-details'}
            selected={currentView === 'request-details'}
            onClick={() => updateCurrentView('request-details')}
            data-testid="request-details-button"
          >
            <Info className="lu-light lu-md" />
          </IconButton>
        )}
      </div>
      <div className={`${classNames.contentContainer} tw-pl-20`}>
        <CurrentView view={currentView} />
      </div>
    </div>
  )
}

export default function RightPanel(): ReactElement {
  const { user } = useUserContext()
  if (!user.merging) {
    return <DeprecatedRightPanel />
  }

  return (
    <div className={classNames.scrollable}>
      <RevisionsSidebarContent />
    </div>
  )
}
