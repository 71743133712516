import { useMutation, useQueryClient } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { useState } from 'react'
import Button, { LinkLikeButton } from 'components/core/button'
import TextInput from 'components/core/text-input/text-input'
import Modal from 'lib/components/modals/modal'
import { toast } from 'lib/components/toast/toast'

const SaveSearch = () => {
  const [newSearchName, setNewSearchName] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const queryClient = useQueryClient()

  const createMutation = useMutation({
    mutationFn: () => {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.delete('page')
      const parameters = Object.fromEntries(queryParams.entries())
      return request({
        endpoint: 'createSavedSearch',
        body: {
          name: newSearchName,
          parameters,
        },
      })
    },
    onSuccess: () => {
      toast.success('Search has been saved')
      setIsModalOpen(false)
      setNewSearchName('')
      queryClient.invalidateQueries({ queryKey: ['savedSearches'] })
    },
    onError: () => toast.error('Search failed to save'),
  })

  return (
    <>
      <LinkLikeButton onClick={() => setIsModalOpen(true)}>Save search</LinkLikeButton>
      <Modal open={isModalOpen} setOpen={setIsModalOpen}>
        <Modal.Header>Create Saved Search</Modal.Header>
        <TextInput
          className="tw-w-full"
          placeholder="Name your search"
          name="name"
          value={newSearchName}
          onChange={(e) => setNewSearchName(e.target.value)}
        />
        <div className="tw-flex tw-gap-2 tw-items-center tw-justify-end tw-w-full tw-mt-4">
          <Button color="lightGray" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button color="purple" onClick={() => createMutation.mutate()} disabled={!newSearchName}>
            Save
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default SaveSearch
