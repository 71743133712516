import { createSkillCategory, routes } from 'lib/api/admin/skill-categories/skill-categories'
import SelectBox from 'lib/components/dropdown/select-box'
import TextInput from 'lib/components/text-input/text-input'
import Button from 'components/core/button'
import { useState } from 'react'
import { snakeCaseKeys } from 'lib/object/utils'
import { Toaster, toast } from 'lib/components/toast/toast'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  formWrapper: 'tw-max-w-xs',
  option: 'tw-mx-1 tw-flex-grow tw-text-center',
  select: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-min-h-screen',
}

interface SkillCategoriesCreatePageProps {
  subscriptionTypes: string[][]
}

function AdminSkillCategoriesEdit({ subscriptionTypes }: SkillCategoriesCreatePageProps) {
  const [name, setName] = useState<string>('')
  const [subscriptionType, setSubscriptionType] = useState<string>(subscriptionTypes[0][1])
  const subscriptionTypesOptions = subscriptionTypes.map((subscriptionType) => ({
    value: subscriptionType[1],
    displayElement: <SubscriptionTypeOption subscriptionType={subscriptionType[0]} />,
  }))

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const params = snakeCaseKeys({ name, subscriptionType })

    try {
      const { status } = await createSkillCategory(params)
      if (status === 201) {
        toast.success('Skill Category created successfully')
      }
    } catch (error) {
      console.error(error)
      toast.error('Failed to create skill category')
    } finally {
      window.open(routes.index, '_self')
    }
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <a href={routes.index} className="tw-text-gherkin-500 tw-text-sm tw-mb-4 tw-block">
          Back to Skill Categories
        </a>
        <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">New Skill Category</h1>
        <div className={classNames.formWrapper}>
          <form data-testid="edit-form" onSubmit={handleSubmitForm}>
            <TextInput
              data-testid="name"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              name="name"
              className="tw-w-full"
            />
            <div className={classNames.select}>
              <SelectBox
                data-testid="subcription-type"
                label="Subscription Type"
                // @ts-ignore
                handleChange={setSubscriptionType}
                selectedValue={subscriptionType}
                options={subscriptionTypesOptions}
                className="tw-w-full tw-mb-4"
              />
            </div>
            <Button data-testid="submit-button" color="green" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

function SubscriptionTypeOption({ subscriptionType }: { subscriptionType: string }) {
  return <div className={classNames.option}>{subscriptionType}</div>
}

export default function AdminSkillCategoriesCreatePage(props: SkillCategoriesCreatePageProps) {
  return (
    <>
      <Toaster />
      <AdminSkillCategoriesEdit {...props} />
    </>
  )
}
