import React, { ReactNode } from 'react'
import Checkbox from '../core/checkbox'

interface BulkActionsBarProps {
  totalSelected: number
  children: ReactNode
  bulkAllToggle: (e?: unknown) => void
}

export default function BulkActionsBar({ totalSelected, children, bulkAllToggle }: BulkActionsBarProps): JSX.Element {
  return (
    <div className="tw-align-middle tw-justify-items-center tw-flow-root">
      <div className=" tw-flex tw-items-center tw-float-left">
        <Checkbox checked={totalSelected > 1} label={''} onChange={bulkAllToggle} />
        <div className="tw-flex tw-items-center tw-px-6">{children}</div>
      </div>

      <div className="tw-flex tw-items-center tw-align-middle tw-text-xs tw-text-peppercorn-700 tw-float-right tw-mr-4 tw-py-2 tw-font-normal">
        {totalSelected} Tickets Selected
      </div>
    </div>
  )
}
