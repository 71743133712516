import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'

import { Annotation } from 'lib/api/annotations/annotations'
import { AnnotoriousAnnotation, AnnotoriousInstance } from 'lib/components/annotation/annotorious-openseadragon-types'

import { useAdminMediaContext } from './admin-media-provider'
import { useAdminTimelineContext } from './admin-timeline-provider'
import VideoPlayer from 'lib/components/video/video-player'
import { VideoJSAnnotationObject } from 'lib/api/ticket-files/ticket-files'

interface AdminAnnotationHighlightContextProps {
  children: ReactNode
}

export type AdminAnnotationHighlightContextValue = {
  clickedAnnotation: Annotation
  setClickedAnnotation: (annotation: Annotation, ticketVersion: number) => void
  setClickedAnnotationByAnnotoriousAnnotation: (annotation: AnnotoriousAnnotation) => void
  setClickedAnnotationByVideoAnnoation: (annotation: VideoJSAnnotationObject, ticketVersion: number) => void
  setAnnotorious: Dispatch<SetStateAction<AnnotoriousInstance>>
  setVideoPlayer: Dispatch<SetStateAction<VideoPlayer>>
  setHoveredAnnotation: (annotation: Annotation) => void
  hoveredAnnotation: Annotation
}

const AdminAnnotationHighlightContext = createContext({})

export function useAdminAnnotationHighlightContext(): AdminAnnotationHighlightContextValue {
  return useContext(AdminAnnotationHighlightContext) as AdminAnnotationHighlightContextValue
}

export default function AdminAnnotationHighlightProvider({ children }: AdminAnnotationHighlightContextProps) {
  const [clickedAnnotation, setClickedAnnotation] = useState(null)
  const [hoveredAnnotation, setHoveredAnnotation] = useState(null)
  const [annotorious, setAnnotorious] = useState<AnnotoriousInstance>(null)
  const [videoPlayer, setVideoPlayer] = useState<VideoPlayer>(null)

  const { currentSelectedPage, findAndSelectFileFromAnnotation } = useAdminMediaContext()
  const { findAnnotation } = useAdminTimelineContext()

  function handleImageAnnotationHover(annotation: Annotation) {
    if (annotation && currentSelectedPage?.annotations?.some((a) => a.id === annotation.id)) {
      selectAnnotoriousAnnotation(annotation)
    } else if (!clickedAnnotation) {
      cancelSelectedAnnotation()
    } else if (!annotation && clickedAnnotation) {
      selectAnnotoriousAnnotation(clickedAnnotation)
    } else if (annotation.id !== clickedAnnotation?.id) {
      cancelSelectedAnnotation()
    }
  }

  function handleVideoAnnotationHover(annotation: Annotation) {
    if (annotation && annotation.id !== clickedAnnotation?.id) {
      videoPlayer.select(annotation.uuid)
    } else {
      cancelSelectedAnnotation()
    }
  }

  function handleSetHoveredAnnotation(annotation: Annotation) {
    if (annotorious) {
      handleImageAnnotationHover(annotation)
    } else if (videoPlayer) {
      handleVideoAnnotationHover(annotation)
    }

    setHoveredAnnotation(annotation)
  }

  async function handleSetClickedAnnotation(annotation: Annotation, ticketVersion: number) {
    if (clickedAnnotation?.id === annotation?.id) {
      setClickedAnnotation(null)
      cancelSelectedAnnotation()
      return
    }

    const allAnnotations = currentSelectedPage?.isExtractable
      ? currentSelectedPage.extractedPages.flatMap((page) => page.annotations)
      : currentSelectedPage?.annotations || []
    const isAnnotationFileSelected = allAnnotations.some((a) => a.id === annotation.id)

    if (!isAnnotationFileSelected) {
      await findAndSelectFileFromAnnotation(annotation, ticketVersion)
    }

    setClickedAnnotation(annotation || null)
  }

  async function setClickedAnnotationByAnnotoriousAnnotation(annotation: AnnotoriousAnnotation) {
    const foundAnnotation = await findAnnotation(annotation.dbId, annotation.ticketVersion)

    if (foundAnnotation) {
      selectAnnotoriousAnnotation(foundAnnotation)
      setClickedAnnotation(foundAnnotation)
      const el = document.getElementById(`timeline-item-${foundAnnotation.id}`)
      el.scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'center' })
    }
  }

  async function setClickedAnnotationByVideoAnnoation(annotation: VideoJSAnnotationObject, ticketVersion: number) {
    const foundAnnotation = await findAnnotation(annotation.dbId, ticketVersion)

    if (foundAnnotation) {
      setClickedAnnotation(foundAnnotation)
      const el = document.getElementById(`timeline-item-${foundAnnotation.id}`)
      el.scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'center' })
    }
  }

  const cancelSelectedAnnotation = useCallback(() => {
    if (annotorious) {
      annotorious.cancelSelected()
    } else if (videoPlayer) {
      videoPlayer.cancel()
    }
  }, [annotorious, videoPlayer])

  const selectAnnotoriousAnnotation = useCallback(
    (annotation: Annotation) => {
      if (annotorious && annotation) {
        annotorious.selectAnnotation(annotation.uuid)
      } else {
        cancelSelectedAnnotation()
      }
    },
    [annotorious, cancelSelectedAnnotation]
  )

  useEffect(() => {
    if (clickedAnnotation && !hoveredAnnotation) {
      if (clickedAnnotation.data.type === 'Annotation' && annotorious?.getSelected()?.id !== clickedAnnotation.uuid) {
        selectAnnotoriousAnnotation(clickedAnnotation)
      } else if (videoPlayer) {
        videoPlayer.select(clickedAnnotation.uuid)
      }
    }
  }, [annotorious, clickedAnnotation, hoveredAnnotation, selectAnnotoriousAnnotation, videoPlayer])

  useEffect(() => {
    function clickHandler(e: MouseEvent) {
      const closestTimelineItem = (e.target as Element).closest('.timeline-item')

      if (!closestTimelineItem) {
        setClickedAnnotation(null)
        setHoveredAnnotation(null)
        cancelSelectedAnnotation()
      }
    }

    if (clickedAnnotation) {
      document.addEventListener('click', clickHandler)
    } else {
      document.removeEventListener('click', clickHandler)
    }

    return () => {
      document.removeEventListener('click', clickHandler)
    }
  }, [annotorious, cancelSelectedAnnotation, clickedAnnotation, videoPlayer])

  const contextValue: AdminAnnotationHighlightContextValue = {
    hoveredAnnotation,
    setHoveredAnnotation: handleSetHoveredAnnotation,
    clickedAnnotation,
    setAnnotorious,
    setClickedAnnotation: handleSetClickedAnnotation,
    setClickedAnnotationByAnnotoriousAnnotation,
    setClickedAnnotationByVideoAnnoation,
    setVideoPlayer,
  }

  return (
    <AdminAnnotationHighlightContext.Provider value={contextValue}>{children}</AdminAnnotationHighlightContext.Provider>
  )
}
