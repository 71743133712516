import { ReactElement, useState, useEffect, useCallback } from 'react'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import Pagination from 'components/elements/pagination'
import { getBlocklists, routes } from 'lib/api/admin/blocklists/blocklists'
import { snakeCaseKeys } from 'lib/object/utils'
import BlocklistsFilters from './blocklists-filters'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  header: 'tw-flex tw-justify-between navbar-custom',
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

export interface Filters {
  nameOrEmailCont?: string
}

export const emptyFilters: Filters = {
  nameOrEmailCont: '',
}

export default function BlocklistsPage(): ReactElement {
  const [companies, setCompanies] = useState([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [filters, setFilters] = useState<Filters>(emptyFilters)

  useEffect(() => {
    fetchBlocklists(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const fetchBlocklists = useCallback(
    async (filters: Filters) => {
      try {
        setIsLoading(true)
        const { blocklistedCompanies, meta } = await getBlocklists({ page: currentPage + 1, q: snakeCaseKeys(filters) })
        setCompanies(blocklistedCompanies)
        setPageCount(Math.ceil(meta.total / meta.pageSize))
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    [currentPage]
  )

  function onPageClick({ selected }) {
    setCurrentPage(selected)
    window.scrollTo(0, 0)
  }

  function handleSubmit(e) {
    e.preventDefault()
    fetchBlocklists(filters)
  }

  function handleClear(e) {
    e.preventDefault()
    setFilters(emptyFilters)
    fetchBlocklists(emptyFilters)
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <h4>Companies</h4>
      </div>
      <div className={classNames.container}>
        <BlocklistsFilters
          filters={filters}
          handleClear={handleClear}
          onSubmit={handleSubmit}
          setFilters={setFilters}
        />
        <div className={classNames.tableWrapper}>
          <table className={classNames.table}>
            <thead className={classNames.tableHead}>
              <tr>
                <th className={classNames.tableHeadCell}>Company</th>
                <th className={classNames.tableHeadCell}>Email</th>
                <th className={classNames.tableHeadCell}>View</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <LoadingScreenTableRow />
              ) : (
                companies.map(({ id, name, email }) => (
                  <tr key={id} className={classNames.tableRow}>
                    <td className={classNames.tableCell}>{name}</td>
                    <td className={classNames.tableCell}>{email}</td>
                    <td className={classNames.tableCell}>
                      <a href={routes.show(id)}>View</a>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination pageCount={pageCount} onPageChange={onPageClick} forcePage={currentPage} />
      </div>
    </div>
  )
}
