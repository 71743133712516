import { ReactElement, useEffect, useRef, useState } from 'react'

import { DesignRequest } from 'lib/api/tickets/tickets'
import TicketError from 'components/elements/ticket-error'

import CollaboratorTicketProvider, { useCollaboratorTicketContext } from './collaborator-ticket-provider'
import MediaProvider, { useMediaContext } from '../request/media/media-provider'
import LeftSidebar from './left-sidebar'
import MediaContainer from '../request/media/media-container'
import AnnotationsProvider from '../request/providers/annotations-provider'
import AnnotationsListContainer from '../request/annotations/annotations-list-container'
import { AnimatedLoadingScreen } from '../requests/empty-screens'
import CollaboratorTicketHeader from './header/collaborator-ticket-header'
import CollaborationUnavailable from './collaboration-unavailable'
import { moveIntercom } from 'lib/util/intercom'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'

const classNames = {
  container: 'tw-h-screen tw-w-screen tw-grid tw-grid-cols-1 tw-grid-rows-2 tw-bg-white',
  main: 'tw-flex tw-flex-row tw-overflow-hidden tw-border-0 tw-border-t tw-border-solid tw-border-gray-200',
}

function CollaboratorTicketContent() {
  const [showMainContent, setShowMainContent] = useState(false)
  const [showMainOverflow, setShowMainOverflow] = useState(false)
  const { selectedFile } = useMediaContext()
  const timeoutRef = useRef<number>(null)

  function close() {
    setShowMainContent(false)
    setShowMainOverflow(false)
  }

  function open() {
    setShowMainContent(true)
    timeoutRef.current = window.setTimeout(() => {
      setShowMainOverflow(true)
    }, 750)
  }

  useEffect(() => {
    moveIntercom()
    return () => clearTimeout(timeoutRef.current)
  }, [])

  useEffect(() => {
    if (selectedFile) {
      open()
    } else {
      close()
    }
  }, [selectedFile])

  return (
    <>
      <LeftSidebar showMainContent={showMainContent} openMainContent={open} />
      <MediaContainer show={showMainContent} showOverflow={showMainOverflow} closeMainContent={close} isColab />
      <div className="tw-px-5 tw-pt-6 tw-w-full tw-bg-white tw-overflow-y-auto">
        <AnnotationsListContainer />
      </div>
    </>
  )
}

function CollaboratorMainContentWrapper() {
  const { fetchAndSetTicket, ticket, ticketError, editMode } = useCollaboratorTicketContext()
  // @ts-ignore
  if (ticketError?.response?.data?.message?.toLowerCase().includes('expired')) {
    return <CollaborationUnavailable />
  }
  if (ticketError) {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <TicketError />
      </div>
    )
  } else if (!ticket) {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <AnimatedLoadingScreen />
      </div>
    )
  }

  return (
    <main className={classNames.main}>
      {ticket && (
        <MediaProvider
          fetchAndSetTicket={fetchAndSetTicket}
          isCollaboratorView
          ticket={ticket as DesignRequest}
          editMode={editMode}
          showRevisions
        >
          <AnnotationsProvider isCollaboratorView>
            <CollaboratorTicketContent />
          </AnnotationsProvider>
        </MediaProvider>
      )}
    </main>
  )
}

export default function CollaboratorTicket(): ReactElement {
  return (
    <FeatureFlagsProvider>
      <CollaboratorTicketProvider>
        <div className={classNames.container} style={{ gridTemplateRows: 'min-content auto' }}>
          <CollaboratorTicketHeader />
          <CollaboratorMainContentWrapper />
        </div>
      </CollaboratorTicketProvider>
    </FeatureFlagsProvider>
  )
}
