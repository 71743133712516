import { useRequestContext } from 'components/pages/request/providers/request-provider'
import { getSizesTitle } from 'lib/util/skill-sizes/skill-sizes'
import { getFormatsTitle } from 'lib/util/ticket-formats'
import { LinkLikeButton } from 'components/core/button'
import { useMemo } from 'react'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import CompanyTaggingProvider from 'providers/company-tagging-provider'
import CompanyTaggingPopover from 'components/elements/company-tagging/company-tagging-popover'
import useSelectedTicketTags from 'components/pages/request/request-header/use-selected-ticket-tags'

function Line() {
  return <div className="tw-h-5 tw-bg-neutral-700" style={{ width: '1px' }} />
}

export default function HeaderRequestDetails() {
  const { ticket, setCurrentView, fetchAndSetTicket } = useRequestContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { selectedTags } = useSelectedTicketTags(ticket)

  const detailItems = useMemo(() => {
    return [
      { label: 'Request Type', value: ticket?.skill.name },
      { label: 'Size(s)', value: getSizesTitle(ticket?.selectedSkillSizes, ticket?.size || '') },
      { label: 'Format(s)', value: getFormatsTitle(ticket?.selectedFormats) },
      { label: 'Brand Profile', value: ticket?.selectedBrand?.name || 'N/A' },
    ]
  }, [
    ticket?.selectedBrand?.name,
    ticket?.selectedFormats,
    ticket?.selectedSkillSizes,
    ticket?.size,
    ticket?.skill.name,
  ])

  return (
    <div className="tw-flex tw-items-center">
      {detailItems.map((item, index) => (
        <div key={index} className="tw-flex tw-items-center">
          <RequestDetailItem label={item.label} value={item.value} />
          <Line />
        </div>
      ))}
      {isFeatureFlagEnabled('company_tags') && (
        <div className="tw-flex tw-items-center">
          <div className="tw-ml-2 tw-mt-4">
            <CompanyTaggingProvider
              taggableId={ticket.id}
              taggableType="Ticket"
              updateTaggableState={fetchAndSetTicket}
              selectedTags={selectedTags}
            >
              <CompanyTaggingPopover />
            </CompanyTaggingProvider>
          </div>
        </div>
      )}
      <div className="tw-pl-4">
        <LinkLikeButton onClick={() => setCurrentView('request-details')}>View More</LinkLikeButton>
      </div>
    </div>
  )
}

interface RequestDetailItemProps {
  label: string
  value: string
}

function RequestDetailItem({ label, value }: RequestDetailItemProps) {
  return (
    <div className="tw-px-4">
      <div className="tw-flex tw-flex-col tw-bg-white tw-text-gray-700 tw-border-none tw-py-2">
        <div className="tw-text-neutral-600 tw-font-sm tw-pb-1">{label}</div>
        <div className="tw-text-neutral-800 tw-flex tw-align-center tw-gap-2">
          <div className="tw-font-semibold">{value}</div>
        </div>
      </div>
    </div>
  )
}
