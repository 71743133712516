import Button from 'components/core/button'
import { useAdminMediaContext } from 'components/pages/admin/admin-ticket/providers/admin-media-provider'
import { useAdminTicketContext } from 'components/pages/admin/admin-ticket/providers/admin-ticket-provider'
import { useMediaContext } from 'components/pages/request/media/media-provider'
import { AdminTicketFile, AdminTicketFileState } from 'lib/api/admin/ticket-files/admin-ticket-files'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import { generateAndDownloadZip } from 'lib/util/file/file'
import { filestackCdnUrl } from 'lib/util/filestack'
import { Download, LoaderCircle } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'lib/components/toast/toast'

const DownloadButton = ({ onClick, progress }: { onClick: () => void; loading?: boolean; progress?: number }) => (
  <Button
    color="neutralGray"
    onClick={onClick}
    className="tw-flex tw-items-center tw-justify-center tw-gap-1 tw-m-auto"
  >
    {progress ? (
      <>
        <LoaderCircle className="tw-animate-spin" />{' '}
        <span className="tw-min-w-32">{Math.round(progress)}% Downloaded</span>
      </>
    ) : (
      <>
        <Download className="lu-sm" /> <span>Download All</span>
      </>
    )}
  </Button>
)

function fileUrl(file: AdminTicketFile | TicketFile) {
  if (file.handle) {
    return `${filestackCdnUrl}/${file.handle}`
  }

  return file.previewUrl
}

export function DownloadAllAdminAssetsButton({ files }: { files: AdminTicketFile[] }) {
  const { ticket } = useAdminTicketContext()
  const { filters } = useAdminMediaContext()
  const [progress, setProgress] = useState(null)

  async function handleDownloadAllAssets() {
    setProgress(0)
    try {
      const filesMissingFromZip = files.filter(
        (file) => (file?.previewUrl?.includes('file_placeholder') || !file?.previewUrl) && !file.handle
      )

      const filesToBeZipped = files
        .filter(
          (file) =>
            !((file?.previewUrl?.includes('file_placeholder') && !file.handle) || !file.previewUrl) &&
            file.state !== AdminTicketFileState.Rejected &&
            !file.preview
        )
        .map((file) => ({ name: file.name, url: fileUrl(file) }))
      const creativeOrCustomer = filters.isUploadedByCreative ? 'creative' : 'customer'
      const versionName = filters.version === 0 ? 'all versions' : `version ${filters.version}`
      const zipName = `${creativeOrCustomer} #${ticket.id} ${versionName}`
      await generateAndDownloadZip(filesToBeZipped, zipName, setProgress)

      if (filesMissingFromZip.length > 0) {
        toast.error('Some files could not be added to the zip file')
        console.error('Some files could not be added to the zip file', filesMissingFromZip)
      } else {
        toast.success('Assets downloaded successfully')
      }
    } catch (e) {
      toast.error('Error downloading assets')
      console.error('Error downloading assets', e)
    }
    setProgress(null)
  }

  return <DownloadButton progress={progress} onClick={handleDownloadAllAssets} />
}

export function DownloadAllAssetsButton() {
  const { files, selectedVersion, ticket } = useMediaContext()
  const downloadFileName = `${ticket.id} ${selectedVersion === 0 ? 'all versions' : `version ${selectedVersion}`}`
  const [progress, setProgress] = useState(null)

  async function handleDownloadAllAssets() {
    setProgress(0)
    try {
      const filesMissingFromZip = files.filter(
        (file) => (file?.previewUrl?.includes('file_placeholder') || !file?.previewUrl) && !file.handle
      )

      const filesToBeZipped = files
        .filter(
          (file) =>
            !((file?.previewUrl?.includes('file_placeholder') && !file.handle) || !file.previewUrl) &&
            !file.preview &&
            file.uploadedByCreative &&
            (file.ticketVersion === selectedVersion || selectedVersion === 0)
        )
        .map((file) => ({ name: file.name, url: fileUrl(file) }))
      await generateAndDownloadZip(filesToBeZipped, downloadFileName, setProgress)

      if (filesMissingFromZip.length > 0) {
        toast.error('Some files could not be added to the zip file')
        console.error('Some files could not be added to the zip file', filesMissingFromZip)
      } else {
        toast.success('Assets downloaded successfully')
      }
    } catch (e) {
      toast.error('Error downloading assets')
      console.error('Error downloading assets', e)
    }
    setProgress(null)
  }

  return <DownloadButton progress={progress} onClick={handleDownloadAllAssets} />
}
