import { useEffect, useRef, useState } from 'react'
import { Designer } from 'lib/api/tickets/tickets'

interface TicketDesignersProps {
  onSelect: (designer: Designer) => void
  possibleDesigners: Designer[]
  ticketDesigner: Designer
}

interface DesignerDetailsProps {
  designer: Designer
  onSelect: (brand: Designer) => void
  isSelected: boolean
}

const classNames = {
  container: `tw-flex
      tw-p-4
      tw-rounded
      tw-cursor-pointer
      tw-gap-4
      tw-items-center
      tw-transition-all
      tw-border-solid
      tw-border-2
      tw-border-neutral-200
      hover:tw-bg-cornflower-50
      hover:tw-border-cornflower-300`,
  selectedContainer: 'tw-bg-cornflower-100 tw-border-cornflower-300',
  designerName: `tw-text-sm tw-text-neutral-800 tw-font-normal`,
  avatarContainer: `tw-flex tw-gap-2`,
  avatar: `tw-rounded-full tw-h-6 tw-w-6 tw-border-1 tw-border-solid tw-border-neutral-300`,
  noDesignersContainer: `tw-flex tw-flex-col`,
  noDesignersText: `tw-text-sm tw-text-neutral-800`,
}

function DesignerDetails({ designer, onSelect, isSelected }: DesignerDetailsProps): JSX.Element {
  return (
    <div
      className={`${classNames.container} ${isSelected ? classNames.selectedContainer : ''}`}
      onClick={() => onSelect(designer)}
    >
      <div className={classNames.avatarContainer}>
        <img src={designer.avatar} alt={`${designer.name}-avatar`} className={classNames.avatar} />
      </div>
      <div className={classNames.designerName}>{designer.name}</div>
    </div>
  )
}

export default function TicketDesigners({ possibleDesigners, ticketDesigner, onSelect }: TicketDesignersProps) {
  // The ref is used to call onSelect on dismissal of the dropdown
  const selectedDesignerRef = useRef(ticketDesigner)
  // State is still needed for the rerender cycle to be called
  const [selectedDesigner, setSelectedDesigner] = useState(ticketDesigner)

  function handleSelectDesigner(designer: Designer) {
    if (selectedDesigner?.id === designer?.id) {
      selectedDesignerRef.current = null
      setSelectedDesigner(null)
    } else {
      selectedDesignerRef.current = designer
      setSelectedDesigner(designer)
    }
  }

  useEffect(() => {
    // Gets called when dropdown is dismissed
    return function () {
      if (selectedDesignerRef.current !== ticketDesigner) {
        onSelect(selectedDesignerRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ticketDesigner) {
      selectedDesignerRef.current = ticketDesigner
      setSelectedDesigner(ticketDesigner)
    }
  }, [ticketDesigner])

  return (
    <>
      <h3>Preferred Designer</h3>
      <div className="tw-flex tw-flex-col tw-gap-4">
        {possibleDesigners?.map((designer) => (
          <DesignerDetails
            designer={designer}
            onSelect={handleSelectDesigner}
            isSelected={designer.id === selectedDesigner?.id}
            key={designer.id}
          />
        ))}
      </div>
    </>
  )
}
