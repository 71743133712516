import { JSX } from 'react'
import ReactPaginate from 'react-paginate'
import styles from './pagination.module.scss'

interface Props {
  forcePage?: number
  pageCount: number
  onPageChange: (selectedItem: { selected }) => void
}

export default function Pagination(props: Props): JSX.Element {
  return (
    <div className="tw-flex-grow">
      <ReactPaginate
        activeClassName={styles.activeItem}
        activeLinkClassName={styles.activeLink}
        breakClassName={styles.pageItem}
        breakLinkClassName={styles.pageLink}
        containerClassName={styles.paginationContainer}
        disabledClassName={styles.disabled}
        forcePage={props.forcePage}
        marginPagesDisplayed={3}
        nextClassName={styles.nextPageItem}
        nextLabel="Next >"
        nextLinkClassName={styles.nextPageLink}
        onPageChange={props.onPageChange}
        pageClassName={styles.pageItem}
        pageCount={props.pageCount}
        pageLinkClassName={styles.pageLink}
        pageRangeDisplayed={2}
        previousClassName={styles.previousPageItem}
        previousLabel="< Previous"
        previousLinkClassName={styles.previousPageLink}
      />
    </div>
  )
}
