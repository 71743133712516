import { ReactNode, ReactPortal, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

/*
Usage:
<Tooltip content="This is a tooltip" direction="up" width={350}>
  <input type="text" />
</Tooltip>
 */

type Bounds = Coords & {
  height: number
  width: number
}

type Coords = {
  x: number
  y: number
}

export type Direction = 'up' | 'down' | 'left' | 'right'

interface PortalProps {
  children: ReactNode
}

interface TooltipProps {
  children: ReactNode
  content: ReactNode
  direction?: Direction
  width?: number
  leftOffset?: number
  topOffset?: number
}

const defaultCoords: Coords = Object.freeze({
  x: 0,
  y: 0,
})

function calculatePosition(direction: Direction, targetBounds: Bounds, tooltipBounds: Bounds): Coords {
  const scrollX = window.scrollX
  const scrollY = window.scrollY

  switch (direction) {
    case 'up': {
      const x = targetBounds.x + targetBounds.width / 2 - tooltipBounds.width / 2 + scrollX
      const y = targetBounds.y - tooltipBounds.height + scrollY
      return { x, y }
    }
    case 'down': {
      const x = targetBounds.x + targetBounds.width / 2 - tooltipBounds.width / 2 + scrollX
      const y = targetBounds.y + targetBounds.height + scrollY
      return { x, y }
    }
    case 'right': {
      const x = targetBounds.x + targetBounds.width + scrollX
      const y = targetBounds.y + targetBounds.height / 2 - tooltipBounds.height / 2 + scrollY
      return { x, y }
    }
    case 'left': {
      const x = targetBounds.x - tooltipBounds.width + scrollX
      const y = targetBounds.y + targetBounds.height / 2 - tooltipBounds.height / 2 + scrollY
      return { x, y }
    }
  }
}

function Portal({ children }: PortalProps): ReactPortal {
  return createPortal(children, document.body)
}

export default function Tooltip({
  children,
  content,
  direction = 'down',
  width = 200,
  leftOffset = 0,
  topOffset = 0,
}: TooltipProps): JSX.Element {
  const [coords, setCoords] = useState<Coords>({ ...defaultCoords })
  const [isHovering, setIsHovering] = useState<boolean>(false)

  const targetRef = useRef<HTMLElement>(null)
  const tipRef = useRef<HTMLDivElement>(null)

  const tooltipStyle = useMemo(
    () => ({
      left: `${coords.x + leftOffset}px`,
      maxWidth: `${width}px`,
      top: `${coords.y + topOffset}px`,
      width: 'max-content',
    }),
    [coords.x, coords.y, leftOffset, topOffset, width]
  )

  function show() {
    setIsHovering(true)
  }

  function hide() {
    setIsHovering(false)
  }

  useLayoutEffect(() => {
    if (isHovering) {
      const toolTipBounds = tipRef.current.getBoundingClientRect()
      const targetBounds = targetRef.current.getBoundingClientRect()
      const newCoords = calculatePosition(direction, targetBounds, toolTipBounds)
      setCoords(newCoords)
    }
  }, [direction, isHovering, content])

  return (
    <>
      <span onMouseEnter={show} onMouseLeave={hide} ref={targetRef}>
        {children}
      </span>
      <Portal>
        {isHovering && (
          <div className="tw-absolute tw-z-50" ref={tipRef} role="tooltip" style={tooltipStyle}>
            <div className="tw-text-white tw-px-3 tw-py-1.5 tw-text-left tw-text-sm tw-rounded tw-bg-neutral-500">
              {content}
            </div>
          </div>
        )}
      </Portal>
    </>
  )
}
