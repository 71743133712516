import { ReactElement, useEffect, useRef, useState } from 'react'
import { EditMode } from 'lib/api/tickets/tickets'
import Comment from './comments/comment'
import CommentForm from './comments/comment-form'
import hyperlink from 'lib/util/hyperlink'
import CommentsNotLoaded from './comments-not-loaded'
import { useConversationsContext } from './conversations-provider'
import { useRequestContext } from '../providers/request-provider'
import { LinkLikeButton } from 'components/core/button'

interface ConversationsProps {
  onLoad: (behaviorIsSmooth?: boolean) => void
}

const classNames = {
  list: 'tw-list-none tw-p-0',
  reply: 'tw-sticky tw-bottom-0 tw-py-5 tw-bg-white',
}

/**
 * @deprecated
 */
export default function Conversations({ onLoad }: ConversationsProps): ReactElement {
  const [isNextPageLoading, setIsNextPageLoading] = useState(false)
  const [topItemId, setTopItemId] = useState(null)
  const { addComment, conversationHasError, comments, hasNextPage, getNextPage, retryLoadConversation } =
    useConversationsContext()
  const { editMode, fetchAndSetTicket } = useRequestContext()
  const observerTargetDiv = useRef<HTMLDivElement>(null)
  const topItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hasNextPage && comments?.length) {
      const observer = new IntersectionObserver(
        async ([targetDiv]) => {
          if (targetDiv.isIntersecting && !isNextPageLoading) {
            setIsNextPageLoading(true)
            setTopItemId(comments[0].id)
            await getNextPage()
            observer.disconnect()
            setTimeout(() => {
              setIsNextPageLoading(false)
            }, 500)
          }
        },
        { threshold: 0.5 }
      )

      if (observerTargetDiv.current && !isNextPageLoading) {
        observer.observe(observerTargetDiv.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, comments, isNextPageLoading])

  useEffect(() => {
    if (topItemRef.current) {
      topItemRef.current.scrollIntoView()

      setTopItemId(null)
    }
  }, [topItemId])

  useEffect(() => {
    // Scroll to the bottom of the conversations when this view is mounted for the first time
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function submitComment(body: string) {
    await addComment(body)
    fetchAndSetTicket()

    // Not the best way to do this, but it works for now
    setTimeout(() => {
      onLoad(true)
    }, 100)
  }

  return (
    <div className="tw-pt-6">
      <h2 className="tw-text-base tw-m-0">Conversation Thread</h2>
      <p>Want to reply directly to your Designer? Drop them a line below.</p>
      {hasNextPage && !isNextPageLoading && (
        <div ref={observerTargetDiv} className="tw-ml-5 tw-bg-white tw-text-center tw-text-sm tw-text-gray-500">
          <LinkLikeButton onClick={getNextPage}>More...</LinkLikeButton>
        </div>
      )}
      {conversationHasError && <CommentsNotLoaded retry={retryLoadConversation} />}
      {!conversationHasError && comments.length > 0 && (
        <ol className={classNames.list}>
          {comments.map((comment) => (
            <li key={comment.id}>
              <Comment body={hyperlink(comment.body)} user={comment.user} createdAt={comment.createdAt} />
            </li>
          ))}
        </ol>
      )}
      {!conversationHasError && comments.length === 0 && <strong>The conversation has not started.</strong>}
      {editMode !== EditMode.complete && (
        <div className={classNames.reply}>
          <CommentForm onSend={submitComment} />
        </div>
      )}
    </div>
  )
}
