import { ChangeEvent, FormEvent, ReactElement, useState } from 'react'
import Button from 'components/core/button'
import TextInput from 'lib/components/text-input/text-input'
import AuthBranding from './auth-branding'
import { AuthRoute, useUserContext } from 'providers/user-provider'
import { resetPassword } from 'lib/api/auth/auth'
import { validateEmail } from 'lib/util/email'
import { toast } from 'lib/components/toast/toast'

export default function ForgotPassword(): ReactElement {
  const { auth } = useUserContext()
  const [email, setEmail] = useState<string>('')
  const [hasSentEmail, setHasSentEmail] = useState(false)
  const [emailError, setEmailError] = useState(false)

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (!validateEmail(email)) {
      setEmailError(true)
      return
    }

    try {
      await resetPassword(email, window.location.href)
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong - please try again.')
      return
    }

    setHasSentEmail(true)
    return
  }

  return (
    <AuthBranding>
      {hasSentEmail ? (
        <div className="tw-text-center">
          <p data-testid="success-message" className="tw-font-bold">
            Please check your email for a link to reset your password
          </p>
          <p>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                auth.setAuthRoute(AuthRoute.LOGIN)
              }}
              data-testid="auth-login-return-link"
            >
              Return to log in
            </a>
          </p>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit} data-testid="forgot-password-form">
            <h1 className="tw-text-center tw-text-base tw-m-0">Reset your collaborator account password</h1>
            <TextInput
              name="user[email]"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value)
                setEmailError(false)
              }}
              placeholder="Email"
              error={emailError}
            />
            <div className="tw-text-center">
              <Button color="purple" type="submit">
                Reset Password
              </Button>
              <p>
                Need to log in?{' '}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    auth.setAuthRoute(AuthRoute.LOGIN)
                  }}
                  data-testid="auth-login-link"
                >
                  Log in here
                </a>
              </p>
            </div>
          </form>
        </>
      )}
    </AuthBranding>
  )
}
