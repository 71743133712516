import { AdminQaReviewStates } from 'lib/api/admin/tickets/admin-tickets'
import { bindJWT, getApi } from 'lib/api/api'
import { QaSpecialist } from 'lib/api/qa_specialists/qa_specialists'
import { snakeCaseKeys } from 'lib/object/utils'

export enum AdminLogFilter {
  QAReview = 'qa_review',
  TicketChange = 'ticket_changes',
  AutoAssigner = 'auto_assigner',
  TicketTimeTracking = 'ticket_time_tracking',
  all = 'all',
}

export interface AdminTicketLogFilters {
  lastCreatedAt?: string
  logType?: AdminLogFilter
}

export type AdminTicketLog =
  | AdminTicketQAReviewLog
  | AdminTicketChangeLog
  | AdminTicketAssignerLog
  | AdminTicketTimeTrackingLog

export interface AdminTicketQAReviewLog {
  id: number
  companyId: number
  companyName: string
  createdAt: string
  designerId: number
  designerName: string
  finishedAt: string
  logType: 'qa_review'
  meta: Record<string, unknown>
  qaSpecialist: QaSpecialist
  startedAt: string
  state: AdminQaReviewStates
  ticketId: number
  ticketStateChangedAt: string
  ticketSubject: string
  user: string
}

export interface AdminTicketChangeLog {
  logType:
    | 'ticket_version'
    | 'qa_review_version'
    | 'company_daily_available_time_version'
    | 'company_time_tracking_version'
  whodunnit: string
  createdAt: string
  changeset: Record<string, Array<string | number>>
  user: string
}

export interface AdminTicketAssignerLog {
  logType: 'auto_assigner_tracking'
  createdAt: string
  success: boolean
  errorMessages: Array<string>
  user: string
}

export interface AdminTicketTimeTrackingLog {
  id: number
  endTime: string
  hoursWorked: number
  logType: 'ticket_time_tracking' | 'ticket_tracking'
  minutesPassed: number
  startTime: string
  trackingType: string
  user: string
}

export async function getLogs(
  id: number,
  filters: AdminTicketLogFilters = {},
  jwt: string = null
): Promise<AdminTicketLog[]> {
  const getWithJWT = bindJWT(getApi, jwt)

  const ticketParamsSnakeCase = snakeCaseKeys(filters)

  const response = await getWithJWT(`/api/internal/admin/tickets/${id}/logs`, ticketParamsSnakeCase)
  return response.data.data
}
