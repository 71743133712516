import SingleSelect from 'components/core/single-select'
import { Dpu } from 'interfaces/dpu'
import { Team } from 'interfaces/team'
import { findById } from 'lib/array/utils'
import React, { Dispatch, SetStateAction, useState } from 'react'
import SubscriptionsItem, { ScaleDpuRequestBody } from '../shared/subscriptions-item'

interface ScaleSubscriptionsItemProps {
  dpu: Dpu
  onTeamChange: (dpuId: number, teamId: number, clusterId: number) => void
  teams: Team[]
}

export default function ScaleSubscriptionsItem(props: ScaleSubscriptionsItemProps): JSX.Element {
  const [assigned, setAssigned] = useState(props.dpu.clusterId !== null)

  const disabled = !props.dpu.teamId || !props.dpu.clusterId
  const requestBody: ScaleDpuRequestBody = {
    qrTeamId: props.dpu.teamId,
    teamDesignerClusterId: props.dpu.clusterId,
  }

  const selectedTeam = findById(props.dpu.teamId, props.teams)

  return (
    <SubscriptionsItem
      assigned={assigned}
      disabled={disabled}
      dpu={props.dpu}
      requestBody={requestBody}
      setAssigned={setAssigned}
    >
      <td className="tw-p-2 tw-min-w-48">
        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
          <TeamSelect
            dpu={props.dpu}
            onTeamChange={props.onTeamChange}
            selectedTeam={selectedTeam}
            setAssigned={setAssigned}
            teams={props.teams}
          />
          <ClusterSelect
            dpu={props.dpu}
            onTeamChange={props.onTeamChange}
            selectedTeam={selectedTeam}
            setAssigned={setAssigned}
          />
        </div>
      </td>
    </SubscriptionsItem>
  )
}

interface TeamSelectProps {
  dpu: Dpu
  onTeamChange: (dpuId: number, teamId: number, clusterId: number) => void
  selectedTeam: Team
  setAssigned: Dispatch<SetStateAction<boolean>>
  teams: Team[]
}

function TeamSelect({ dpu, onTeamChange, selectedTeam, setAssigned, teams }: TeamSelectProps): JSX.Element {
  const handleTeamChange = (e) => {
    onTeamChange(dpu.id, e.value, null)
    setAssigned(false)
  }

  return (
    <>
      <label htmlFor={`team-select-${dpu.id}`} className="tw-hidden">
        Team
      </label>
      <SingleSelect
        value={selectedTeam ? { label: selectedTeam.name, value: selectedTeam.id } : null}
        onChange={(e) => handleTeamChange(e)}
        inputId={`team-select-${dpu.id}`}
        options={teams.map((team) => ({
          value: team.id,
          label: team.name,
        }))}
      />
    </>
  )
}

interface ClusterSelectProps {
  dpu: Dpu
  onTeamChange: (dpuId: number, teamId: number, clusterId: number) => void
  selectedTeam: Team
  setAssigned: Dispatch<SetStateAction<boolean>>
}

function ClusterSelect({ dpu, onTeamChange, selectedTeam, setAssigned }: ClusterSelectProps): JSX.Element {
  const selectedCluster = selectedTeam && selectedTeam.clusters.find(({ id }) => id === dpu.clusterId)
  const handleClusterChange = (e) => {
    onTeamChange(dpu.id, selectedTeam.id, e.value)
    setAssigned(false)
  }

  return (
    <>
      <label htmlFor={`cluster-select-${dpu.id}`} className="tw-hidden">
        Cluster
      </label>
      <SingleSelect
        value={
          selectedCluster
            ? {
                label: `Cluster ${selectedCluster.clusterId}`,
                value: selectedCluster.clusterId,
              }
            : null
        }
        onChange={(e) => handleClusterChange(e)}
        isDisabled={!selectedTeam}
        inputId={`cluster-select-${dpu.id}`}
        options={
          selectedTeam &&
          selectedTeam.clusters.map((cluster) => ({
            value: cluster.id,
            label: `Cluster ${cluster.clusterId}`,
          }))
        }
      />
    </>
  )
}
