import { HTMLAttributes, MouseEvent } from 'react'
import Dropdown, { defaultClassNames } from 'lib/components/dropdown/dropdown'
import { cn } from 'lib/util/cn'

import { useCompanyTaggingContext } from 'providers/company-tagging-provider'
import CompanyTags from './company-tags'
import CompanyTaggingPopoverTitle from './company-tagging-popover-title'
import { Pencil, X } from 'lucide-react'

interface CompanyTaggingPopoverProps {
  size?: 'xs' | 'full'
}

export default function CompanyTaggingPopover({ size = 'xs' }: CompanyTaggingPopoverProps) {
  const { selectedTags } = useCompanyTaggingContext()

  const classNames = {
    ...defaultClassNames,
    popover: 'tw-width-full',
  }

  return (
    <div className="tw-relative">
      <Dropdown
        classNames={classNames}
        icon="chevron"
        title={<CompanyTaggingPopoverTitle selectedTags={selectedTags} emptyTagTitleElement={<>Select tag(s)</>} />}
        label={size === 'full' ? null : 'Tags'}
        openByDefault={false}
        size={size}
        render={(close) => <CompanyTags handleXClick={close} />}
      />
    </div>
  )
}

interface SelectBoxCompanyTaggingPopoverProps {
  disabled?: boolean
  buttonClassName?: HTMLAttributes<HTMLButtonElement>['className']
  titleClassName?: HTMLAttributes<HTMLDivElement>['className']
}

export function SelectBoxCompanyTaggingPopover({
  disabled = false,
  buttonClassName,
  titleClassName,
}: SelectBoxCompanyTaggingPopoverProps) {
  const { selectedTags, clearSelectedTags } = useCompanyTaggingContext()

  const buttonClassNames =
    buttonClassName ||
    (selectedTags?.length > 0 ? 'tw-text-neutral-500 tw-text-xs' : 'tw-text-peppercorn-300 tw-text-xs')

  const dropdownClassNames = {
    ...defaultClassNames,
    popoverButton: `
      ${buttonClassNames}
      tw-w-48
      tw-gap-1
      tw-px-1.5
      tw-py-2.5
      tw-rounded-md
      tw-bg-white
      tw-border-1
      tw-border-solid
      tw-border-peppercorn-100
    `,
    titleContainer: 'tw-flex tw-items-center tw-justify-between',
    chevronContainer: 'tw-flex tw-items-center tw-justify-center',
    chevronHoverColor: 'tw-bg-white',
    title: titleClassName || defaultClassNames.title,
  }

  const titleClassNames = {
    container: 'tw-flex tw-items-center tw-justify-between',
    text: 'tw-whitespace-nowrap tw-overflow-hidden tw-overflow-ellipsis tw-w-32 tw-text-left tw-font-normal',
    icon: 'tw-text-neutral-500 tw-text-xs tw-ml-2 tw-border-none tw-bg-transparent hover:tw-text-neutral-700',
  }

  function handleXClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()

    clearSelectedTags()
  }

  const title = (
    <>
      {selectedTags?.length > 0 ? (
        <div className={titleClassNames.container}>
          <span className={titleClassNames.text}>
            {selectedTags?.length > 0 ? selectedTags?.map((tag) => tag.name).join(', ') : 'Tags'}
          </span>
          <button onClick={handleXClick} disabled={disabled} className={titleClassNames.icon}>
            <X className="lu-sm" />
          </button>
        </div>
      ) : (
        'Tags'
      )}
    </>
  )

  return (
    <Dropdown
      classNames={dropdownClassNames}
      openByDefault={false}
      size="sm"
      icon="chevron"
      title={title}
      disabled={disabled}
      render={(close) => <CompanyTags handleXClick={close} />}
    />
  )
}

interface PencilCompanyTaggingPopoverProps {
  openDirection?: 'bottom' | 'right'
  editTags?: boolean
}

export function PencilCompanyTaggingPopover({
  openDirection = 'right',
  editTags = true,
}: PencilCompanyTaggingPopoverProps) {
  const { selectedTags } = useCompanyTaggingContext()

  const classNames = {
    ...defaultClassNames,
    popoverButton: 'tw-flex tw-flex-col tw-bg-transparent tw-text-gray-700 tw-border-none tw-py-2',
    popoverPanel: cn(defaultClassNames.popoverPanel, {
      'tw--mt-72 tw-left-full tw-ml-4': openDirection === 'right',
    }),
  }

  if (!editTags) {
    return <CompanyTaggingPopoverTitle selectedTags={selectedTags} emptyTagTitleElement={null} />
  }

  return (
    <Dropdown
      buttonRingOnOpen
      classNames={classNames}
      icon={selectedTags?.length > 0 ? <Pencil className="lu-md" /> : null}
      render={(close) => <CompanyTags handleXClick={close} />}
      size="sm"
      title={
        <CompanyTaggingPopoverTitle
          selectedTags={selectedTags}
          emptyTagTitleElement={<div className="tw-text-xs tw-text-neutral-500 tw-p-0 tw-m-0">+ Add tag(s)</div>}
        />
      }
    />
  )
}
