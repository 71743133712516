import Modal from 'lib/components/modals/modal'

import Button from 'components/core/button'

import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { useAdminMediaContext } from '../providers/admin-media-provider'
import { toast } from 'lib/components/toast/toast'

interface AdminCreateNewVersionModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export default function AdminCreateNewVersionModal({ open, setOpen }: AdminCreateNewVersionModalProps) {
  const { ticket, createNewVersion } = useAdminTicketContext()
  const { setFilters } = useAdminMediaContext()

  async function handleCreateNewVersion() {
    try {
      const { currentVersion } = await createNewVersion()
      setFilters((previous) => ({ ...previous, version: currentVersion }))

      toast.success(`Version ${currentVersion} Created`)
      setOpen(false)
    } catch (error) {
      toast.error('Error creating new version, please try again')
      console.error('Error creating new version', error)
    }
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        <h2 className="tw-text-lg tw-font-bold tw-text-neutralDark tw-mb-4">
          Create Version {ticket.currentVersion + 1}
        </h2>
      </Modal.Header>
      <Modal.Body setOpen={setOpen}>
        <p className="tw-text-sm tw-text-neutralGray tw-mb-8">Are you sure you want to archive all current files?</p>
        <div className="tw-flex tw-flex-row tw-gap-2 tw-justify-end tw-items-center">
          <Button color="neutralGray" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={handleCreateNewVersion}>
            Yes, Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
