import { BlobWriter, BlobReader, ZipWriter } from '@zip.js/zip.js'
import { getBlob } from '../../api/api'
import { sumBy } from 'lodash'

export function displayFilesize(filesize: number): string {
  const units = ['B', 'KB', 'MB', 'GB']

  let unitIndex = 0
  let size = filesize

  while (size > 1024) {
    size /= 1024
    unitIndex += 1
  }

  return `${size.toFixed(0)} ${units[unitIndex]}`
}

export interface File {
  name: string
  url: string
}

export function removeParenCountFromFileName(fileName: string): [string, number] {
  const regex = /^(.*)\s\((\d+)\)(\.[^.]+)$/
  const match = fileName.match(regex)

  if (match) {
    const baseName = `${match[1]}${match[3]}` // The part before the parentheses and the extension
    const count = parseInt(match[2], 10) // The number inside the parentheses
    return [baseName, count]
  } else {
    return [fileName, 0]
  }
}

export function renameFileNameDuplicates(files: File[]): File[] {
  const nameOccurrences: { [key: string]: number } = {}

  return files.map((file) => {
    // eslint-disable-next-line prefer-const
    let [baseName, originalCount] = removeParenCountFromFileName(file.name)
    let extension = ''

    const dotIndex = baseName.lastIndexOf('.')
    if (dotIndex !== -1) {
      extension = baseName.slice(dotIndex)
      baseName = baseName.slice(0, dotIndex)
    }

    const count = nameOccurrences[baseName + extension] || originalCount
    nameOccurrences[baseName + extension] = count + 1

    if (count > 0) {
      baseName = `${baseName} (${count})`
    }

    return { ...file, name: `${baseName}${extension}` }
  })
}

export async function generateAndDownloadZip(files: File[], zipName: string, onProgress?: (progress: number) => void) {
  const filesWithUniqueNames = renameFileNameDuplicates(files)
  const zipWriter = new ZipWriter(new BlobWriter('application/zip'))
  const progress = new Array<ProgressEvent>(filesWithUniqueNames.length)
  const onItemProgress = (event: ProgressEvent, idx: number) => {
    progress[idx] = event
    if (!progress.includes(undefined)) {
      onProgress(progress.reduce((acc, { loaded }) => acc + loaded * 100, 0) / sumBy(progress, 'total'))
    }
  }

  await Promise.all(
    filesWithUniqueNames.map(async (file, index) => {
      const fileAsBlob = await getBlob(file.url, (event) => onItemProgress(event, index)).then(
        (response) => response.data
      )
      await zipWriter.add(file.name, new BlobReader(new Blob([fileAsBlob])))
    })
  )
  const zipFile = await zipWriter.close()

  downloadZipFile(zipFile, zipName)
}

function downloadZipFile(blob: Blob, name: string) {
  Object.assign(document.createElement('a'), {
    download: `${name}.zip`,
    href: URL.createObjectURL(blob),
  }).click()
}
