import Button from 'components/core/button'
import TextInput from 'components/core/text-input/text-input'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Format } from 'interfaces/format'
import { routes, updateFormat } from 'lib/api/admin/formats/formats'
import SelectBox from 'lib/components/dropdown/select-box'
import { useState } from 'react'
import SourceSwitch from './source-switch'
import { Toaster, toast } from 'lib/components/toast/toast'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  option: 'tw-mx-1 tw-flex-grow tw-text-right',
  select: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

interface AdminFormatsEditProps {
  format: Format
}

function AdminFormatsEdit({ format }: AdminFormatsEditProps) {
  const [name, setName] = useState<string>(format.name)
  const [source, setSource] = useState<string>(format.source.toString())
  const [delivery, setDelivery] = useState<string>(format.delivery)
  const [extensions, setExtensions] = useState<string>(format.extensions.join(','))
  const [updating, setUpdating] = useState<boolean>(false)

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    setUpdating(true)
    const extensionsParam = extensions.split(',').map((ext) => ext.trim())
    try {
      const { status } = await updateFormat(format.id, { name, source, delivery, extensions: extensionsParam })

      if (status === 200) {
        toast.success('Format updated successfully')
        window.open(routes.index, '_self')
      }
    } catch (error) {
      console.error(error)
      toast.error('Failed to update format')
    } finally {
      setUpdating(false)
    }
  }

  const deliveryOptions = () =>
    ['file', 'link'].map((option) => ({
      value: option,
      displayElement: <div className={classNames.option}>{option}</div>,
    }))

  if (updating) {
    return <LoadingScreen />
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <a href={routes.index} className="tw-text-gherkin-500 tw-text-sm tw-mb-4 tw-block">
          Back to Formats
        </a>
        <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">Edit {format.name}</h1>
        <form data-testid="edit-form" onSubmit={handleSubmitForm}>
          <TextInput
            data-testid="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="Name"
          />
          <SourceSwitch source={source} setSource={setSource} />
          <div className={classNames.select}>
            <SelectBox
              data-testid="delivery"
              label="Delivery"
              handleChange={(value: string) => setDelivery(value)}
              selectedValue={delivery}
              options={deliveryOptions()}
            />
          </div>
          <div className="tw-mt-4">
            <TextInput
              data-testid="extensions"
              label="Extensions"
              value={extensions}
              onChange={(e) => setExtensions(e.target.value)}
              name="Extensions"
            />
          </div>
          <div className="tw-mt-4">
            <Button data-testid="submit-button" color="green" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

interface AdminFormatsEditPageProps {
  format: Format
}

export default function AdminFormatsEditPage(props: AdminFormatsEditPageProps) {
  return (
    <>
      <Toaster />
      <AdminFormatsEdit format={props.format} />
    </>
  )
}
