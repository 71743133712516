import { baseRequest, BaseResponse, getApi, patchApi, postApi } from '../api'
import { TicketFile } from '../ticket-files/ticket-files'

const baseUrl = '/api/internal/gen_ai/requests'

export interface GenAiRequest {
  id: number
  createdAt: number
  message: string
  requestId: string
  requestType: GenAiRequestTypes
  request: GenAiRequestDSRequest
  response: GenAiRequestDSResponse
  status: GenAiRequestStatus
  ticketId: number
  filestackHandle: string
}

export interface GenAiDSImage {
  id: string
  url: string
  ticket_file_id?: number
}

export interface GenAiRequestDSRequest {
  filestack_handle: string
  mask_image?: string
  message: string
  original_image?: string
  request_type: GenAiRequestTypes
  ticket_id: number
  ticket_file_id: number
}

export interface GenAiRequestDSResponse {
  ds: {
    prompt: string
    status: GenAiRequestStatus
    results: string[] | GenAiDSImage[]
    request_id: string
    session_id: string
    end_timestamp: number
    start_timestamp: number
  }
  req: {
    requestId: string
  }
}

export interface GenAiRequestMetaData {
  empty: boolean
}

interface GenAiRequestParams {
  imageUrl?: string
  message?: string
  requestType?: GenAiRequestTypes
  status?: GenAiRequestStatus
  ticketId?: number
}

export interface GenAiResponse extends BaseResponse {
  genAiRequest: GenAiRequest
  meta: GenAiRequestMetaData
}

export interface GenAiListResponse extends BaseResponse {
  genAiRequests: GenAiRequest[]
  meta: GenAiRequestMetaData
}

export enum GenAiRequestStatus {
  New = 'new',
  Error = 'error',
  Dismissed = 'dismissed',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Accepted = 'accepted',
}

export enum GenAiRequestTypes {
  Copy = 'copy',
  Elaborate = 'elaborate',
  IMG2IMG = 'img2img',
  Inpainting = 'inpainting',
  TXT2IMG = 'txt2img',
}

export enum GenAiResultTypes {
  CreateAssist = 'CreateAssist',
  EditAssist = 'EditAssist',
}

export async function acceptGenAiRequest(genAiRequestId: number) {
  return updateGenAiRequest(genAiRequestId, { status: GenAiRequestStatus.Accepted })
}

function convertArrayParam(paramName: string, values: unknown[]) {
  return values.map((value) => `${paramName}[]=${value}`)
}

export async function createGenAiRequest(params: GenAiRequestParams): Promise<GenAiResponse> {
  const response = await baseRequest(baseUrl, postApi, { data: paramsCamelCaseToSnakeCase(params) })

  return {
    error: response.error,
    status: response.status,
    genAiRequest: response.data.data as unknown as GenAiRequest,
    meta: response.data.meta as unknown as GenAiRequestMetaData,
  }
}

interface InpaintingRequestPayload {
  mask_image: string
  message: string
  original_image: string
  request_type: GenAiRequestTypes
  ticket_id: number
  ticket_file_id: number
}

export async function createInpaintingRequest(
  ticketId: number,
  originalImageUrl: string,
  maskImageUrl: string,
  prompt: string,
  ticketFileId: number
) {
  const data: InpaintingRequestPayload = {
    mask_image: maskImageUrl,
    message: prompt,
    original_image: originalImageUrl,
    request_type: GenAiRequestTypes.Inpainting,
    ticket_file_id: ticketFileId,
    ticket_id: ticketId,
  }

  const response = await baseRequest(baseUrl, postApi, { data })

  return {
    error: response.error,
    status: response.status,
    genAiRequest: response.data.data as unknown as GenAiRequest,
    meta: response.data.meta as unknown as GenAiRequestMetaData,
  }
}

export async function dismissGenAiRequest(genAiRequestId: number) {
  return updateGenAiRequest(genAiRequestId, { status: GenAiRequestStatus.Dismissed })
}

export async function cancelGenAiRequest(genAiRequestId: number) {
  return updateGenAiRequest(genAiRequestId, { status: GenAiRequestStatus.Cancelled })
}

export async function getAllPendingGenAiRequestsForTicket(ticketId: number): Promise<GenAiListResponse> {
  const params = convertArrayParam('status', [
    GenAiRequestStatus.Completed,
    GenAiRequestStatus.Error,
    GenAiRequestStatus.New,
  ])
  const url = `/api/internal/gen_ai/tickets/${ticketId}?${params.join('&')}`
  const response = await baseRequest(url, getApi)

  return {
    error: response.error,
    status: response.status,
    genAiRequests: response.data.data as unknown as GenAiRequest[],
    meta: response.data.meta as unknown as GenAiRequestMetaData,
  }
}

export async function getGenAiRequest(genAiRequestId: number): Promise<GenAiResponse> {
  const response = await baseRequest(`${baseUrl}/${genAiRequestId}`, getApi)

  return {
    error: response.error,
    status: response.status,
    genAiRequest: response.data.data as unknown as GenAiRequest,
    meta: response.data.meta as unknown as GenAiRequestMetaData,
  }
}

export function getGenAiResultType(file: TicketFile): GenAiResultTypes {
  const requestType = file?.stockAsset?.requestType
  if (requestType === GenAiRequestTypes.TXT2IMG || requestType === GenAiRequestTypes.IMG2IMG) {
    return GenAiResultTypes.CreateAssist
  } else if (requestType === GenAiRequestTypes.Inpainting) {
    return GenAiResultTypes.EditAssist
  }
  return null
}

function paramsCamelCaseToSnakeCase(params: GenAiRequestParams): Record<string, unknown> {
  return {
    ticket_id: params.ticketId,
    request_type: params.requestType,
    message: params.message,
    status: params.status,
    original_image: params.imageUrl,
  }
}

export async function updateGenAiRequest(genAiRequestId: number, params: GenAiRequestParams): Promise<GenAiResponse> {
  const response = await baseRequest(`${baseUrl}/${genAiRequestId}`, patchApi, {
    data: paramsCamelCaseToSnakeCase(params),
  })

  return {
    error: response.error,
    status: response.status,
    genAiRequest: response.data.data as unknown as GenAiRequest,
    meta: response.data.meta as unknown as GenAiRequestMetaData,
  }
}
